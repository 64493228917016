import { observable, makeObservable, flow } from 'mobx';
import { BaseStore, longCache } from '../base-store';
import { dataLikeArrayMixin } from '../mixins';

/** @typedef {import('dashboard-api-types').payment} Payment */
export class AccountPaymentsStore extends dataLikeArrayMixin(BaseStore) {
  limit = 100;

  /** @type {Payment[]} */
  data = [];

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/payments/';
  }

  *fetchData({ config = longCache } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get(`/?limit=${this.limit}`, config);
      this.data = res.data.results;
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }
}
