import { flow, makeObservable } from 'mobx';
import { BaseStore, clearRequestsCache } from '../base-store';

export class AuthStore extends BaseStore {
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      signIn: flow,
      checkSsoIsSupported: flow,
      signUp: flow,
      logout: flow,
      sendLoginLink: flow,
      tokenAuth: flow,
      disconnectSocialAccount: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/';
  }

  *sendLoginLink({ email, captcha }) {
    this.isLoadingMap.sendLoginLink = true;

    try {
      return yield this.api.post('/auth/email/', {
        email,
        captcha,
      });
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap.sendLoginLink = false;
    }
  }

  *signIn({ email, password, captcha }) {
    this.isLoadingMap.signIn = true;

    try {
      const res = yield this.api.post('/auth/login/', {
        email,
        password,
        captcha,
      });
      this.isLoadingMap.signIn = false;

      this.appStore.stores.accountStore.setInitialData(res.data);

      return res;
    } catch (e) {
      this.isLoadingMap.signIn = false;
      this.processErrors(e);
    }
  }

  /**
   * @param {{ email: string }} params
   * @returns {Promise<import('axios').AxiosResponse<{ location: string }>}
   */
  *checkSsoIsSupported({ email }) {
    this.isLoadingMap.signInSso = true;
    try {
      return yield this.api.post('/auth/social/okta-oauth2/', { email });
    } finally {
      this.isLoadingMap.signInSso = false;
    }
  }

  *signUp({ email, password, captcha, referrer, referral, pages, agreedToTerms = true }) {
    this.isLoadingMap.signUp = true;

    try {
      const res = yield this.api.post('/signup/', {
        email,
        password,
        captcha,
        referrer,
        referral,
        pages,
        agreed_to_terms: agreedToTerms,
      });
      this.isLoadingMap.signUp = false;

      this.appStore.stores.accountStore.setInitialData(res.data);

      return res;
    } catch (e) {
      this.isLoadingMap.signUp = false;
      this.processErrors(e);
    }
  }

  *tokenAuth({ email, token }) {
    this.isLoadingMap.tokenAuth = true;

    try {
      const res = yield this.api.post('/auth/login/token/', { email, token });
      this.appStore.stores.accountStore.setInitialData(res.data);
      return res;
    } finally {
      this.isLoadingMap.tokenAuth = false;
    }
  }

  *logout(redirectUrl = import.meta.env.VITE_WEBSITE_BASE) {
    this.isLoadingMap.logout = true;

    try {
      window.localStorage?.clear();
      clearRequestsCache();

      const res = yield this.api.post('/auth/logout/');
      this.isLoadingMap.logout = false;

      if (redirectUrl) {
        window.location = redirectUrl;
      }

      return res;
    } catch (e) {
      this.isLoadingMap.logout = false;
      this.processErrors(e);
    }
  }

  *disconnectSocialAccount(provider) {
    this.isLoadingMap[provider] = true;

    try {
      return yield this.api.post(`/auth/social/disconnect/${provider}/`);
    } finally {
      this.isLoadingMap[provider] = false;
    }
  }
}
