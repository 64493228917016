/* eslint-disable camelcase */
import { action, observable, makeObservable, flow, reaction, computed } from 'mobx';
import qs from 'query-string';
import { BaseStore, longCache, noCache } from '~/stores/base-store';
import { AccountMetaFieldEnum } from '~/stores/account/account-store';
import { CUSTOM_PLAN_BASE } from '~/stores/constants/plans';
import { sendCustomGtmEvent } from '~/analytics/utils/tracking';
import { message } from '~/components/Toaster';

// this enum from backend
/** @type {{ ANNUALLY: 'A'; MONTHLY: 'M' }} */
export const PaymentPeriodEnum = {
  ANNUALLY: 'A',
  MONTHLY: 'M',
};

export const AVAILABLE_PLANS_ENUM = {
  FREE: 'Free',
  HOBBY: 'Hobby',
  DEMO: 'Demo',
  PRO: 'Pro',
  PAYG: 'Pay as you go',
  BUSINESS: 'Business',
  ENTERPRISE: 'Custom',
};

const NEW_PLAN_NAMES = new Set(['Pro', 'Pay as you go', 'Business']);
const FIVE_TB = 5 * 1000 ** 4;

/**
 * @typedef {import('dashboard-api-types').pricingPlan} Plan
 *
 * @typedef {import('dashboard-api-types').feature} Feature
 *
 * @typedef {import('dashboard-api-types').features} Features
 */

export class AccountPlanStore extends BaseStore {
  /** @type {Plan} */
  currentPlan = null;

  /** @type {Plan} */
  promotedPlan = null;

  /** @type {Plan[]} */
  availablePlans = [];

  /** @type {('M' | 'A')[]} */
  availablePaymentPeriods = [];

  /** @type {string | null} */
  promoCode = null;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      currentPlan: observable,
      availablePlans: observable,
      availablePaymentPeriods: observable,
      fetchCurrentPlan: flow,
      setCurrentPlanById: action,
      fetchAvailablePlans: flow,
      changePlan: flow,
      cancelTrial: flow,
      paymentPeriod: computed,
      nextPlan: computed,
      freePlan: computed,
      isFreePlan: computed,
      firstPaidPlan: computed,
      isCustomPlan: computed,
      areNotDefaultPlans: computed,
      hasOperations: computed,
    });
  }

  onInit() {
    // subscription to available plans property for checking if there is promoted plan in it
    reaction(
      () => this.availablePlans,
      (plans) => {
        this.promotedPlan = plans.find((plan) => NEW_PLAN_NAMES.has(plan.name));

        if (this.currentPlan === null) {
          const planId = this.appStore.stores.accountStore.data?.subscription?.plan_id;
          const plan = this.setCurrentPlanById(planId);
          if (!plan) {
            this.fetchCurrentPlan();
          }
        }
      }
    );
  }

  isNewPlan(id) {
    const plan = this.getPlanById(id);
    return plan ? NEW_PLAN_NAMES.has(plan.name) : false;
  }

  get baseURL() {
    return '/apps/api/v0.1/pricing_plan/';
  }

  get paymentPeriod() {
    return this.appStore.stores.accountStore.data?.subscription?.is_annual_payment_period
      ? PaymentPeriodEnum.ANNUALLY
      : PaymentPeriodEnum.MONTHLY;
  }

  get hasOperations() {
    return Boolean(this.currentPlan?.features?.operations?.is_enabled);
  }

  /** @returns {import('dashboard-api-types').pricingPlan | null} */
  get nextPlan() {
    if (!this.currentPlan || this.availablePlans.length === 0) {
      return null;
    }

    const currentPlanIndex = this.availablePlans.findIndex(({ id }) => id === this.currentPlan.id);
    const isLegacyPlan = currentPlanIndex === -1;

    if (isLegacyPlan) {
      const shouldOfferUpgrade = this.isFreePlan;

      if (shouldOfferUpgrade) {
        return this.firstPaidPlan;
      }

      return null;
    }

    return this.availablePlans[currentPlanIndex + 1];
  }

  get firstPaidPlan() {
    if (!this.currentPlan || this.availablePlans.length === 0) {
      return null;
    }

    return this.availablePlans.find(({ price }) => Number(price) > 0) ?? null;
  }

  get freePlan() {
    return this.availablePlans.find(({ price }) => Number(price) === 0);
  }

  get isFreePlan() {
    return Number(this.currentPlan?.price) === 0;
  }

  get isCustomPlan() {
    return this.currentPlan?.name === AVAILABLE_PLANS_ENUM.ENTERPRISE;
  }

  *fetchCurrentPlan({ config } = {}) {
    this.isLoadingMap.fetchCurrentPlan = true;

    try {
      const res = yield this.api.get('/current/', config);
      this.currentPlan = res.data;
      this.isLoadingMap.fetchCurrentPlan = false;
      return res;
    } catch (e) {
      this.isLoadingMap.fetchCurrentPlan = false;
      this.processErrors(e);
    }
  }

  setCurrentPlanById(planId) {
    const plan = this.availablePlans.find((plan) => plan.id === planId);
    if (plan) {
      this.currentPlan = plan;
    }
    return plan;
  }

  getPlanById(planId) {
    return this.availablePlans.find((plan) => plan.id === planId);
  }

  /**
   * Return the first plan from the current family where a specified feature is enabled.
   *
   * @param {Feature | null | undefined} feature Feature which should be enabled on the found plan.
   * @param {(feature: Feature) => boolean | null} condition An additional condition to check on the
   *   enabled feature.
   * @returns {Plan | undefined}
   */
  getPlanWithEnabledFeature(feature, condition = null) {
    if (!feature) return;

    const plans = this.availablePlans.filter((plan) => plan.id !== this.currentPlan?.id);

    // Match feature by title, because feature name is not part of Feature object :(
    for (const plan of plans) {
      for (const planFeature of Object.values(plan.features)) {
        if (
          planFeature.title === feature.title &&
          planFeature.is_enabled &&
          (!condition || condition(planFeature))
        ) {
          return plan;
        }
      }
    }
  }

  get areNotDefaultPlans() {
    return this.promoCode && this.availablePlans.every((p) => Number(p.price) > 0);
  }

  *fetchAvailablePlans(promoCode = null, { config = longCache } = {}) {
    this.promoCode = promoCode;
    this.isLoadingMap.fetchAvailablePlans = true;

    try {
      let url = '/actual/';
      if (promoCode) {
        url = qs.stringifyUrl({
          url,
          query: {
            promo_code: promoCode,
          },
        });
      }

      const res = yield this.api.get(url, config);

      this.availablePlans = [...res.data];

      this.availablePlans.push(this.initEnterprisePlanData(res.data.at(-1).features));

      return res;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap.fetchAvailablePlans = false;
    }
  }

  /**
   * @param {number} [planId]
   * @param {'M' | 'A'} paymentPeriod
   * @param {string | null} promoCode
   * @param {string | null} price
   * @returns {Promise<string[]>}
   */
  async *changePlan(planId, paymentPeriod, promoCode = null, price = null) {
    if (!planId) {
      throw new Error('Plan ID is required');
    }

    if (this.isLoadingMap.changePlan) {
      return;
    }

    const plan = this.getPlanById(planId);

    if (!plan) {
      return;
    }

    this.isLoadingMap.changePlan = true;
    let res;

    try {
      res = yield this.api.post('/change/', {
        plan_id: planId,
        payment_period: paymentPeriod,
        promo_code: promoCode,
      });
    } catch (err) {
      this.isLoadingMap.changePlan = false;
      throw err;
    }

    const { accountStore, billingStore, projectStore } = this.appStore.stores;
    const { subscription } = accountStore.data ?? {};
    const isTrial = subscription?.is_trial;
    const isUpgrade = Number(plan?.price) >= Number(subscription?.price);
    const shouldPoll =
      planId !== subscription.plan_id && (accountStore.isFree || !isUpgrade || isTrial);

    try {
      if (shouldPoll && subscription?.period_start) {
        // XXX: due to race conditions on the backend we need to poll API until new period
        // is started.
        await accountStore.pollNewSubscriptionStatus({
          byPeriodStart: subscription.period_start,
        });
      } else {
        await accountStore.fetchData({ config: noCache });
      }

      await Promise.all([
        this.fetchAvailablePlans(null, { config: noCache }),
        billingStore.fetchUsage({ config: noCache }),
      ]);
    } catch (err) {
      window.Rollbar?.error(err);
    }

    if (projectStore.project) {
      await projectStore.fetchData({ config: noCache });
    }

    this.checkFirstPayment(plan, price);
    this.setCurrentPlanById(planId);
    this.isLoadingMap.changePlan = false;

    return res.data;
  }

  *cancelTrial() {
    this.isLoadingMap.cancelTrial = true;

    try {
      const res = yield this.api.delete('/cancel_trial/');
      this.isLoadingMap.cancelTrial = false;
      message.info('Trial successfully cancelled');
      return res;
    } catch (e) {
      this.isLoadingMap.cancelTrial = false;
      this.processErrors(e);
    }
  }

  /**
   * Make copy of paid plan's features, but with all features enabled. Extend upload's
   * filesize_limit and team_member's limit to max available values.
   *
   * @param {Features} paidPlanFeatures
   * @returns {Features}
   */
  getEnterprisePlanFeatures(paidPlanFeatures) {
    /** @type {Features} */
    const features = {};

    for (const feature of Object.keys(paidPlanFeatures)) {
      features[feature] = { ...paidPlanFeatures[feature] };
      features[feature].is_enabled = true;
    }

    if (features.uploads) {
      features.uploads.filesize_limit = FIVE_TB;
    }

    if (features.team_members) {
      features.team_members.limit = Infinity;
    }

    if (features.virus_checking) {
      features.virus_checking.is_managed = true;
    }

    return features;
  }

  /**
   * @param {Features} paidPlanFeatures
   * @returns {Plan}
   */
  initEnterprisePlanData(paidPlanFeatures) {
    return {
      ...CUSTOM_PLAN_BASE,
      features: this.getEnterprisePlanFeatures(paidPlanFeatures),
    };
  }

  checkFirstPayment(plan, price) {
    const { accountStore } = this.appStore.stores;
    const isPaidPlan = Number(plan?.price) > 0;
    const hasFirstPayment = accountStore.data.meta?.[AccountMetaFieldEnum.HAS_FIRST_PAYMENT];

    if (isPaidPlan && !hasFirstPayment) {
      sendCustomGtmEvent('upgrade_modal_first-purchase-made', { price: price ?? plan.price });

      accountStore.updateAccount(
        { meta: { [AccountMetaFieldEnum.HAS_FIRST_PAYMENT]: true } },
        true
      );
    }
  }

  reset() {
    super.reset();
    this.currentPlan = null;
    this.promotedPlan = null;
    this.freePlan = null;
    this.availablePlans = [];
    this.availablePaymentPeriods = [];
  }
}
