import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams, Outlet } from 'react-router-dom';

import { RoutesEnum } from '~/App';
import { isDefined } from '~/utils/check-value';
import { usePrevious } from '~/hooks/usePrevious';
import { useStores } from '~/stores';

const PROJECT_NOT_FOUND = 404;

export const ProjectWrapper = observer(() => {
  const { projectStore, projectsListStore } = useStores();
  const { pubKey } = useParams();
  const navigate = useNavigate();
  const prevPubKey = usePrevious(pubKey);
  const isChangeProject = pubKey !== prevPubKey;

  useEffect(() => {
    async function fetchData() {
      // process project alias
      if (pubKey === '-') {
        // Translate URLs like /projects/-/ to /projects/:pubKey/
        const project = projectStore.suggestCurrentProject(projectsListStore.data);

        if (project) {
          const url = window.location.href
            .replace(window.location.origin, '')
            .replace('/-/', `/${project.pub_key}/`);

          navigate(url, { replace: true });
        } else {
          navigate(RoutesEnum.HOME);
        }

        return;
      }

      projectStore.setPubKey(pubKey);

      try {
        await projectStore.fetchData({
          statusesOfSilentErrors: [PROJECT_NOT_FOUND],
        });
      } catch (e) {
        const projectNotFound = e.response.status === PROJECT_NOT_FOUND;
        if (projectNotFound) {
          // redirect to the dashboard if project with that pubKey doesn't exist
          // TODO: make 404 page and display it here instead of redirect.
          navigate(RoutesEnum.HOME);
        }
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pubKey]);

  if (!isDefined(projectStore.project) || projectStore.isLoading || isChangeProject) {
    return null;
  }

  return <Outlet />;
});
