// @ts-check
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { normalizeLinkAttrs, LINK_TARGET } from '@uc-common/utils/normalize-links';

// Searches for double and more repetition of slashes without a colon before
const SLASHES_REPETITION_REGEXP = /(?<!:)\/{2,}/g;

/**
 * Transforms external uc-links into absolute ones depending on the environment.
 *
 * @param {string} url
 * @returns {string}
 */
const addUcPrefix = (url) => {
  const isRelativeUrl = /^\/(?!\/)/.test(url);

  return isRelativeUrl
    ? `${import.meta.env.VITE_WEBSITE_BASE}${url}`.replace(SLASHES_REPETITION_REGEXP, '/')
    : url;
};

/** @typedef {React.AnchorHTMLAttributes<HTMLAnchorElement>} DefaultLinkProps */
/** @typedef {Omit<Parameters<typeof RouterLink>[0], 'to'>} RouterLinkProps */
/**
 * @typedef {{
 *   to?: string;
 *   href?: string;
 *   as?: any;
 *   forwardedAs?: any;
 *   target?: string;
 *   rel?: string;
 *   ariaLabel?: string;
 *   children?: React.ReactNode;
 *   className?: string;
 *   props?: object;
 * }} ComponentProps
 */

/**
 * Returns the RouterLink or `a` element
 *
 * @type {React.FC<DefaultLinkProps & RouterLinkProps & ComponentProps>}
 */
export const BaseLink = ({ className, children, href, to, target, ariaLabel, rel, ...props }) => {
  let url = href || to || '';
  const isInternal = !!to;
  const isRouterObjUrl = typeof url === 'object';

  if (isRouterObjUrl) {
    return (
      <RouterLink
        className={className}
        to={url}
        target={target}
        rel={rel}
        aria-label={ariaLabel}
        {...props}
      >
        {children}
      </RouterLink>
    );
  }

  if (!!url && typeof url !== 'string') {
    throw new Error('The href or to attribute must be string type.');
  }

  if (!isInternal) {
    url = addUcPrefix(url);
  }

  const { newUrl, newTarget, newAriaLabel, newRel } = normalizeLinkAttrs(
    url,
    target,
    ariaLabel,
    rel
  );

  if (isInternal) {
    if (target === LINK_TARGET.BLANK) {
      return (
        <a
          className={className}
          href={newUrl}
          target={newTarget}
          rel={newRel}
          aria-label={newAriaLabel}
          {...props}
        >
          {children}
        </a>
      );
    }

    return (
      <RouterLink
        className={className}
        to={newUrl}
        target={newTarget}
        rel={newRel}
        aria-label={newAriaLabel}
        {...props}
      >
        {children}
      </RouterLink>
    );
  }

  return (
    <a
      className={className}
      href={newUrl}
      target={newTarget}
      rel={newRel}
      aria-label={newAriaLabel}
      {...props}
    >
      {children}
    </a>
  );
};
