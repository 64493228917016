// @ts-check
import React from 'react';
import styled from 'styled-components';
import { useTrial } from '~/hooks/useTrial';
import { Button, ButtonSizeEnum, ButtonTypeEnum } from '@uc-common/button';
import { MODALS_ENUM, useModal } from '~/components/Modal';

import { ReactComponent as UpgradeIcon } from '../Navigation/assets/icon-upgrade-white.svg';

export const TrialButtonUserMenu = () => {
  const { trialDaysLeft } = useTrial();
  const [showSelectPlanModal] = useModal(MODALS_ENUM.SELECT_PLAN, {}, { isFullScreenModal: true });

  return (
    <StyledButton
      themeType={ButtonTypeEnum.SUCCESS}
      size={ButtonSizeEnum.EXTRA_SMALL}
      block
      data-analytics="btn_upgrade-sticky-header-trial"
      onClick={showSelectPlanModal}
      icon={UpgradeIcon}
    >
      Upgrade • {trialDaysLeft} day{trialDaysLeft === 1 ? '' : 's'} left in trial
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  gap: 6px;
`;
