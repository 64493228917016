// @ts-check
import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '~/stores';
import { RoutesEnum } from '~/App/Routing/routes';
import { AnnoyingMessageTemplate, annoyingMessageType } from '../AnnoyingMessageTemplate';

export const OnlyImages = observer(() => {
  const { projectStore, accountStore } = useStores();
  const isOnlyImagesDisplayed = projectStore.imagesOnlyAllowed && accountStore.isFree;

  if (!isOnlyImagesDisplayed) {
    return null;
  }

  const messageOptions = projectStore.isShared
    ? {
        message: 'Only images can be uploaded to this project',
        type: annoyingMessageType.WARNING,
      }
    : {
        message: 'Only images can be uploaded to unverified accounts',
        type: annoyingMessageType.WARNING,
        button: {
          label: 'Verify account',
          dataAnalytics: 'btn_verify_account',
          to: RoutesEnum.BILLING,
        },
      };

  return <AnnoyingMessageTemplate {...messageOptions} />;
});
