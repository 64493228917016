import { flow, observable, makeObservable } from 'mobx';
import { getHostNameFromUrl } from '../../utils/browser';
import { BaseStore } from '../base-store';
import { dataLikeArrayMixin } from '../mixins';

/** @typedef {import('dashboard-api-types').whitelist} Whitelist */
export class ProjectWhitelistStore extends dataLikeArrayMixin(BaseStore) {
  /** @type {Whitelist[]} */
  data = [];

  limit = 100;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      addDomain: flow,
      removeDomain: flow,
      fetchData: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/proxy/permitted_domains/`;
  }

  /** @param {{ config: import('axios').AxiosRequestConfig }} options */
  *fetchData({ config } = {}) {
    this.isLoading = this.isInitialLoading = true;

    try {
      const res = yield this.api.get(`/?limit=${this.limit}`, config);
      this.data = res.data.results;
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }

  /** @param {string} domain */
  *addDomain(domain) {
    this.isLoading = true;

    const hostName = getHostNameFromUrl(domain);

    try {
      const res = yield this.api.post('/', { domain: hostName });
      this.data.push(res.data);
    } finally {
      this.isLoading = false;
    }
  }

  /** @param {number} id */
  *removeDomain(id) {
    this.isLoading = this.isLoadingMap[id] = true;

    try {
      yield this.api.delete(`/${id}/`);
      this.removeItemFromDataByField(id);
    } finally {
      this.isLoading = this.isLoadingMap[id] = false;
    }
  }
}
