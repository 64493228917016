// @ts-check
import React from 'react';
import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle(
  ({ theme: { isDark } }) => css`
    &:focus-visible:not(textarea):not(input) {
      outline-offset: 2px;
      outline: ${isDark ? '#b3bfcb solid 2px' : '#000000 solid 2px'};
      transition: all 0.2s ease-in-out;
    }
  `
);

export const OutlineManager = () => {
  return <GlobalStyle />;
};
