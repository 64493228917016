import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { BaseStore, clearRequestsCache, noCache } from '~/stores/base-store';
import { RoutesEnum } from '~/App';
import { isDefined } from '~/utils/check-value';
import { message } from '~/components/Toaster';

export const AccountStatusEnum = {
  ACTIVE: 'active',
  UNPAID: 'unpaid',
};

export const AccountMetaFieldEnum = {
  IS_ALREADY_QUALIFIED: 'is_already_qualified',
  IS_DASHBOARD_TOUR_COMPLETED: 'is_dashboard_tour_completed',
  IS_TRIAL_MODAL_SEEN: 'is_trial_modal_seen',
  HAS_FIRST_PAYMENT: 'has_first_payment',
  CUS_DEV_COUNT_OF_IMPRESSIONS: 'cus_dev_count_of_impressions',
  IS_DEMO_COMPLETED: 'is_demo_completed',
  FILE_PAGE_SURVEY_COUNTER: 'file_page_survey_counter',
  DEFAULT_PROJECT_ROUTE: 'default_project_route',
};

/** @typedef {import('dashboard-api-types').account} Account */
/**
 * @template T
 * @typedef {import('axios').AxiosResponse<T>} AxiosResponse<T>
 */

/** @augments {BaseStore<Account>} */
export class AccountStore extends BaseStore {
  /** @type {Account | null} */
  data = null;

  /** @type {boolean} */
  isFree = false;

  isBlocked = false;

  isLogout = false;

  isUnknownUser = true;

  isAuthenticated = false;

  beaconSignature = null;

  cantRemoveCard = false;

  /** @param {import('../app-store').AppStore} store */
  constructor(store) {
    super(store);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      isFree: observable,
      isBlocked: observable,
      isLogout: observable,
      updateAccount: flow,
      beaconSignature: observable,
      getBeaconSignature: flow,
      setInitialData: action,
      setDashboardTourCompleted: flow,
      setTrialModalSeen: flow,
      setIsLogout: action,
      setIsBlocked: action,
      isAuthenticated: observable,
      isUnknownUser: observable,
      setIsAuthenticated: action,
      setIsUnknownUser: action,
      setCantRemoveCard: action,
      deleteAccount: flow,
      isAlreadyQualified: computed,
      defaultProjectRoute: computed,
      rollbarPerson: computed,
      personalizedOffer: computed,
      qualify: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/account/';
  }

  get isAlreadyQualified() {
    return this.data?.meta?.[AccountMetaFieldEnum.IS_ALREADY_QUALIFIED];
  }

  /** @returns {typeof RoutesEnum[keyof typeof RoutesEnum]} */
  get defaultProjectRoute() {
    // Check hypothesis about conversion drop because of redirect to Get Started page instead
    // of Files page: https://uploadcare.atlassian.net/browse/DASH-1950
    return RoutesEnum.PROJECT_FILES_INDEX;

    // const isSingleProject = this.appStore.stores.projectsListStore.data.length === 1;
    // const currentDefault = this.data.meta?.[AccountMetaFieldEnum.DEFAULT_PROJECT_ROUTE];
    // const isExistingRoute = Object.values(RoutesEnum).includes(currentDefault);
    // const fallbackRoute = isSingleProject
    //   ? RoutesEnum.PROJECT_GET_STARTED
    //   : RoutesEnum.PROJECT_FILES_INDEX;

    // return isExistingRoute ? currentDefault : fallbackRoute;
  }

  onInit() {
    // subscribe to card store, for sync has_card in account store
    reaction(
      () => !!this.appStore.stores.accountCardStore.data,
      (hasCard) => {
        if (this.data?.subscription) {
          this.data.subscription.has_card = hasCard;
        }
      }
    );

    // subscribe to account subscription, for sync isFree property in account store
    reaction(
      () => this.data,
      (data) => {
        this.setIsUnknownUser(false);
        const price = data?.subscription?.price;
        if (isDefined(price)) {
          this.isFree = Number(price) === 0;
        }
      }
    );

    // subscribe to logout
    reaction(
      () => this.isLogout,
      (value) => {
        if (!value) return;

        clearRequestsCache();
        window.localStorage?.clear();
      }
    );

    reaction(
      () => this.isAuthenticated,
      (isAuthenticated) => {
        if (isAuthenticated) {
          this.setIsUnknownUser(false);
        }
      }
    );
  }

  /**
   * @param {{
   *   config?: import('axios').AxiosRequestConfig;
   *   statusesOfSilentErrors?: number[];
   * }} params
   * @returns {Promise<AxiosResponse<Account>>}
   */
  *fetchData({ config, statusesOfSilentErrors } = {}) {
    this.isLoading = true;

    try {
      /** @type {AxiosResponse<Account>} */
      const res = yield this.api.get('/', config);
      this.data = res.data;
      return res;
    } catch (e) {
      if (e instanceof Error) {
        this.processErrors(e, { statusesOfSilentErrors });
      } else {
        throw e;
      }
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }

  /**
   * @param {object} data
   * @param {boolean} [isSilent=false]
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  *updateAccount(data, isSilent = false) {
    this.isLoadingMap.updateAccount = true;

    try {
      const res = yield this.api.put('/', data);
      this.data = res.data;

      if (isSilent === false) {
        message.info('Profile info was updated.');
      }

      return res;
    } catch (e) {
      if (e instanceof Error) {
        this.processErrors(e);
      } else {
        throw e;
      }
    } finally {
      this.isLoadingMap.updateAccount = false;
    }
  }

  /** @returns {Promise<AxiosResponse<import('dashboard-api-types').helpscout>>} */
  *getBeaconSignature() {
    this.isLoadingMap.getBeaconSignature = true;

    try {
      const res = yield this.api.get('/helpscout/');
      this.beaconSignature = res.data.signature;

      return res;
    } catch (e) {
      if (e instanceof Error) {
        this.processErrors(e);
      } else {
        throw e;
      }
    } finally {
      this.isLoadingMap.getBeaconSignature = false;
    }
  }

  /** @returns {Promise<void>} */
  *setDashboardTourCompleted() {
    const resp = yield this.api.put('/', {
      meta: { [AccountMetaFieldEnum.IS_DASHBOARD_TOUR_COMPLETED]: true },
    });
    if (resp.status === 200) {
      if (this.data?.meta) {
        this.data.meta[AccountMetaFieldEnum.IS_DASHBOARD_TOUR_COMPLETED] = true;
      }
    }
  }

  /** @returns {Promise<void>} */
  *setTrialModalSeen() {
    const resp = yield this.api.put('/', {
      meta: { [AccountMetaFieldEnum.IS_TRIAL_MODAL_SEEN]: true },
    });
    if (resp.status === 200) {
      if (this.data?.meta) {
        this.data.meta[AccountMetaFieldEnum.IS_TRIAL_MODAL_SEEN] = true;
      }
    }
  }

  /** @returns {Promise<{ accountRemoved: boolean; requestSent: boolean }>} */
  *deleteAccount() {
    this.isLoadingMap.delete = true;

    try {
      const resp = yield this.api.delete('/');
      return {
        accountRemoved: resp.status === 204,
        requestSent: resp.status === 201,
      };
    } finally {
      this.isLoadingMap.delete = false;
    }
  }

  /**
   * @param {object} data
   * @returns {Promise<import('axios').AxiosResponse>}
   */
  *qualify(data) {
    this.isLoadingMap.qualify = true;

    try {
      const response = yield this.api.post('/qualification/', data);
      if (this.data?.meta) {
        this.data.meta[AccountMetaFieldEnum.IS_ALREADY_QUALIFIED] = true;
      }
      return response;
    } catch (e) {
      if (e instanceof Error) {
        this.processErrors(e);
      } else {
        throw e;
      }
    } finally {
      this.isLoadingMap.qualify = false;
    }
  }

  /** @param {Partial<import('dashboard-api-types').account>} data */
  setInitialData(data) {
    this.data = data;
    this.isInitialLoading = false;
  }

  /** @param {boolean} isLogout */
  setIsLogout(isLogout) {
    this.isLogout = isLogout;
  }

  /** @param {boolean} isBlocked */
  setIsBlocked(isBlocked) {
    this.isBlocked = isBlocked;
  }

  setIsAuthenticated(isAuthenticated = false) {
    this.isAuthenticated = isAuthenticated;
  }

  setIsUnknownUser(isUnknownUser = false) {
    this.isUnknownUser = isUnknownUser;
  }

  setCantRemoveCard(cantRemoveCard = false) {
    this.cantRemoveCard = cantRemoveCard;
  }

  get rollbarPerson() {
    if (!this.data) {
      return {};
    }
    return {
      id: this.data.id,
      email: this.data.email,
    };
  }

  /**
   * @returns {{
   *   plan_id: number;
   *   monthly_promo_code?: string;
   *   monthly_discount?: number;
   *   monthly_discount_duration?: number;
   *   monthly_button_text?: string;
   *   annual_promo_code?: string;
   *   annual_discount?: number;
   *   annual_button_text?: string;
   *   title?: string;
   *   preview_message?: string;
   *   custom_description?: string;
   *   start_date?: string;
   * } | null}
   */
  get personalizedOffer() {
    const offer = this.data?.meta?.personalized_offer;
    if (offer === undefined) {
      return null;
    }

    if (!offer?.plan_id) {
      window?.Rollbar?.error('No plan ID in personalized offer', offer ?? {});
      return null;
    }

    if (!offer.annual_promo_code && !offer.monthly_promo_code) {
      window?.Rollbar?.error('No promo codes in personalized offer', offer);
      return null;
    }

    if (offer.annual_promo_code && !offer.annual_button_text && !offer.annual_discount) {
      window?.Rollbar?.error(
        'No annual_button_text or annual_discount in personalized offer',
        offer
      );
      return null;
    }

    if (
      offer.monthly_promo_code &&
      !offer.monthly_button_text &&
      (!offer.monthly_discount || !offer.monthly_discount_duration)
    ) {
      window?.Rollbar?.error(
        'No monthly_button_text or monthly_discount in personalized offer',
        offer
      );
      return null;
    }

    return offer;
  }

  /**
   * @param {{ byPeriodStart?: string; byHasCard?: boolean }} props
   * @returns {Promise<AxiosResponse<Account>>}
   */
  async pollNewSubscriptionStatus({ byPeriodStart, byHasCard }) {
    if (byPeriodStart === undefined && byHasCard === undefined) {
      throw new Error('Specify byPeriodStart or byHasCard');
    }
    let attempt = 1;

    /**
     * @param {(value: Promise<AxiosResponse<Account>>) => void} resolve
     * @param {(error: Error) => void} reject
     */
    const poll = (resolve, reject) => {
      this.fetchData({ config: noCache })
        .then((res) => {
          const { subscription } = res.data ?? {};

          if (attempt++ >= 10) {
            resolve(res);
          } else if (byPeriodStart !== undefined && subscription?.period_start === byPeriodStart) {
            setTimeout(poll.bind(null, resolve, reject), 1000);
          } else if (byHasCard !== undefined && subscription?.has_card === byHasCard) {
            setTimeout(poll.bind(null, resolve, reject), 1000);
          } else {
            resolve(res);
          }
        })
        .catch(reject);
    };

    return new Promise((resolve, reject) => {
      poll(resolve, reject);
    });
  }

  reset() {
    super.reset();
    this.data = null;
    this.isFree = false;
    this.isBlocked = false;
    this.isLogout = false;
    this.beaconSignature = null;
    this.cantRemoveCard = false;
  }
}
