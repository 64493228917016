import { action, makeObservable } from 'mobx';

export const dataLikeArrayMixin = (Base) =>
  class extends Base {
    data = [];

    constructor(...args) {
      super(...args);

      makeObservable(this, {
        removeItemFromDataByField: action,
        updateItemInDataByField: action,
      });
    }

    removeItemFromDataByField(value, field = 'id') {
      this.data = this.data.filter((listItem) => listItem[field] !== value);
    }

    updateItemInDataByField(item, field = 'id') {
      this.data = this.data.map((listItem) => (listItem[field] === item[field] ? item : listItem));
    }

    reset() {
      super.reset();
      this.data = [];
    }
  };
