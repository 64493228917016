// @ts-check
import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '~/stores';
import { toDashCase } from '~/utils/browser';

import {
  UpgradePlanContainer,
  UpgradePlanText,
  UpgradePlanButton,
} from './styles/UpgradePlan.styles';

/** @typedef {import('dashboard-api-types').feature} Feature */

/**
 * If you pass `feature` with `upgradeOptions`, this component will automatically suggest the first
 * plan from available ones where this feature is enabled. If feature is not specified, the first
 * paid plan will be suggested for upgrade.
 *
 * @type {React.FC<{
 *   upgradeOptions?: { feature?: Feature };
 *   children?: React.ReactNode;
 * }>}
 */
export const UpgradePlan = observer(({ upgradeOptions = {}, children }) => {
  const { accountPlanStore, projectStore } = useStores();
  const { feature } = upgradeOptions;

  if (projectStore.isShared) {
    return (
      <UpgradePlanContainer $isWarning>
        {children ? (
          <UpgradePlanText>{children}</UpgradePlanText>
        ) : (
          <UpgradePlanText>This feature is not included in project owner’s plan</UpgradePlanText>
        )}
      </UpgradePlanContainer>
    );
  }

  const plan = accountPlanStore.getPlanWithEnabledFeature(feature);
  if (plan) {
    return (
      <UpgradePlanContainer>
        <UpgradePlanText>{children}</UpgradePlanText>
        <UpgradePlanButton
          planId={plan.id}
          data-analytics={`btn_upgrade-${toDashCase(feature?.title)}`}
        >
          Upgrade
        </UpgradePlanButton>
      </UpgradePlanContainer>
    );
  }

  return null;
});
