// @ts-check
import { UploadcareAuthSchema } from '@uploadcare/rest-client';

class HttpError extends Error {
  /**
   * @param {string} message
   * @param {number} code
   * @param {string} body
   */
  constructor(message, code, body) {
    super(message);

    this.code = code;
    this.body = body;
  }
}

/**
 * Returns UploadcareAuthSchema which allows to sign requests to REST API with project secret key
 * via intermediate request to dashboard API.
 *
 * @param {string} pubKey Project's public key.
 * @returns {UploadcareAuthSchema}
 */
export const getRestApiAuthSchema = (pubKey) =>
  new UploadcareAuthSchema({
    publicKey: pubKey,
    /**
     * @param {string} signString
     * @returns {Promise<string>}
     */
    signatureResolver: async (signString) => {
      /** @type {Response} */
      const res = await fetch(
        `/apps/api/v0.1/auth/signature/${pubKey}/?string=${encodeURIComponent(signString)}`,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );

      if (res.status !== 200) {
        throw new HttpError('Cannot get signature', res.status, await res.text());
      }

      /** @type {any} */
      const data = await res.json();

      return data.signature;
    },
  });
