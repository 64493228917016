import React, { useState, useEffect } from 'react';

import {
  AppContentLoaderContainer,
  AppContentLoaderDots,
  AppContentLoaderDot,
} from './AppContentLoader.styles';

export const AppContentLoader = ({ className, timeOut = 500 }) => {
  const [isShowLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const timeoutToShow = setTimeout(() => {
      setShowLoading(true);
    }, timeOut);

    return () => {
      clearTimeout(timeoutToShow);
    };
  });

  return isShowLoading ? (
    <AppContentLoaderContainer className={className}>
      <AppContentLoaderDots>
        <AppContentLoaderDot />
        <AppContentLoaderDot />
        <AppContentLoaderDot />
      </AppContentLoaderDots>
    </AppContentLoaderContainer>
  ) : null;
};
