// @ts-check
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BeaconFacadeButton } from './BeaconFacadeButton';
import { BEACON_ID, identifyUser, initBeacon } from './utils';

/**
 * @typedef {{
 *   signature: string | null;
 *   name?: string;
 *   email?: string;
 *   isNew: string;
 * } | null} User
 */
export {};

/**
 * @typedef {object} BeaconProps
 * @property {string} className
 * @property {string} beaconKey
 * @property {string} pathname
 * @property {User} user
 * @property {string | string[]} allowedRoutes
 * @property {boolean} [isShowButton=true]
 */

/**
 * @param {BeaconProps} props
 * @returns {JSX.Element | null}
 */
export const Beacon = ({
  className,
  beaconKey,
  pathname,
  user = null,
  allowedRoutes = 'all',
  isShowButton = true,
}) => {
  const [isLoadScript, setIsLoadScript] = useState(false);
  const [isInitialized, setIsInitialized] = useState(!!window.Beacon);

  const isAllowedRoute = allowedRoutes === 'all' || allowedRoutes.includes(pathname);
  const [isFacadeVisible, setIsFacadeVisible] = useState(isShowButton && isAllowedRoute);

  const loadScript = () => {
    if (!isInitialized) {
      setIsLoadScript(true);
    }

    setIsFacadeVisible(true);
  };

  const startBeacon = () => {
    if (!isInitialized && window.Beacon) {
      initBeacon(beaconKey);

      window.Beacon('once', 'ready', () => {
        identifyUser(user);
        window.Beacon?.('open');
        setIsInitialized(true);
      });
    }
  };

  const handleHelmetChangeState = () => {
    if (isInitialized) return;

    const beaconScript = document.getElementById(BEACON_ID);
    beaconScript && beaconScript.addEventListener('load', startBeacon);
  };

  useEffect(() => {
    if (!isInitialized && window.Beacon) {
      setIsInitialized(true);
      startBeacon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized]);

  useEffect(() => {
    // Adding new functionality to windows.Beacon without overwriting it.
    // Hack that allows you to open the chat using some third button (not BeaconFacadeButton) on different pages
    const originalBeacon = window.Beacon;

    window.Beacon = (type) => {
      if (type === 'open') {
        isInitialized ? originalBeacon?.('open') : loadScript();
      } else {
        originalBeacon?.(type);
      }
    };

    return () => {
      window.Beacon = originalBeacon;
    };

    // we need to open the chat using the external button more than once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isInitialized && setIsFacadeVisible(false);
  }, [isInitialized]);

  if (!beaconKey) return null;

  return (
    <>
      {isFacadeVisible && (
        <BeaconFacadeButton className={className} onClick={loadScript} isLoading={isLoadScript} />
      )}
      {isLoadScript && !isInitialized && (
        <Helmet onChangeClientState={handleHelmetChangeState}>
          <script type="text/javascript">
            {`!function(a,n,e){function t(){var e=n.getElementsByTagName("script")[0],t=n.createElement("script");t.type="text/javascript",t.async=!0,t.src="https://beacon-v2.helpscout.net",t.id="${BEACON_ID}",e.parentNode.insertBefore(t,e)}if(a.Beacon=e=function(e,t,n){a.Beacon.readyQueue.push({method:e,options:t,data:n})},e.readyQueue=[],"complete"===n.readyState)return t();a.attachEvent?a.attachEvent("onload",t):a.addEventListener("load",t,!1)}(window,document,window.Beacon||function(){});`}
          </script>
        </Helmet>
      )}
    </>
  );
};
