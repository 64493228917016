// @ts-check

/**
 * @param {number} count
 * @param {string} singleForm
 * @param {string} pluralForm
 * @param {boolean} [strict]
 * @returns {string}
 */
export const pluralize = (count, singleForm, pluralForm, strict) => {
  const form = count === 1 ? singleForm : pluralForm;

  if (strict) {
    return form;
  }

  return `${count}${'\u00A0'}${form}`;
};
