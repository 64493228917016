// @ts-check
import React from 'react';
import Helmet from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../stores';

/** @type {React.FC<{ title: string }>} */
export const Meta = observer(({ title }) => {
  const { pageStore } = useStores();

  return (
    <Helmet titleTemplate={pageStore.titleTemplate}>
      <title>{title}</title>
    </Helmet>
  );
});
