import styled, { css } from 'styled-components';

import { Link } from '../../Link';

const baseTitleStyles = css`
  color: #000000;
  line-height: 1.2;
  font-weight: 400;
`;

export const H1 = styled.h1`
  ${baseTitleStyles};
  font-size: 44px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.025em;
`;

export const H2 = styled.h2`
  ${baseTitleStyles};
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.025em;
`;

export const H3 = styled.h3`
  ${baseTitleStyles};
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.004px;

  small {
    font-weight: 500;
    font-size: 22px;
    color: #b5bbc7;
    margin-right: 14px;
  }

  span {
    font-weight: 400;
    opacity: 0.8;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.004px;
  color: #808080;
`;

export const BlueButton = styled.button`
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.2;
  color: #3771ff;
  transition: color 0.2s ease;

  svg {
    margin-left: 6px;
  }

  &:hover {
    color: #000000;
  }
`;

export const Mono = styled.span`
  font-family: var(--font-mono);
`;

export const StepHeading = styled.h4`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.3;
  color: #000000;

  &::before {
    --size: 20px;
    display: inline-block;
    counter-increment: steps;
    content: counter(steps);
    font-size: 11px;
    line-height: var(--size);
    text-align: center;
    color: #000000;
    width: var(--size);
    height: var(--size);
    border-radius: 50px;
    margin-right: 8px;
    background-color: #e9ebef;
  }
`;

export const LinkWithArrow = styled(Link)`
  &::after {
    display: inline-block;
    content: '→';
    margin-left: 0.1em;
    transition: transform 0.3s ease;
  }
  &:hover::after {
    transform: translateX(0.15em);
  }
`;
