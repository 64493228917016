// @ts-check
import { CONVERSION_STATUS } from '~/stores/constants/convert-documents';

/** @typedef {import('@uploadcare/rest-client').FileInfoVariations | null | undefined} Variations */

/**
 * @param {{ value: string; label: string }[]} allFormats
 * @param {{ value: string; label: string }[]} usedFormats
 */
export const getAvailableFormats = (allFormats, usedFormats) => {
  if (!allFormats?.length) return [];
  if (!usedFormats?.length) return allFormats;

  const availableFormats = usedFormats.map(({ value }) => value);
  const set = new Set(availableFormats);

  return allFormats
    .filter(({ value }) => !set.has(value))
    .sort((a, b) => a.value.localeCompare(b.value));
};

/** @param {string} uuid */
export const buildLink = (uuid) => `${import.meta.env.VITE_UPLOADCARE_CDN_BASE}${uuid}/`;

/** @param {string} conversionInfo */
// conversionInfo example: 'document/-/format/tiff/'
const extractFormatValue = (conversionInfo) => conversionInfo.split('/')[3];

/** @param {Variations} variations */
export const normalizeUsedConversions = (variations) => {
  if (!variations || !Object.keys(variations).length) return [];

  return Object.entries(variations).map(([conversionInfo, conversionUuid]) => ({
    format: extractFormatValue(conversionInfo),
    linkTo: buildLink(conversionUuid),
    status: CONVERSION_STATUS.SUCCESS,
  }));
};

/** @param {Variations} variations */
export const normalizeUsedFormats = (variations) => {
  if (!variations || !Object.keys(variations).length) return [];

  return Object.keys(variations).map((conversionInfo) => ({
    value: extractFormatValue(conversionInfo),
    label: extractFormatValue(conversionInfo).toUpperCase(),
  }));
};
