// @ts-check
import React from 'react';

import { MODALS_ENUM, useModal } from '~/components/Modal';
import { observer } from 'mobx-react-lite';
import { useStores } from '~/stores';
import { AnnoyingMessageTemplate, annoyingMessageType } from '../AnnoyingMessageTemplate';

const offerMessageOptions = {
  button: {
    label: 'See options',
    dataAnalytics: 'btn_personalized-offer_learn-more',
  },
  type: annoyingMessageType.WARNING,
};

export const PersonalizedOffer = observer(() => {
  const { accountStore } = useStores();
  const [showPersonalizedOffer] = useModal(MODALS_ENUM.PERSONALIZED_OFFER);

  if (!accountStore.personalizedOffer) {
    return null;
  }

  const startDate = accountStore?.personalizedOffer?.start_date || 'May 15';
  const message =
    accountStore.personalizedOffer?.preview_message ||
    `Starting ${startDate}, your subscription is switching to operations-based billing`;

  return (
    <AnnoyingMessageTemplate
      message={message}
      type={offerMessageOptions.type}
      button={{
        ...offerMessageOptions.button,
        onClick: showPersonalizedOffer,
      }}
    />
  );
});
