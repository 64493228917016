// @ts-check
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { useKey, useClickAway } from 'react-use';

/**
 * @typedef {{
 *   isOpen: boolean;
 *   setIsOpen: import('react').Dispatch<import('react').SetStateAction<boolean>>;
 * }} DropDownContextProp
 */
export const DropDownContext = React.createContext(/** @type {DropDownContextProp} */ ({}));

/**
 * @type {React.FC<{
 *   children?: React.ReactNode;
 *   className?: string;
 * }>}
 */
export const DropDown = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const hide = useCallback(() => {
    setIsOpen(false);
  }, []);

  useKey('Escape', hide);
  useClickAway(ref, hide);

  return (
    <DropDownContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      <Container className={className} ref={ref}>
        {children}
      </Container>
    </DropDownContext.Provider>
  );
};

const Container = styled.div`
  position: relative;
  width: max-content;
`;
