import { config, useTransition } from '@react-spring/web';
import React, { Suspense, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useClickAway, useKey } from 'react-use';
import styled from 'styled-components';

import { AnalyticsEventCategoryEnum, sendCustomGtmEvent } from '~/analytics/utils/tracking';
import { AppContentLoader } from '~/components/AppContentLoader';
import { MODALS_ENUM, Modal } from '~/components/Modal';
import { useModalsContext } from './context/ModalsContext';
import { MODALS_COMPONENTS } from './data/ModalsData';

const ModalsRendererContainer = styled.div`
  position: relative;
  z-index: 200;
`;
export const ModalsRenderer = () => {
  const { activeModals, setActiveModals } = useModalsContext();
  const modalsArray = Array.from(activeModals);
  const lastActiveModal = modalsArray[modalsArray.length - 1] ?? [];
  const lastActiveModalRef = useRef(null);
  const { pathname } = useLocation();

  const transitions = useTransition(modalsArray, {
    keys: (modal) => modal[0],
    from: { opacity: 0, scale: 0.95, transform: 'translate3d(0, -15%, 0)' },
    enter: { opacity: 1, scale: 1, transform: 'translate3d(0, 0%, 0)' },
    leave: { opacity: 0, scale: 0.95, transform: 'translate3d(0, 15%, 0)' },
    config: config.stiff,
    onDestroyed: ([
      ,
      {
        config: { onHidden },
      },
    ]) => {
      onHidden?.();
    },
  });

  const closeModal = (id, modalName) => {
    if (!id) return;

    sendCustomGtmEvent(`Modal closed : ${modalName}`, {
      category: AnalyticsEventCategoryEnum.LAYOUT,
    });

    setActiveModals((currentModals) => {
      const newActiveModals = new Map(currentModals);
      newActiveModals.delete(id);
      return newActiveModals;
    });
  };

  useKey(
    'Escape',
    () => lastActiveModal.length && closeModal(lastActiveModal[0], lastActiveModal[1]?.name),
    { event: 'keydown' },
    [lastActiveModal]
  );
  useClickAway(lastActiveModalRef, () => {
    if (lastActiveModal[1]?.config?.hideOnOutsideClick) {
      closeModal(lastActiveModal[0], lastActiveModal[1].name);
    }
  });

  useEffect(() => {
    setActiveModals(new Map());
  }, [pathname, setActiveModals]);

  return (
    <ModalsRendererContainer>
      {transitions((style, [, { config, onClose, props, name, onOpenArgs }]) => {
        const ModalContent = MODALS_COMPONENTS[MODALS_ENUM[name]];

        return ModalContent ? (
          <Modal style={style} ref={lastActiveModalRef} {...config}>
            <Suspense fallback={<AppContentLoader />}>
              <ModalContent hideModal={onClose} {...props} onOpenArgs={onOpenArgs} />
            </Suspense>
          </Modal>
        ) : null;
      })}
    </ModalsRendererContainer>
  );
};
