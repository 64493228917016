import React, { useRef, useCallback } from 'react';
import { generatePath, matchPath, useLocation } from 'react-router-dom';

import { RoutesEnum } from '~/App';
import { useStores } from '~/stores';
import { useDropdown } from '~/hooks/useDropdown';
import { ProjectAvatar } from '../ProjectAvatar/ProjectAvatar';

import {
  ProjectDropdownContainer,
  ProjectDropdownIcon,
  ProjectDropdownMenu,
  ProjectDropdownToggle,
  ProjectDropdownContent,
  ProjectDropdownToggleText,
  ProjectDropdownHeading,
  ProjectDropdownLink,
  ProjectDropdownToggleAvatar,
  ProjectDropdownProjects,
} from './styles/ProjectDropdown.styles';

const checkRoutesMap = (pathname) => {
  // { from: to }
  const routesMap = {
    [RoutesEnum.PROJECT_API_LOGS_ITEM]: RoutesEnum.PROJECT_API_LOGS,
  };

  for (const route in routesMap) {
    if (matchPath({ path: route, end: true }, pathname)) {
      return routesMap[route];
    }
  }

  return null;
};

export const ProjectDropdown = () => {
  const { projectStore, projectsListStore } = useStores();
  const { pathname, hash } = useLocation();
  const dropdownMenuRef = useRef();
  const [isShowMenu, setIsShowMenu] = useDropdown(dropdownMenuRef, {
    alwaysHideByClick: true,
  });

  const currentProject = projectStore.project;
  const { ownedProjects, sharedProjects } = projectsListStore.projects;

  const getPathToProject = useCallback(() => {
    if (currentProject && pathname.includes(currentProject.pub_key)) {
      const route = checkRoutesMap(pathname);
      return route || pathname.replace(currentProject.pub_key, ':pubKey') + hash;
    }
    return RoutesEnum.PROJECT;
  }, [pathname, currentProject, hash]);

  return (
    <ProjectDropdownContainer>
      <ProjectDropdownToggle
        as="button"
        onClick={() => setIsShowMenu(true)}
        data-cy="btn_top-menu_project-list"
        $isActive={isShowMenu}
      >
        <ProjectDropdownToggleAvatar pubKey={currentProject?.pub_key ?? '0'} />
        <ProjectDropdownToggleText>{currentProject?.name}</ProjectDropdownToggleText>
        {isShowMenu ? (
          <ProjectDropdownIcon
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="2"
            viewBox="0 0 10 2"
            fill="none"
          >
            <use xlinkHref="#dropdown-close" />
          </ProjectDropdownIcon>
        ) : (
          <ProjectDropdownIcon
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="6"
            viewBox="0 0 9 6"
            fill="none"
          >
            <use xlinkHref="#dropdown-open" />
          </ProjectDropdownIcon>
        )}
      </ProjectDropdownToggle>

      <ProjectDropdownMenu $isShow={isShowMenu} ref={dropdownMenuRef}>
        <ProjectDropdownLink to={RoutesEnum.HOME}>
          <ProjectDropdownToggleText data-cy="btn_top-menu_workspace">
            Go to workspace
          </ProjectDropdownToggleText>
        </ProjectDropdownLink>
        <ProjectDropdownContent>
          {sharedProjects.length > 0 && ownedProjects.length > 0 && (
            <ProjectDropdownHeading>My projects</ProjectDropdownHeading>
          )}
          <ProjectDropdownProjects>
            {ownedProjects.map(({ pub_key: pubKey, name }) => (
              <ProjectDropdownLink
                key={pubKey}
                title={name}
                to={generatePath(getPathToProject(), {
                  pubKey,
                })}
                data-analytics="btn_project_select"
                $isSelected={pubKey === currentProject?.pub_key}
              >
                <ProjectAvatar pubKey={pubKey} />
                <ProjectDropdownToggleText>{name}</ProjectDropdownToggleText>
              </ProjectDropdownLink>
            ))}
          </ProjectDropdownProjects>

          {sharedProjects.length > 0 && (
            <>
              <ProjectDropdownHeading>Shared with me</ProjectDropdownHeading>
              <ProjectDropdownProjects>
                {sharedProjects.map(({ pub_key: pubKey, name }) => (
                  <ProjectDropdownLink
                    key={pubKey}
                    title={name}
                    to={generatePath(getPathToProject(), {
                      pubKey,
                    })}
                    $isSelected={pubKey === currentProject?.pub_key}
                  >
                    <ProjectAvatar pubKey={pubKey} />
                    <ProjectDropdownToggleText>{name}</ProjectDropdownToggleText>
                  </ProjectDropdownLink>
                ))}
              </ProjectDropdownProjects>
            </>
          )}
        </ProjectDropdownContent>
      </ProjectDropdownMenu>
    </ProjectDropdownContainer>
  );
};
