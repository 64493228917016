// @ts-check
import { COLORS } from './constants';
import { contentAppearance } from './styles';

// TODO: move to constants https://uploadcare.atlassian.net/browse/WEB-1486
export const breakpoints = ['768px', '1024px', '1280px'];

/** @param {string} breakpoint */
export const up = (breakpoint) => `@media (min-width: calc(${breakpoint}))`;

export const ThemesEnum = {
  DARK: 'dark',
  LIGHT: 'light',
  MID: 'midtone',
};

export const theme = {
  breakpoints,
  up,
  tablet: () => up(breakpoints[0]),
  laptop: () => up(breakpoints[1]),
  desktop: () => up(breakpoints[2]),

  isDark: false,
  themeName: ThemesEnum.LIGHT,

  animations: {
    contentAppearance,
  },

  colors: COLORS,
};
