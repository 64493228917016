import React from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useMeasure } from 'react-use';
import { AccountStatusEnum } from '~/stores/account/account-store';
import { useStores } from '~/stores';
import { PersonalizedOffer } from './PersonalizedOffer';
import { OnlyImages } from './OnlyImages';
import { PushingToUpgrade } from './PushingToUpgrade';
import { AnnoyingMessageTemplate } from './AnnoyingMessageTemplate';
import {
  accountUnpaidMessageOptions,
  hitUploadLimitsMessageOptions,
  marketingBannerMessage,
} from './data';

const Container = styled.div`
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;

  position: sticky;
  top: 0;

  z-index: 101;
`;

const GlobalStyles = createGlobalStyle(
  ({ annoyingMessagesHeight = 0 }) => css`
    :root {
      --annoying-messages-height: ${annoyingMessagesHeight}px;
    }
  `
);

export const AnnoyingMessages = observer(() => {
  const [ref, { height }] = useMeasure();
  const { accountStore, projectStore } = useStores();

  // ---> AccountUnpaidMessage, HitUploadLimitsMessage --->
  let isAccountUnpaidDisplayed = accountStore.data?.status === AccountStatusEnum.UNPAID;
  let isHitUploadLimitsDisplayed = accountStore.data?.subscription?.hit_upload_limits;

  if (projectStore.isShared) {
    // hide account errors for shared project in project pages
    isAccountUnpaidDisplayed = false;
    isHitUploadLimitsDisplayed = false;
  }

  const errors = [isAccountUnpaidDisplayed, isHitUploadLimitsDisplayed].filter(Boolean);

  return (
    <>
      <GlobalStyles annoyingMessagesHeight={height} />
      <Container ref={ref}>
        <AnnoyingMessageTemplate {...marketingBannerMessage} />
        {errors.length ? (
          <>
            {isAccountUnpaidDisplayed && (
              <AnnoyingMessageTemplate {...accountUnpaidMessageOptions} />
            )}
            {!isAccountUnpaidDisplayed && isHitUploadLimitsDisplayed && (
              <AnnoyingMessageTemplate {...hitUploadLimitsMessageOptions} />
            )}
          </>
        ) : (
          <>
            <PushingToUpgrade />
            <OnlyImages />
          </>
        )}

        <PersonalizedOffer />
      </Container>
    </>
  );
});
