import styled, { css } from 'styled-components';

import { InputSpinner } from '../../spinner';

export const FacadeButton = styled.button`
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-color: rgb(35, 146, 236);
  border: none;
  cursor: pointer;
  z-index: 110;
  transition: background-color 0.2s linear;

  /* from Beacon styles */
  @media (max-height: 740px) {
    bottom: 10px;
    right: 20px;
  }

  &:hover {
    background-color: rgb(27, 138, 228);
    box-shadow: rgb(0 0 0 / 6%) 0 0 0 30px inset;
  }
`;

const Centered = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const ButtonIcon = styled.i`
  ${Centered};
  width: 24px;
  height: 22px;
  color: #ffffff;
`;
export const Spinner = styled(InputSpinner)`
  ${Centered};
  color: #ffffff;
`;
