import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Button, ButtonSizeEnum, ButtonTypeEnum } from '@uc-common/button';
import { MODALS_ENUM, useModal } from '~/components/Modal';
import { useStores } from '~/stores';
import { Loader } from '~/components/Loader';

export const PlanBadge = observer(() => {
  const { accountPlanStore, projectStore, accountStore } = useStores();

  const { subscription } = accountStore.data ?? {};
  const { isLoading } = accountStore;
  const isShowBadge = !projectStore.isShared && accountStore.isFree && accountPlanStore.nextPlan;

  const [showSelectPlanModal] = useModal(
    MODALS_ENUM.SELECT_PLAN,
    {},
    {
      isFullScreenModal: true,
    }
  );

  useEffect(() => {
    accountPlanStore.fetchAvailablePlans();
  }, [accountPlanStore]);

  if (!isShowBadge) {
    return null;
  }

  if (isLoading) {
    return <Loader className="static text-xs" isLoading />;
  }

  return (
    <StyledButton
      themeType={ButtonTypeEnum.DARK}
      size={ButtonSizeEnum.EXTRA_SMALL}
      block
      onClick={showSelectPlanModal}
      data-analytics="btn_plan-badge"
    >
      {subscription.plan_name} plan
    </StyledButton>
  );
});

const StyledButton = styled(Button)`
  padding: 2px 8px;
  height: 24px;

  // expand clickable area
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    min-width: 32px;
    height: 32px;
    transform: translate(-50%, -50%);
  }
`;
