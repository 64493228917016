import React from 'react';
import styled, { css } from 'styled-components';
import { useChangelogUpdate } from '@uc-common/use-changelog-update';
import { ReactComponent as NotificationIcon } from './assets/notification.svg';
import { BaseLink } from '../Link';

const Link = styled(BaseLink)(
  ({ $isHighlighted = false }) => css`
    ${$isHighlighted &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 19px;
        right: -3px;
        width: 5px;
        height: 5px;
        background-color: #ee5567;
        border-radius: 8px;
      }
    `};
  `
);

export const ChangelogLink = ({ className }) => {
  const environment = import.meta.env.VITE_APP_ENV === 'production' ? 'production' : 'staging';
  const { isChangelogVisited, visitChangelog } = useChangelogUpdate(environment);

  return (
    <Link
      href="/docs/changelog/"
      target="_blank"
      $isHighlighted={!isChangelogVisited}
      onClick={visitChangelog}
      className={className}
      data-analytics="btn_top-menu_changelog"
      aria-label="Go to changelog"
      title="Changelog"
    >
      <NotificationIcon />
    </Link>
  );
};
