// https://fkhadra.github.io/react-toastify/introduction/

// @ts-check
import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as CloseIcon } from './assets/close-icon.svg';

/** @enum {number} */
export const NotificationTimeout = {
  BASE: 5000,
};

/**
 * @param {{ closeToast: (event: import('event').EventFor<'button', 'onClick'>) => void }} props
 * @returns {JSX.Element}
 */
const CloseButton = ({ closeToast }) => (
  <button
    className="Toastify__close-button"
    type="button"
    onClick={closeToast}
    aria-label="Close notification"
  >
    <CloseIcon />
  </button>
);

export const Toaster = () => {
  return (
    <StyledToastContainer
      role="status"
      aria-live="polite"
      position="bottom-center"
      theme="light"
      rtl={false}
      transition={Slide}
      draggable={false}
      closeButton={CloseButton}
      autoClose={NotificationTimeout.BASE}
      hideProgressBar
      newestOnTop={false}
      closeOnClick={false}
      pauseOnFocusLoss
      pauseOnHover
      stacked
    />
  );
};

// https://fkhadra.github.io/react-toastify/how-to-style
const StyledToastContainer = styled(ToastContainer)`
  --toastify-toast-min-height: 50px;

  --toastify-color-progress-info: #c1c6ca;
  --toastify-color-progress-success: #c1c6ca;
  --toastify-color-progress-warning: #c1c6ca;
  --toastify-color-progress-error: #c1c6ca;
  --toastify-color-progress-bgo: 0;

  .Toastify__toast {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    width: 312px;
    padding: 8px 8px 8px 18px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1), 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  }

  .Toastify__toast-body {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    padding: 0;

    & > div:not(.Toastify__toast-icon) {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 8px;
      align-items: center;

      & a,
      & button {
        justify-self: end;
      }
    }
  }

  .Toastify__close-button {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: auto;

    & svg {
      width: 10px;
      height: 10px;
    }
  }

  .Toastify__toast-icon {
    width: 17px;
    height: 17px;
    margin-inline-end: 0;
  }

  .Toastify__progress-bar--wrp {
    height: 2px;
  }
`;
