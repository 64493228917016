/* eslint-disable camelcase */
import { flow, observable, makeObservable } from 'mobx';
import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';

export class ProjectS3BackupStore extends BaseStore {
  /** @type {import('@types/dashboard-api-types').s3Backup | null} */
  data = null;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      createBackup: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/s3_backup/`;
  }

  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/', config);
      this.data = res.data;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      // if status === 404, then backup not exist
      if (e?.response?.status !== 404) {
        this.processErrors(e);
      }
    }
  }

  *createBackup({ bucket_name, is_bucket_acl_enabled }) {
    this.isLoading = true;

    try {
      const res = yield this.api.post('/', {
        bucket_name,
        is_bucket_acl_enabled,
      });
      this.data = res.data;
      this.isLoading = false;
      message.success('S3 Bucket was connected for auto backups.');
      return res;
    } catch (e) {
      this.isLoading = false;
      this.processErrors(e);
    }
  }
}
