import React from 'react';
import styled, { css } from 'styled-components';
import { BaseLink } from './BaseLink';

export const StyledLink = styled(BaseLink)(
  ({ $isDisabled = false, $inverse }) => css`
    line-height: 1;
    color: #3771ff;
    font-family: var(--font-sans);
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      color: #000;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      transition: all 0.2s ease-in-out;
    }

    ${$isDisabled &&
    css`
      pointer-events: none;
    `}

    ${$inverse &&
    css`
      color: #000;

      &:hover {
        color: #0e54ff;
      }
    `}
  `
);

export const Link = ({ inverse = false, disabled = false, ...props }) => (
  <StyledLink $inverse={inverse} $isDisabled={disabled} {...props} />
);
