import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AppContentLoader } from '~/components/AppContentLoader';

export const FullscreenLayout = () => (
  <Suspense fallback={<AppContentLoader />}>
    <Outlet />
  </Suspense>
);
