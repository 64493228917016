import styled, { css } from 'styled-components';

const AvatarGradientList = css`
  --gradient-0: radial-gradient(
    85.42% 168.66% at 100% 100%,
    #5700c1 0%,
    #7b28e0 45.69%,
    #2c1f32 59.23%,
    #8d2a1c 71.74%
  );
  --gradient-1: radial-gradient(
    85.42% 168.66% at 100% 100%,
    #8f00c1 12.88%,
    #a82636 38.92%,
    #7b28e0 69.13%,
    #2c1f32 78.51%
  );
  --gradient-2: radial-gradient(
    68.75% 189.25% at 33.33% -10.42%,
    #d4a6f1 0%,
    #a0a0f5 35.27%,
    #735aca 71.21%,
    #35211b 91.01%
  );
  --gradient-3: radial-gradient(
    152.03% 152.03% at 60.42% 64.58%,
    #35211b 12.88%,
    #735aca 63.4%,
    #a0a0f5 71.21%,
    #dd6bf0 100%
  );
  --gradient-4: radial-gradient(
    160.88% 170.63% at 12.5% 83.33%,
    #35211b 0%,
    #735aca 23.29%,
    #a0a0f5 42.57%,
    #dd6bf0 64.96%,
    #ff6ca1 92.57%
  );
  --gradient-5: radial-gradient(106.25% 106.25% at 20.83% -6.25%, #ef6b82 0%, #e1a0f1 100%);
  --gradient-6: radial-gradient(
    91.67% 223.1% at 70.83% -8.33%,
    #ffc0bd 0%,
    #ef6e7a 56.11%,
    #456e77 100%
  );
  --gradient-7: radial-gradient(
    100% 302.05% at 0% 0%,
    #f19a9c 0%,
    #f67d89 20.17%,
    #ae7081 52.46%,
    #456e77 70.17%,
    #16284a 99.86%
  );
  --gradient-8: radial-gradient(93.75% 100% at 50% 0%, #3a6972 16%, #182d4b 100%);
  --gradient-9: radial-gradient(
    109.27% 109.27% at 100% 0%,
    #90cc72 0%,
    #3e857d 45.67%,
    #487e7b 58.17%,
    #a2be9b 100%
  );
  --gradient-10: radial-gradient(
    246.08% 100% at 8.33% 95.83%,
    #90cc72 12.36%,
    #3e857d 31.63%,
    #487e7b 44.65%,
    #a2be9b 66.53%,
    #4a7e7a 73.82%,
    #90cc72 92.57%
  );
  --gradient-11: radial-gradient(72.92% 87.11% at 89.58% 75%, #488284 11.89%, #ba7797 100%);
  --gradient-12: radial-gradient(
    106.55% 193.37% at 83.33% 95.83%,
    #948ed7 0%,
    #362614 21.39%,
    #33220d 30.58%,
    #514c5b 38.65%,
    #989dcb 51.7%,
    #ca838f 71.74%,
    #ca838f 100%
  );
  --gradient-13: radial-gradient(
    87.5% 190.41% at 93.75% 27.08%,
    #dcd7d7 0%,
    #c2c8c9 23.81%,
    #488284 51.94%,
    #ba7797 100%
  );
  --gradient-14: radial-gradient(131.25% 285.61% at 50% 0%, #e6e2e2 0%, #397779 100%);
  --gradient-15: radial-gradient(
    149.98% 246.4% at -20.83% 27.08%,
    #a87fb4 0%,
    #bcd1c7 37.97%,
    #ead3af 45.94%,
    #a69cab 71.73%,
    #5d8088 100%
  );
  --gradient-16: radial-gradient(
    85.7% 214.26% at 83.33% 83.33%,
    #a87fb4 0%,
    #ead3af 45.94%,
    #bcd1c7 54.02%
  );
  --gradient-17: radial-gradient(100% 100% at 100% 0%, #f4e0bf 16%, #ebb1ce 100%);
  --gradient-18: radial-gradient(
    87.5% 233.01% at 70.83% -8.33%,
    #fff5bd 22.26%,
    #fbe083 52.46%,
    #fbb1f8 100%
  );
  --gradient-19: radial-gradient(
    145% 95% at 50% 0%,
    rgb(255, 245, 189) 22.26%,
    rgb(254, 233, 157) 52.46%,
    rgb(237, 239, 134) 68.61%,
    rgb(195, 219, 208) 100%
  );
`;

export const AvatarGradientContainer = styled.span(
  ({ $index = 1 }) => css`
    ${AvatarGradientList};
    width: var(--gradient-size, 26px);
    height: var(--gradient-size, 26px);
    border-radius: 5px;
    background: var(--gradient-${$index});
  `
);
