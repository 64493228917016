import { flow, makeObservable } from 'mobx';
import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';

export class UserPasswordStore extends BaseStore {
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      requestChangePassword: flow,
      changePassword: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/password/change/';
  }

  *requestChangePassword(data) {
    this.isLoadingMap.requestChangePassword = true;

    try {
      const res = yield this.api.post('/', data);
      this.isLoadingMap.requestChangePassword = false;
      message.info('The email was sent.');
      return res;
    } catch (e) {
      this.isLoadingMap.requestChangePassword = false;
      this.processErrors(e);
    }
  }

  *changePassword(data) {
    this.isLoadingMap.changePassword = true;

    try {
      const res = yield this.api.put('/', data);
      this.isLoadingMap.changePassword = false;
      message.success('Password has been changed.');
      return res;
    } catch (e) {
      this.isLoadingMap.changePassword = false;
      this.processErrors(e);
    }
  }
}
