// @ts-check
import { flow, makeObservable, observable } from 'mobx';

import { BaseStore } from '../base-store';

/** @typedef {import('dashboard-api-types').moderationCategory} Category */

/** @augments {BaseStore<Category[]>} */
export class ModerationCategories extends BaseStore {
  /** @type {Category[] | null} */
  data = null;

  /** @param {import('../app-store.js').AppStore} appStore */
  constructor(appStore) {
    super(appStore);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/moderation/`;
  }

  /** @param {{ config?: import('../base-store').AxiosRequestConfig }} [options={}] */
  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      /** @type {{ data: { categories: Category[] } }} */
      const res = yield /** @type {Promise<{ categories: Category[] }>} */ (
        this.api.get(`/categories/`, config)
      );
      this.data = res.data.categories;
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }
}
