import { lazy } from 'react';

export const MODALS_ENUM = Object.freeze({
  MIME_TYPES: 'MIME_TYPES',
  UPGRADE_PLAN: 'UPGRADE_PLAN',
  SWITCH_PAYMENT_PERIOD: 'SWITCH_PAYMENT_PERIOD',
  DEMO: 'DEMO',
  REQUEST_CHANGE_PASSWORD: 'REQUEST_CHANGE_PASSWORD',
  ENABLE_MFA: 'ENABLE_MFA',
  DISABLE_MFA: 'DISABLE_MFA',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_EMAIL_AUTH: 'CHANGE_EMAIL_AUTH',
  RESEND_EMAIL_CONFIRMATION: 'RESEND_EMAIL_CONFIRMATION',
  CONFIRM: 'CONFIRM',
  CREDIT_CARD: 'CREDIT_CARD',
  ADD_WEBHOOK: 'ADD_WEBHOOK',
  EDIT_WEBHOOK: 'EDIT_WEBHOOK',
  ADD_OAUTH_APP: 'ADD_OAUTH_APP',
  EDIT_OAUTH_APP: 'EDIT_OAUTH_APP',
  ADD_S3_TARGET: 'ADD_S3_TARGET',
  ADD_S3_FOREIGN_BUCKET: 'ADD_S3_FOREIGN_BUCKET',
  EDIT_S3_TARGET: 'EDIT_S3_TARGET',
  ADD_S3_BACKUP: 'ADD_S3_BACKUP',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  STORE_FILE: 'STORE_FILE',
  DELETE_FILE: 'DELETE_FILE',
  SHOW_FILE_INFO: 'SHOW_FILE_INFO',
  PLAYGROUND: 'PLAYGROUND',
  FILES_PAGE_SURVEY: 'FILES_PAGE_SURVEY',
  SELECT_PLAN: 'SELECT_PLAN',
  DOWNGRADE_SURVEY: 'DOWNGRADE_SURVEY',
  DELETE_ACCOUNT_SURVEY: 'DELETE_ACCOUNT_SURVEY',
  PERSONALIZED_OFFER: 'PERSONALIZED_OFFER',
  MISSING_INTEGRATION: 'MISSING_INTEGRATION',
  START_TRIAL: 'START_TRIAL',
  MESSAGE: 'MESSAGE',
  MANAGE_MODERATION_CATEGORIES: 'MANAGE_MODERATION_CATEGORIES',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
});

export const MODALS_COMPONENTS = {
  [MODALS_ENUM.MIME_TYPES]: lazy(() => import('~/modals/MimeTypes')),
  [MODALS_ENUM.UPGRADE_PLAN]: lazy(() => import('~/modals/PlanUpgrade')),
  [MODALS_ENUM.SWITCH_PAYMENT_PERIOD]: lazy(() =>
    import('~/modals/PaymentPeriod/SwitchPaymentPeriodModal')
  ),
  [MODALS_ENUM.DEMO]: lazy(() => import('~/modals/Demo/Demo')),
  [MODALS_ENUM.REQUEST_CHANGE_PASSWORD]: lazy(() => import('~/modals/RequestChangePassword')),
  [MODALS_ENUM.ENABLE_MFA]: lazy(() => import('~/modals/Mfa/EnableMfaModal')),
  [MODALS_ENUM.DISABLE_MFA]: lazy(() => import('~/modals/Mfa/DisableMfaModal')),
  [MODALS_ENUM.CHANGE_EMAIL]: lazy(() => import('~/modals/ChangeEmail')),
  [MODALS_ENUM.CHANGE_EMAIL_AUTH]: lazy(() => import('~/modals/ChangeEmailAuth')),
  [MODALS_ENUM.RESEND_EMAIL_CONFIRMATION]: lazy(() => import('~/modals/ResendEmailConfirmation')),
  [MODALS_ENUM.CONFIRM]: lazy(() => import('~/modals/Confirm')),
  [MODALS_ENUM.CREDIT_CARD]: lazy(() => import('~/modals/CreditCard')),
  [MODALS_ENUM.ADD_WEBHOOK]: lazy(() => import('~/modals/Webhooks/AddWebhookModal')),
  [MODALS_ENUM.EDIT_WEBHOOK]: lazy(() => import('~/modals/Webhooks/EditWebhookModal')),
  [MODALS_ENUM.ADD_OAUTH_APP]: lazy(() => import('~/modals/OAuthApp/AddOAuthAppModal')),
  [MODALS_ENUM.EDIT_OAUTH_APP]: lazy(() => import('~/modals/OAuthApp/EditOAuthAppModal')),
  [MODALS_ENUM.ADD_S3_TARGET]: lazy(() => import('~/modals/S3Target/AddS3TargetModal')),
  [MODALS_ENUM.ADD_S3_FOREIGN_BUCKET]: lazy(() =>
    import('~/modals/S3ForeignBucket/AddS3ForeignBucketModal')
  ),
  [MODALS_ENUM.PERSONALIZED_OFFER]: lazy(() => import('~/modals/PersonalizedOffer')),
  [MODALS_ENUM.EDIT_S3_TARGET]: lazy(() => import('~/modals/S3Target/EditS3TargetModal')),
  [MODALS_ENUM.ADD_S3_BACKUP]: lazy(() => import('~/modals/S3Backup/AddS3BackupModal')),
  [MODALS_ENUM.CHANGE_PASSWORD]: lazy(() => import('~/modals/ChangePassword')),
  [MODALS_ENUM.STORE_FILE]: lazy(() => import('~/modals/StoreFile')),
  [MODALS_ENUM.DELETE_FILE]: lazy(() => import('~/modals/DeleteFile')),
  [MODALS_ENUM.SHOW_FILE_INFO]: lazy(() => import('~/modals/ShowFileInfo')),
  [MODALS_ENUM.PLAYGROUND]: lazy(() => import('~/modals/Playground')),
  [MODALS_ENUM.SELECT_PLAN]: lazy(() => import('~/modals/SelectPlan')),
  [MODALS_ENUM.DOWNGRADE_SURVEY]: lazy(() =>
    import('~/modals/Sequence/DowngradeSurvey/DowngradeSurvey')
  ),
  [MODALS_ENUM.DELETE_ACCOUNT_SURVEY]: lazy(() =>
    import('~/modals/Sequence/DeleteAccountSurvey/DeleteAccountSurvey')
  ),
  [MODALS_ENUM.MISSING_INTEGRATION]: lazy(() => import('~/modals/MissingIntegration')),
  [MODALS_ENUM.START_TRIAL]: lazy(() => import('~/modals/StartTrial')),
  [MODALS_ENUM.MESSAGE]: lazy(() => import('~/modals/Message')),
  [MODALS_ENUM.MANAGE_MODERATION_CATEGORIES]: lazy(() =>
    import('~/modals/ManageModerationCategories')
  ),
  [MODALS_ENUM.ANNOUNCEMENT]: lazy(() => import('~/modals/Announcement')),
};
