import { observable, makeObservable, flow } from 'mobx';
import { BaseStore } from '../base-store';

const DEFAULT_DATA = {
  backup_codes: [],
  provisioning_uri: null,
};

export class MfaStore extends BaseStore {
  /** @type {{ backup_codes: string[]; provisioning_uri: string }} */
  data = DEFAULT_DATA;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      initiate: flow,
      switch: flow,
      verify: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/auth/mfa/';
  }

  /**
   * Initiate the process of MFA's enabling. Retrieve provisioning URI and backup codes from the API
   * and store them in `this.data`.
   */
  *initiate() {
    this.isLoadingMap.initiate = true;

    try {
      const res = yield this.api.post('/initiate/');
      this.data = res.data;
      return res;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap.initiate = false;
    }
  }

  /**
   * Switch (enable or disable) MFA.
   *
   * @param {boolean} enable MFA will be enabled if `true` is passed, otherwise it will be disabled.
   * @param {{ otp?: string; backup_code?: string }} payload `otp` is required for enabling, `otp`
   *   or `backup_code` is required for disabling.
   */
  *switch(enable, payload = {}) {
    this.isLoadingMap.switch = true;

    let url = `/${enable ? 'enable' : 'disable'}/`;
    if (!enable && payload.backup_code) {
      url = `/backup${url}`;
    }

    try {
      return yield this.api.post(url, payload);
    } finally {
      this.isLoadingMap.switch = false;
    }
  }

  /**
   * Verify MFA code.
   *
   * @param {{ otp?: string; backup_code?: string }} payload
   */
  *verify(payload) {
    this.isLoadingMap.verify = true;

    try {
      return yield this.api.post(`/${payload.otp ? 'verify' : 'backup'}/`, payload);
    } finally {
      this.isLoadingMap.verify = false;
    }
  }

  reset() {
    super.reset();
    this.data = DEFAULT_DATA;
  }
}
