import React from 'react';
import { Navigate } from 'react-router-dom';
import { InputSpinner } from '@uc-common/spinner';

export const ProtectedRoute = ({ enabled, redirectTo, children }) => {
  if (enabled) {
    return children;
  }
  if (redirectTo) {
    return <Navigate to={redirectTo} />;
  }
  return <InputSpinner />;
};
