import { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const CHANGELOG_VISITED_DATES_KEY = 'changelog-visited-dates';
const CHANGELOG_LAST_REQUEST_DATE_KEY = 'changelog-last-request-date';
const RSS_FEED_URL_PROD = 'https://uploadcare.com/docs/changelog.rss';
const RSS_FEED_URL_STAGING = 'https://staging0.uploadcare.com/docs/changelog.rss';

export const useChangelogUpdate = (environment) => {
  const [isChangelogVisited, setIsChangelogVisited] = useState(true);
  const [dates, setDates] = useLocalStorageState(CHANGELOG_VISITED_DATES_KEY, {
    ssr: true,
    defaultValue: [],
  });
  const [latestUpdate, setLatestUpdate] = useState(null);

  const visitChangelog = () => {
    if (latestUpdate && !dates.includes(latestUpdate)) {
      setDates([...dates, latestUpdate]);
      setIsChangelogVisited(true);
    }
  };

  const fetchRSSFeed = async () => {
    try {
      const today = new Date().toISOString().split('T')[0];
      const lastRequestDate = localStorage.getItem(CHANGELOG_LAST_REQUEST_DATE_KEY);

      if (dates.length === 0) {
        setIsChangelogVisited(false);
      }
      // Check if a request was already made today
      if (lastRequestDate === today) {
        return; // Skip fetching if we've already made a request today
      }

      const response = await fetch(
        environment === 'production' ? RSS_FEED_URL_PROD : RSS_FEED_URL_STAGING
      );
      const text = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(text, 'application/xml');

      const items = xmlDoc.getElementsByTagName('item');
      if (items.length > 0) {
        const mostRecentItem = items[0];
        const pubDate = mostRecentItem.getElementsByTagName('pubDate')[0].textContent;
        const mostRecentDate = new Date(pubDate).toISOString().split('T')[0];

        setLatestUpdate(mostRecentDate);
        setIsChangelogVisited(dates && dates.includes(mostRecentDate));
      }

      // Update the last request date in localStorage
      localStorage.setItem(CHANGELOG_LAST_REQUEST_DATE_KEY, today);
    } catch (error) {
      window.Rollbar?.error?.('Failed to fetch RSS feed:', error);
    }
  };

  useEffect(() => {
    fetchRSSFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isChangelogVisited,
    visitChangelog,
  };
};
