import { flow, observable, makeObservable } from 'mobx';
import { BaseStore } from '../base-store';

export class ProjectProxyDomainsStore extends BaseStore {
  // TODO: add type
  /** @type {unknown[]} */
  data = [];

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/proxy/domains/`;
  }

  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/', config);
      this.data = res.data.results;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }

  reset() {
    super.reset();
    this.data = [];
  }
}
