import { flow, observable, makeObservable, reaction, computed } from 'mobx';
import { RESPONSE_CODES } from '~/utils/http';
import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';
import { dataLikeArrayMixin } from '../mixins';

/** @typedef {import('dashboard-api-types').project} Project */
export class ProjectsListStore extends dataLikeArrayMixin(BaseStore) {
  /** @type {Project[]} */
  data = [];

  limit = 100;

  /**
   * Project creation flow state
   *
   * @type {{
   *   isProjectCreated: boolean;
   *   errors: { [key: string]: any } | null;
   * }}
   */
  projectCreation = {
    isProjectCreated: false,
    errors: null,
  };

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      projectCreation: observable,
      checkProjectName: flow,
      createProject: flow,
      fetchData: flow,
      deleteProject: flow,
      projects: computed,
      fallbackProject: computed,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/projects/';
  }

  /** @returns {{ ownedProjects: Project[]; sharedProjects: Project[] }} */
  get projects() {
    return this.data.reduce(
      (accumulator, project) =>
        accumulator[project.is_shared_project ? 'sharedProjects' : 'ownedProjects'].push(project) &&
        accumulator,
      { ownedProjects: [], sharedProjects: [] }
    );
  }

  get fallbackProject() {
    return this.projects.ownedProjects[0] ?? {};
  }

  onInit() {
    // subscribe to project store, for sync project lists
    reaction(
      () => this.appStore.stores.projectStore.project,
      (project) => {
        const isProjectUpdated = project !== null;

        if (!isProjectUpdated) return;

        this.updateItemInDataByField(project, 'pub_key');
      }
    );
  }

  *fetchData({ config, statusesOfSilentErrors = [] } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get(`/?limit=${this.limit}`, config);
      this.data = res.data.results;
      this.isLoading = this.isInitialLoading = false;

      return res;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e, { statusesOfSilentErrors });
    }
  }

  /**
   * Check Project Name during creating one.
   *
   * @param {string} name
   * @returns {Promise<boolean>}
   */
  *checkProjectName(name) {
    this.isLoadingMap.checkProjectName = true;

    try {
      const { status } = yield this.api.post('/names/', { name });

      return status === RESPONSE_CODES.NO_CONTENT;
    } finally {
      this.isLoadingMap.checkProjectName = false;
    }
  }

  *createProject(data) {
    this.isLoadingMap.createProject = true;

    try {
      const res = yield this.api.post('/', data);
      this.data = [res.data, ...this.data];
      this.projectCreation.isProjectCreated = true;

      return res;
    } catch (err) {
      const error = /** @type {import('~/types/http-api-error').HttpApiError} */ (err);

      this.projectCreation.errors = error.response.data.errors;

      throw err;
    } finally {
      this.isLoadingMap.createProject = false;
    }
  }

  *deleteProject(pubKey) {
    if (!pubKey) throw new Error('Project pubKey is required.');

    this.isLoadingMap.deleteProject = true;

    try {
      const res = yield this.api.delete(`/${pubKey}/`);
      this.removeItemFromDataByField(pubKey, 'pub_key');
      this.appStore.stores.projectStore.reset();
      message.info('Project was deleted.');

      return res;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap.deleteProject = false;
    }
  }

  resetProjectCreationState() {
    this.projectCreation = {
      isProjectCreated: false,
      errors: null,
    };
  }
}
