import styled, { css } from 'styled-components';
import { BaseLink } from '~/components/Link/index';

export const HeaderContainer = styled.header(
  ({ theme: { tablet } }) => css`
    max-width: 1280px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 56px;
    margin: 0 auto;
    justify-content: space-between;

    ${tablet()} {
      padding: 0 32px;
      height: 88px;
    }
  `
);

export const HeaderRight = styled.div(
  ({ theme: { tablet } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    ${tablet()} {
      flex-direction: row;
      align-items: center;
    }
  `
);

export const HeaderText = styled.p(
  ({ theme: { tablet } }) => css`
    font-weight: 400;
    font-size: 14px;
    line-height: 1.25;
    color: #7b7b7b;

    ${tablet()} {
      font-size: 16px;

      &:after {
        content: '|';
        margin: 0 0.5em;
      }
    }
  `
);

export const HeaderLink = styled(BaseLink)(
  ({ theme: { tablet } }) => css`
    font-size: 14px;
    font-weight: 500;
    color: #000;

    &:hover,
    &:focus {
      color: #434343;
    }

    ${tablet()} {
      font-size: 16px;
    }
  `
);
