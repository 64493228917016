import { action, computed, observable, makeObservable, flow } from 'mobx';

import { parseFromLocalStorage } from '~/utils/browser';
import { BaseStore } from '../base-store';

export const getPendingInvitationKey = (email) => window.btoa(`pending-invitation-${email}`);

export class ProjectTeamStore extends BaseStore {
  SOURCES_ENUM = Object.freeze({
    SETTINGS: 'settings',
    QUALIFICATION: 'qualification',
  });

  initialData = {
    me: null,
    team: [],
  };

  /** @type {import('dashboard-api-types').projectTeam} */
  data = this.initialData;

  pendingInvitation = null;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      pendingInvitation: observable,
      pendingInvitationKey: computed,
      checkPendingInvitation: action,
      inviteUser: flow,
      revokeAccess: flow,
      acceptInvitation: flow,
      revokeInvitation: flow,
      removePendingInvitation: action,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${
      this.appStore.stores.projectStore.pubKey ??
      this.appStore.stores.projectsListStore.fallbackProject.pub_key
    }/team/`;
  }

  get pendingInvitationKey() {
    return getPendingInvitationKey(this.appStore.stores.accountStore.data?.email);
  }

  checkPendingInvitation() {
    this.pendingInvitation = parseFromLocalStorage(this.pendingInvitationKey);
  }

  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/', config);
      this.data = res.data;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }

  *inviteUser(userEmail, source = this.SOURCE_SETTINGS) {
    this.isLoadingMap.inviteUser = true;

    try {
      yield this.api.put('/invitations/', { email: userEmail, source });
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap.inviteUser = false;
    }
  }

  *revokeAccess(userEmail) {
    this.isLoadingMap.revokeAccess = true;

    try {
      yield this.api.delete(`/${window.btoa(userEmail)}/`);
      this.data.team = this.data.team.filter((teamMember) => teamMember.email !== userEmail);
    } catch (e) {
      this.processErrors(e, { statusesOfSilentErrors: [404] });
    } finally {
      this.isLoadingMap.revokeAccess = false;
    }
  }

  *acceptInvitation(token) {
    this.isLoadingMap.acceptInvitation = true;

    try {
      yield this.api.post(`/invitations/${token}/`);
    } finally {
      this.isLoadingMap.acceptInvitation = false;
    }
  }

  *revokeInvitation(token, deleteFromTeam = true) {
    this.isLoadingMap.revokeInvitation = true;

    try {
      yield this.api.delete(`/invitations/${token}/`);

      if (deleteFromTeam) {
        this.data.team = this.data.team.filter(
          (teamMember) => teamMember.invitation?.token !== token
        );
      }
    } catch (e) {
      this.processErrors(e, { statusesOfSilentErrors: [404] });
    } finally {
      this.isLoadingMap.revokeInvitation = false;
    }
  }

  setPendingInvitationToStorage = ({ pubKey, token, invitedEmail }) => {
    const key = getPendingInvitationKey(invitedEmail);
    window.localStorage.setItem(key, JSON.stringify({ pubKey, token, invitedEmail }));
  };

  removePendingInvitation = () => {
    window.localStorage.removeItem(this.pendingInvitationKey);
    this.pendingInvitation = null;
  };

  reset() {
    super.reset();
    this.pendingInvitation = null;
  }
}
