import { action, computed, observable, reaction, makeObservable, flow } from 'mobx';

import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';

/** @typedef {import('dashboard-api-types').features} Features */

/** @enum {string} */
export const ProjectSettingsEnum = {
  AUTOSTORE: 'autostore_enabled',
  SECURE_UPLOADS: 'is_secure_upload_enabled',
  SEARCH_INDEX: 'is_search_index_allowed',
  MIME_FILTERING_ENABLED: 'is_mime_filtering_enabled',
  FILTERED_MIME_TYPES: 'filtered_mime_types',
  BLOCK_INFECTED_UPLOAD: 'block_infected_upload',
  FEATURE_FORMAT_AUTO: 'feature_format_auto',
  FEATURE_QUALITY_SMART: 'feature_quality_smart',
  FEATURE_VALIDATE_SVG: 'feature_validate_svg',
  FEATURE_ADAPTIVE_VIDEO: 'feature_adaptive_video',
  AUTO_MODERATION: 'auto_moderation',
};

export class ProjectStore extends BaseStore {
  hasSecretKey = null;

  /** @type {import('dashboard-api-types').project?} */
  project = null;

  pubKey = null;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      hasSecretKey: observable,
      project: observable,
      pubKey: observable,
      features: computed,
      lastSelectedPubKeyName: computed,
      lastSelectedPubKey: computed,
      imagesOnlyAllowed: computed,
      fetchSecretKey: flow,
      updateProjectProperty: flow,
      toggleProjectProperty: flow,
      updateProject: flow,
      setProject: action,
      resetProject: action,
      setPubKey: action,
      fetchData: flow,
      isShared: computed,
    });
  }

  onInit() {
    reaction(
      () => this.project,
      (project) => {
        if (project) {
          this.pubKey = project.pub_key;

          if (this.lastSelectedPubKeyName) {
            window.localStorage.setItem(this.lastSelectedPubKeyName, project.pub_key);
          }
        } else {
          this.pubKey = null;
          window.localStorage.removeItem(this.lastSelectedPubKeyName);
        }
      }
    );

    reaction(
      () => this.pubKey,
      (pubKey, prev) => {
        if (prev === null) return; // skip initial state
        if (pubKey !== prev) {
          // new pubKey, then reset project stores
          this.reset();
        }
      }
    );
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.pubKey}/`;
  }

  /**
   * Features available on the current project. If user is project's owner, available features are
   * obtained from account store because of API optimization.
   *
   * @returns {Features}
   */
  get features() {
    // Use project features for shared project.
    if (this.project?.is_shared_project && this.project?.features) {
      return this.project.features;
    }

    return this.appStore.stores.accountStore.data?.features;
  }

  /**
   * @param {string} featureName
   * @returns {boolean}
   */
  isFeatureEnabled(featureName) {
    return Boolean((this.features?.[featureName] ?? {}).is_enabled);
  }

  get lastSelectedPubKeyName() {
    const accountId = this.appStore.stores.accountStore.data?.id;
    return accountId ? `last-project-${accountId}` : null;
  }

  get lastSelectedPubKey() {
    return window.localStorage.getItem(this.lastSelectedPubKeyName);
  }

  get imagesOnlyAllowed() {
    if (this.project?.is_shared_project) {
      return !this.project.is_allowed_non_image_upload;
    }
    return !this.appStore.stores.accountStore.data.subscription.has_card;
  }

  get isShared() {
    return Boolean(this.project?.is_shared_project);
  }

  /** @param {{ statusesOfSilentErrors?: number[]; config: import('axios').AxiosRequest }} options */
  *fetchData({ statusesOfSilentErrors, config }) {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/', config);
      this.project = res.data;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e, { statusesOfSilentErrors });
    }
  }

  *fetchSecretKey({ config } = {}) {
    this.isLoadingMap.fetchSecretKey = true;

    try {
      const res = yield this.api.get('/secrets/?limit=1', config);
      this.hasSecretKey = !!res.data.results.length;
      this.isLoadingMap.fetchSecretKey = false;
    } catch (e) {
      this.isLoadingMap.fetchSecretKey = false;
      this.processErrors(e);
    }
  }

  *updateProjectProperty(propertyName, propertyValue) {
    this.isLoadingMap[propertyName] = true;

    try {
      const value = propertyValue ?? !this.project[propertyName];
      const res = yield this.api.put('/', {
        [propertyName]: value,
      });
      this.project = res.data;
      this.isLoadingMap[propertyName] = false;
      const messageText = this.getMessageByProperty(propertyName, value);
      messageText && message.info(messageText);
      return res;
    } finally {
      this.isLoadingMap[propertyName] = false;
    }
  }

  *toggleProjectProperty(propertyName) {
    return yield this.updateProjectProperty(propertyName);
  }

  *updateProject(data) {
    this.isLoadingMap.updateProject = true;

    try {
      const res = yield this.api.put('/', data);
      this.project = res.data;
      this.isLoadingMap.updateProject = false;
      message.info('Project was updated.');
      return res;
    } catch (e) {
      this.isLoadingMap.updateProject = false;
      this.processErrors(e);
    }
  }

  setProject(project) {
    this.project = project;
  }

  resetProject() {
    this.project = null;
  }

  setPubKey(pubKey) {
    this.pubKey = pubKey;
  }

  getMessageByProperty(property, value) {
    // eslint-disable-next-line default-case
    switch (property) {
      case ProjectSettingsEnum.AUTOSTORE: {
        return `Automatic file storing was ${value ? 'enabled' : 'disabled'}.`;
      }
      case ProjectSettingsEnum.SECURE_UPLOADS: {
        return `Signed uploads feature ${value ? 'enabled' : 'disabled'}.`;
      }
      case ProjectSettingsEnum.SEARCH_INDEX: {
        return `Search engine indexing feature ${value ? 'enabled' : 'disabled'}.`;
      }
      case ProjectSettingsEnum.BLOCK_INFECTED_UPLOAD: {
        return `Malware protection ${value ? 'enabled' : 'disabled'}.`;
      }
      case ProjectSettingsEnum.FEATURE_FORMAT_AUTO: {
        return `Auto WEBP / AVIF feature ${value ? 'enabled' : 'disabled'}.`;
      }
      case ProjectSettingsEnum.FEATURE_QUALITY_SMART: {
        return `Adaptive quality feature ${value ? 'enabled' : 'disabled'}.`;
      }
      case ProjectSettingsEnum.FILTERED_MIME_TYPES: {
        return 'Server-side MIME type filtering was updated.';
      }
      case ProjectSettingsEnum.MIME_FILTERING_ENABLED: {
        return `Server-side MIME type filtering feature ${value ? 'enabled' : 'disabled'}.`;
      }
      case ProjectSettingsEnum.FEATURE_VALIDATE_SVG: {
        return `SVG validation feature ${value ? 'enabled' : 'disabled'}.`;
      }
      case ProjectSettingsEnum.FEATURE_ADAPTIVE_VIDEO: {
        return `Adaptive bitrate streaming ${value ? 'enabled' : 'disabled'}.`;
      }
    }
  }

  reset() {
    super.reset();

    this.hasSecretKey = null;
    window.localStorage.removeItem(this.lastSelectedPubKeyName);

    this.appStore.stores.projectAnalyticsStore.reset();
    this.appStore.stores.projectTeamStore.reset();
    this.appStore.stores.projectFilesStore.reset();
    this.appStore.stores.projectApiKeysStore.reset();
    this.appStore.stores.projectS3BackupStore.reset();
    this.appStore.stores.projectWebHooksStore.reset();
    this.appStore.stores.projectWhitelistStore.reset();
    this.appStore.stores.projectS3ForeignBucketStore.reset();
    this.appStore.stores.projectS3TargetsStore.reset();
    this.appStore.stores.projectOAuthAppsStore.reset();
    this.appStore.stores.projectUploadsAuthStore.reset();
    this.appStore.stores.projectApiLogsSummaryStore.reset();
    this.appStore.stores.projectApiLogsStore.reset();
    this.appStore.stores.projectProxyDomainsStore.reset();
  }

  /**
   * Priority:
   *
   * 1. Previously selected project (saved in local storage)
   * 2. Most recently created user project
   * 3. Most recently shared project (if user has no own projects)
   *
   * @param {import('dashboard-api-types').project[]} projects
   * @returns {import('dashboard-api-types').project?}
   */
  suggestCurrentProject(projects) {
    const lastSelectedProject = projects.find(
      (project) => project.pub_key === this.lastSelectedPubKey
    );

    if (lastSelectedProject) {
      // Use last selected project from local storage, if exist.
      return lastSelectedProject;
    }
    // Use most recent user's or shared project.
    const userProjects = projects.filter((project) => !project.is_shared_project);
    return userProjects.length ? userProjects[0] : projects[0];
  }
}
