export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_DAY = 24 * ONE_HOUR;

export const addDays = (date, days) => {
  const newDate = new Date(Number(date));
  newDate.setDate(date.getDate() + days);
  return newDate;
};

export const dateMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * @param {Date} date
 * @returns {boolean}
 */
export const isTodayUTC = (date) => {
  const today = new Date();

  return (
    date.getUTCFullYear() === today.getUTCFullYear() &&
    date.getUTCMonth() === today.getUTCMonth() &&
    date.getUTCDate() === today.getUTCDate()
  );
};

// @ts-check

/**
 * @param {string} date
 * @param {{
 *   monthsAgo?: number;
 *   daysAgo?: number;
 *   date?: string; // example: '2024-02-02';
 * }} targetPastTime
 * @returns {boolean}
 */
export const checkDateBefore = (date, targetPastTime) => {
  if (!date) {
    return false;
  }

  if (Object.keys(targetPastTime).length > 1) {
    return false;
  }

  const dateInMilliseconds = Date.parse(date);
  const now = new Date();
  let targetPastTimeInMilliseconds = 0;

  if (targetPastTime.date) {
    targetPastTimeInMilliseconds = Date.parse(targetPastTime.date);
  }

  if (targetPastTime.monthsAgo) {
    targetPastTimeInMilliseconds = now.setMonth(now.getMonth() - targetPastTime.monthsAgo);
  }

  if (targetPastTime.daysAgo) {
    targetPastTimeInMilliseconds = Number(now) - ONE_DAY * targetPastTime.daysAgo;
  }

  return dateInMilliseconds < targetPastTimeInMilliseconds;
};
