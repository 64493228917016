// @ts-check
import React from 'react';
import { InputSpinner as Spinner } from '../spinner';
import { StyledButton, ButtonContent, ButtonSpinnerContainer } from './styles/Button.styles';
import { ButtonTypeEnum } from './ButtonTypeEnum';
import { ButtonSizeEnum } from './ButtonSizeEnum';
import { themes } from './themes';

/**
 * @param {boolean} transparent
 * @param {boolean} ghost
 * @param {{ [key: string]: { [key: string]: string } }} colors
 * @returns {{ [key: string]: string }}
 */
const getButtonColors = (transparent, ghost, colors) => {
  if (transparent && colors.transparent) {
    return colors.transparent;
  }
  if (ghost) {
    return colors.ghost;
  }

  return colors.default;
};

/** @typedef {React.ButtonHTMLAttributes<HTMLButtonElement>} DefaultButtonProps */
/** @typedef {React.RefAttributes<HTMLButtonElement>} ButtonRefAttributes */

/**
 * @type {React.FC<
 *   import('./Button.types').ButtonProps & DefaultButtonProps & ButtonRefAttributes
 * >}
 */
export const Button = React.forwardRef(
  (
    {
      themeType = ButtonTypeEnum.DEFAULT,
      customTheme,
      ghost = false,
      transparent = false,
      size = ButtonSizeEnum.NORMAL,
      block = false,
      long = false,
      alignmentText = 'center',
      isLoading = false,
      as,
      to,
      href,
      icon: Icon,
      children,
      className,
      ...props
    },
    ref
  ) => {
    const currentTheme = customTheme || {
      colors: getButtonColors(transparent, ghost, themes[themeType].colors),
    };

    return (
      <StyledButton
        ref={ref}
        $currentTheme={currentTheme}
        $size={size}
        $block={block}
        $long={long}
        $alignmentText={alignmentText}
        $isIcon={!!Icon}
        $isLoading={isLoading}
        as={as}
        to={to}
        href={href}
        className={className}
        {...props}
      >
        {!!Icon && <Icon />}

        {!!children && (
          <ButtonContent $alignmentText={alignmentText} $isIcon={!!Icon}>
            {children}
          </ButtonContent>
        )}

        {isLoading && (
          <ButtonSpinnerContainer>
            <Spinner />
          </ButtonSpinnerContainer>
        )}
      </StyledButton>
    );
  }
);
