import styled from 'styled-components';
import { Button } from '@uc-common/button';

export const UpgradeButtonContainer = styled(Button)`
  gap: 10px;

  & span {
    justify-content: start;
  }
`;
