import styled, { css } from 'styled-components';
import { BaseLink } from '../../../../Link';

import { ReactComponent as DocsIcon } from '../assets/content-book-page.svg';
import { ReactComponent as InfoIcon } from '../assets/info.svg';
import { ReactComponent as ArrowBlankIcon } from '../assets/arrow-blank.svg';

export const ModalHintsContainer = styled.div`
  width: 135px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  position: absolute;
  top: 0;
  left: calc(100% + 6px);

  @media (min-width: 1125px) {
    width: 195px;

    left: calc(100% + 20px);
  }
`;

const ModalHintIconStyles = css`
  margin-bottom: 9px;
  color: #596570;
  opacity: 0.4;
  transition: color 0.2s ease;

  &:active {
    color: #444d55;
  }
`;

export const ModalHintDocsIcon = styled(DocsIcon)`
  ${ModalHintIconStyles};
`;

export const ModalHintInfoIcon = styled(InfoIcon)`
  ${ModalHintIconStyles};
`;

export const ModalHintArrowIcon = styled(ArrowBlankIcon)`
  ${ModalHintIconStyles};
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0;
  transition: opacity 0.2s ease, color 0.2s ease;
`;

export const ModalHintContainer = styled(BaseLink)`
  padding: 15px;
  background-color: #f8f9fb;
  border-radius: 12px;

  color: #596570;

  position: relative;
  transition: background-color 0.2s ease;

  &:visited {
    outline: none;
  }

  &:hover,
  &:active {
    color: #444d55;

    ${ModalHintArrowIcon} {
      opacity: 0.4;
    }
  }

  &:hover {
    background-color: #f2f4f8;

    ${ModalHintInfoIcon}, ${ModalHintDocsIcon} {
      color: #6f767e;
    }
  }

  &:active {
    background-color: #eceef4;

    ${ModalHintInfoIcon}, ${ModalHintDocsIcon} {
      color: #444d55;
    }
  }
`;

export const ModalHintText = styled.p`
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  transition: color 0.2s ease;
`;
