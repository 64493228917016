// @ts-check
import React from 'react';
import Rollbar from 'rollbar';
import { Provider, ErrorBoundary } from '@rollbar/react';
import { Code } from '@uc-common/code';

/** @type {Rollbar.Configuration} */
const config = {
  // Rollbar/react doesn't allow to create Provider with instance with empty access token,
  // but we must create provider to be able to use Rollbar-react hooks in the components.
  // TODO: stop to use rollbar/react, init Rollbar instance in the base store and use it from stores
  // instead of hooks.
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN || 'XXX',
  environment: import.meta.env.VITE_APP_ENV || 'development',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.VITE_APP_ENV || 'development',
    client: {
      javascript: {
        branch: import.meta.env.VITE_APP_BRANCH,
        code_version: import.meta.env.VITE_APP_VERSION,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

const rollbar = new Rollbar(config);

if (window && rollbar) {
  window.Rollbar = rollbar;
}

/** @type {React.FC<{ error: Error | null }>} */
const ErrorDisplay = ({ error }) =>
  import.meta.env.VITE_APP_ENV === 'production' ? (
    <h1>Something went wrong. Please try again later.</h1>
  ) : (
    // @ts-expect-error
    <Code code={error?.stack} />
  );

/** @type {React.FC<{ children: React.ReactNode }>} */
export const RollbarProvider = ({ children }) => (
  <Provider instance={rollbar}>
    <ErrorBoundary fallbackUI={ErrorDisplay}>{children}</ErrorBoundary>
  </Provider>
);
