import { observable, makeObservable, flow } from 'mobx';
import { BaseStore } from '../base-store';

export class BillingStore extends BaseStore {
  /** @type {import('dashboard-api-types').upcomingInvoice} */
  usage = null;

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      usage: observable,
      fetchUsage: flow,
      applyPromoCode: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/billing/';
  }

  /** @param {{ config?: import('axios').AxiosRequestConfig }} param */
  *fetchUsage({ config } = {}) {
    this.isLoadingMap.usage = this.isInitialLoading = true;

    try {
      /** @type {import('axios').AxiosResponse<Exclude<Usage, undefined>>} */
      const res = yield this.api.get('usage/', config);
      this.usage = res.data;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap.usage = this.isInitialLoading = false;
    }
  }

  /**
   * @param {string} promoCode
   * @param {number} planId
   * @returns {Promise<import('dashboard-api-types').promoCode>}
   */
  *applyPromoCode(promoCode, planId) {
    this.isLoadingMap.applyPromoCode = true;

    try {
      const res = yield this.api.get(`/promo_code/?code=${promoCode}&plan_id=${planId}`);
      return res.data;
    } finally {
      this.isLoadingMap.applyPromoCode = false;
    }
  }
}
