// @ts-check
import React from 'react';
import { observer } from 'mobx-react-lite';

import { useStores } from '~/stores';
import { UpgradeFromFreePlanButton } from '~/components/UpgradeFromFreePlanButton';
import { AnnoyingMessageTemplate, annoyingMessageType } from '../AnnoyingMessageTemplate';

const messageOptions = {
  button: {
    component: UpgradeFromFreePlanButton,
    label: 'Upgrade',
    dataAnalytics: 'button_annoying-banner-for-free-users_upgrade',
  },
  message: 'Remove hard limits and avoid your project being paused',
  type: annoyingMessageType.WARNING,
};

export const PushingToUpgrade = observer(() => {
  const { accountStore, projectStore } = useStores();

  const { isFree: isFreePlan } = accountStore;
  const isPaymentCardAdded = accountStore.data?.subscription?.has_card;
  const isPushingToUpgradeDisplayed =
    isFreePlan && isPaymentCardAdded && !!projectStore.project && !projectStore.isShared;

  if (!isPushingToUpgradeDisplayed) return null;

  return <AnnoyingMessageTemplate {...messageOptions} />;
});
