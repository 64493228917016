export const CONVERSION_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'succses',
  FAILED: 'failed',
};

export const ERROR_MESSAGE = {
  NO_FORMATS: 'No available options for this file type',
  GENERAL_ERROR: 'Something went wrong, please try again later',
};

export const DEFAULT_SELECT_VALUE = 'SELECT...';
