// @ts-check
/**
 * If receives errors with the words 'follow instructions below' without specifying the instructions
 *
 * @param {string[]} errors
 * @returns {string[]}
 */
export const clarifyErrors = (errors) =>
  errors?.map((/** @type {string} */ item) => {
    return item
      .split(/(?<=[.!?])\s+/) // Split on period, exclamation, or question mark followed by whitespace
      .filter((sentence) => !sentence.includes('instructions'))
      .join('.');
  });
