import { matchPath, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { useStores } from '~/stores';
import { parseFromLocalStorage } from '~/utils/browser';
import { RoutesEnum } from '~/App/Routing/routes';

const NEXT_ROUTE_KEY = '_next_route';

/**
 * @param {string} route This path will be stored in local storage and user will be redirected there
 *   immediately after email confirmation / sign in / mfa verification etc.
 */
export const storeNextRoute = (route) => {
  if (!route?.length || route.length > 1024) {
    return; // Protect from bad values.
  }

  // These routes are service ones, so we don't need to store them to redirect user after signing in.
  const excludeNextRoutes = [
    RoutesEnum.HOME, // Prevent useless redirect from / to /.
    RoutesEnum.APPROVE_EMAIL,
    RoutesEnum.BLOCKED_ACCOUNT,
    RoutesEnum.MFA_VERIFICATION,
    RoutesEnum.SESSION_EXPIRED,
    RoutesEnum.SIGN_IN,
    RoutesEnum.SIGN_UP,
    RoutesEnum.SIGN_UP_SUCCESS,
    RoutesEnum.TEAM_INVITATION,
    RoutesEnum.TOKEN_AUTH,
  ];

  if (excludeNextRoutes.find((r) => matchPath(r, route))) {
    return;
  }

  window.localStorage.setItem(
    NEXT_ROUTE_KEY,
    JSON.stringify({
      timestamp: Date.now(),
      route,
    })
  );
};

export const useNavigateNextRoute = () => {
  const navigate = useNavigate();
  const { accountStore } = useStores();
  const { route } = parseFromLocalStorage(NEXT_ROUTE_KEY) ?? {};

  return useCallback(() => {
    if (!route || !accountStore?.isAuthenticated) {
      return;
    }
    window.localStorage.removeItem(NEXT_ROUTE_KEY);
    navigate(route);
  }, [route, navigate, accountStore?.isAuthenticated]);
};
