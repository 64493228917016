import { makeObservable, flow, observable } from 'mobx';

import { BaseStore } from '../base-store';

export class AccountBillingInfoStore extends BaseStore {
  data = null;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      updateInfo: flow,
      fetchData: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/billing/address/';
  }

  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/', config);
      this.data = res.data;
      return this.data;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }

  *updateInfo(data) {
    this.isLoadingMap.updateInfo = true;

    try {
      const res = yield this.api.put('/', data);
      this.data = res.data;

      return this.data;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap.updateInfo = false;
    }
  }
}
