import { parseFromLocalStorage } from '~/utils/browser';
import { sendCustomGtmEvent, setGtmVariable } from '~/analytics/utils/tracking';
import { ONE_HOUR } from '~/utils/date';

const SIGNUP_TYPES = {
  EMAIL: 'email',
  GOOGLE: 'Google',
  GITHUB: 'GitHub',
};

const SIGNUP_MAX_INTERVAL = ONE_HOUR;

const LOCAL_STORAGE_CLICK_KEY = '_social_button';

/**
 * Send sign up custom event to GTM.
 *
 * @param {number} userId
 * @param {SIGNUP_TYPES.EMAIL | SIGNUP_TYPES.GOOGLE | SIGNUP_TYPES.GITHUB} type
 */
export const sendSignUpEvent = (userId, type = SIGNUP_TYPES.EMAIL) => {
  if (type === SIGNUP_TYPES.EMAIL) {
    // Reset social sign up timestamp to prevent sending event twice,
    // if user started sign up process via social button, but then signed up via email.
    window.localStorage.removeItem(LOCAL_STORAGE_CLICK_KEY);
  }
  sendCustomGtmEvent('auto_event_Account_sign_up', { userId, signupType: type });
};

/**
 * Store a click on a social button to local storage to be able to understand if a new user signed
 * up after returning from social network or it was just a login.
 *
 * @param {SIGNUP_TYPES.GOOGLE | SIGNUP_TYPES.GITHUB} socialProvider
 */
export const storeSocialClick = (socialProvider) => {
  window.localStorage.setItem(
    LOCAL_STORAGE_CLICK_KEY,
    JSON.stringify({
      timestamp: Date.now(),
      provider: socialProvider,
    })
  );
};

/**
 * Detect if there is a timestamp of a social button's click in the local storage and compare it
 * with user's signup time. If user signed up recently, track this signup to GTM dataLayer.
 *
 * @param {number} userId User ID.
 * @param {string} accountCreated Account creation time as string.
 */
export const detectSignUp = (userId, accountCreated) => {
  if (!accountCreated) {
    return;
  }

  const createdTs = new Date(accountCreated).getTime();
  if (!createdTs) {
    return;
  }

  if (userId) {
    setGtmVariable('userId', userId);
  }

  // Detect sign up via social networks.
  const { timestamp, provider } = parseFromLocalStorage(LOCAL_STORAGE_CLICK_KEY, true) ?? {};
  if (!timestamp || !provider) {
    return;
  }

  const isValidTimeFrame = createdTs > timestamp && createdTs < timestamp + SIGNUP_MAX_INTERVAL;
  const isValidProvider = Object.values(SIGNUP_TYPES).includes(provider);

  if (isValidTimeFrame && isValidProvider) {
    sendSignUpEvent(userId, provider);
  }
};
