// @ts-check
import { reaction, makeObservable, computed, observable, flow, action } from 'mobx';
import { BaseStore, longCache } from '~/stores/base-store';

/**
 * @typedef {{
 *   type: string;
 *   group: string | undefined;
 *   selected: boolean;
 * }} MimeTypes
 */

/**
 * @param {MimeTypes[]} mimeTypes
 * @param {string[]} savedTypes
 */
const prepareMimeTypes = (mimeTypes, savedTypes) => {
  const savedTypesSet = new Set(savedTypes);

  return mimeTypes.map(({ type, group, ...rest }) => ({
    ...rest,
    type,
    group: group === 'undefined' ? 'other' : group,
    selected: savedTypesSet.has(type),
  }));
};

/**
 * @param {MimeTypes[]} mimeTypes
 * @returns {string[]}
 */
const getMimeGroups = (mimeTypes) => {
  const groups = new Set();
  let hasOther = false;

  for (const item of mimeTypes) {
    if (item.group === 'undefined') {
      hasOther = true;
    } else {
      groups.add(item.group);
    }
  }

  const output = ['all', ...groups].sort();
  if (hasOther) {
    output.push('other');
  }

  return output;
};

/** @augments {BaseStore<never>} */
export class ProjectMimeTypesStore extends BaseStore {
  /** @type {MimeTypes[]} */
  mimeTypes = [];

  /** @type {string[]} */
  mimeGroups = [];

  /** @type {string[]} */
  savedTypes = [];

  /** @type {string} */
  searchValue = '';

  /** @type {boolean} */
  hasSearchedValue = false;

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      mimeTypes: observable,
      mimeGroups: observable,
      savedTypes: observable,
      searchValue: observable,
      hasSearchedValue: observable,
      selectedTypesTotal: computed,
      fetchMimeTypes: flow,
      setSelectedValue: action,
      setSelectedGroup: action,
      isGroupUnSelected: action,
      setSearchValue: action,
      resetSearchValue: action,
      getSearchedTypes: action,
    });
  }

  onInit() {
    // subscribe to projectStore, for sync selected filtered_mime_types
    reaction(
      () => this.appStore.stores.projectStore.project,
      (project) => {
        if (project?.filtered_mime_types) {
          this.savedTypes = project.filtered_mime_types;
        }
      }
    );
  }

  get baseURL() {
    return `/apps/api/v0.1/mime/types/`;
  }

  get selectedTypesTotal() {
    return this.mimeTypes.filter((item) => item.selected).length;
  }

  *fetchMimeTypes() {
    this.isLoadingMap.fetchMimeTypes = true;

    try {
      /** @type {import('axios').AxiosResponse<{ mime_types: MimeTypes[] }>} */
      const res = yield this.api.get('/', longCache);

      this.mimeGroups = getMimeGroups(res.data.mime_types);
      this.mimeTypes = prepareMimeTypes(res.data.mime_types, this.savedTypes);

      return res;
    } finally {
      this.isLoadingMap.fetchMimeTypes = false;
    }
  }

  /** @param {string} type */
  setSelectedValue = (type) => {
    this.mimeTypes = this.mimeTypes.map((item) =>
      item.type === type ? { ...item, selected: !item.selected } : item
    );
  };

  /**
   * @param {string} group
   * @param {boolean} isSelected
   */
  setSelectedGroup = (group, isSelected) => {
    if (group === 'all') {
      this.mimeTypes = this.mimeTypes.map((item) => ({ ...item, selected: isSelected }));
    } else {
      this.mimeTypes = this.mimeTypes.map((item) =>
        item.group === group ? { ...item, selected: isSelected } : item
      );
    }
  };

  /** @param {string} group */
  isGroupUnSelected = (group) => {
    return group === 'all'
      ? this.mimeTypes.some(({ selected }) => !selected)
      : this.mimeTypes.some(({ group: g, selected }) => g === group && !selected);
  };

  /** @param {string} value */
  setSearchValue(value) {
    this.searchValue = value;
  }

  resetSearchValue() {
    this.searchValue = '';
    this.hasSearchedValue = false;
  }

  /**
   * @param {MimeTypes[]} types
   * @returns {MimeTypes[] | []}
   */
  getSearchedTypes = (types) => {
    if (!this.searchValue.length) {
      return types;
    }

    const searchedType = types.filter(({ type }) =>
      type.toLowerCase().includes(this.searchValue.toLowerCase())
    );

    if (searchedType.length) {
      this.hasSearchedValue = true;
    } else {
      this.hasSearchedValue = false;
    }

    return searchedType;
  };

  reset() {
    super.reset();
    this.mimeTypes = [];
    this.mimeGroups = [];
    this.savedTypes = [];
    this.searchValue = '';
    this.hasSearchedValue = false;
  }
}
