import styled from 'styled-components';
import { customScrollbar } from '@uc-common/utils-styles/custom-scrollbar';
import { ModalContentContainer } from './components/ModalContentContainer';

export const ModalMainContent = styled.div`
  position: relative;
  padding: 24px var(--modal-horizontal-padding);
  flex-grow: 1;

  ${customScrollbar('#cecdd0', 'transparent', 'y')}
`;

export const ModalText = styled.p`
  font-size: 15px;
  line-height: 1.4;
  color: rgba(89, 101, 112, 0.8);
  margin-bottom: 20px;
`;

export const ModalDescription = styled.p`
  font-size: 16px;
  line-height: 1.4;
  color: rgba(89, 101, 112, 0.8);
  margin-bottom: 20px;
`;

export const ModalFooter = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f1f2f3;
  background-color: #f8f9fb;
  padding: 22px var(--modal-horizontal-padding);
  border-radius: 0 0 var(--modal-border-radius) var(--modal-border-radius);
  z-index: 100;
`;

export const ModalFooterButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-auto-columns: minmax(100px, max-content);
  grid-gap: 6px;
`;

export const ModalContentContainerFullScreen = styled(ModalContentContainer)`
  width: 100vw;
  height: 100vh;
  max-height: unset;
  overflow: auto;
  box-shadow: none;
`;

export const AnnouncementModalContainer = styled(ModalContentContainer)`
  width: 390px;
`;
