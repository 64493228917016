// @ts-check
import { useCallback, useState } from 'react';

const isSSR = typeof window === 'undefined';

/**
 * @typedef {{
 *   Domain?: string;
 *   Expires?: number | string | Date; // Number means number of days from now.
 *   'Max-Age'?: number; // In seconds.
 *   Path?: string;
 *   SameSite?: 'Strict' | 'Lax' | 'None';
 *   Secure?: boolean;
 * }} CookieOptions
 */

/**
 * @param {string} name
 * @param {string} value
 * @param {CookieOptions} options
 */
export const setCookie = (name, value, options = {}) => {
  if (isSSR) return;

  if (!options.Path) {
    options.Path = '/'; // Set for all paths by default.
  }

  if (!options.Domain) {
    options.Domain = document.location.hostname; // Set for current domain with subdomains by default.
  }

  // Use localhost in tests to be able to test setting cookie.
  if (document.location.hostname === '127.0.0.1' || document.location.hostname === 'localhost') {
    options.Domain = document.location.hostname;
  }

  // Use Fern preview domain in tests to be able to test setting cookie.
  if (document.location.hostname.includes('buildwithfern')) {
    options.Domain = document.location.hostname;
  }

  if (!options.SameSite) {
    options.SameSite = 'Lax';
  }

  if (options.Secure === undefined) {
    options.Secure = true;
  }

  if (options.Expires) {
    if (options.Expires instanceof Date) {
      options.Expires = options.Expires.toUTCString();
    } else if (typeof options.Expires === 'number') {
      options.Expires = new Date(Date.now() + options.Expires * 24 * 3600 * 1000).toUTCString();
    }
  }

  document.cookie = Object.entries(options).reduce(
    (cookie, [key, value]) => {
      // Passing false allows to override default value.
      if (value === false) {
        return cookie;
      }

      cookie += `; ${key}`;
      if (value !== true) {
        cookie += `=${value}`;
      }

      return cookie;
    },
    `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
  );
};

/**
 * @param {string} name
 * @returns {string | undefined}
 */
export const getCookie = (name) => {
  if (isSSR) {
    return;
  }

  const search = `${encodeURIComponent(name)}=`;
  const start = document.cookie.indexOf(search);
  if (start === -1) {
    return;
  }

  const end = document.cookie.indexOf(';', start);

  return decodeURIComponent(
    document.cookie.slice(start + search.length, end === -1 ? undefined : end)
  );
};

/**
 * @param {string} name
 * @param {CookieOptions | undefined} options You should pass the same options as for setCookie to
 *   remove it.
 */
export const removeCookie = (name, options = {}) => {
  // `Expires: -1` or `Max-Age: 0` don't work, they make session cookie.
  options.Expires = new Date(0).toUTCString();
  setCookie(name, '', options);
};

/**
 * @param {string} name
 * @param {CookieOptions | undefined} options
 * @returns {{
 *   cookie: string | undefined;
 *   setCookie: (value: string) => void;
 *   removeCookie: () => void;
 * }}
 */
export const useCookie = (name, options = {}) => {
  const [cookie, setCookieState] = useState(getCookie(name));

  const set = useCallback(
    /** @type {(value: string) => void} */
    (value) => {
      setCookie(name, value, options);
      setCookieState(value);
    },
    [name, options, setCookieState]
  );

  const remove = useCallback(
    /** @type {() => void} */
    () => {
      removeCookie(name, options);
      setCookieState(undefined);
    },
    [name, options, setCookieState]
  );

  return { cookie, setCookie: set, removeCookie: remove };
};
