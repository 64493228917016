// @ts-check
import { action, observable, makeObservable, flow, reaction, toJS, computed } from 'mobx';
import isEqual from 'react-fast-compare';
import qs from 'query-string';
import { getQueryFromParams } from '~/utils/browser';
import { message } from '~/components/Toaster';
import { pluralize } from '~/utils/pluralize';
import { BaseStore } from '../base-store';

/**
 * @typedef {{
 *   mime: string;
 *   type: string;
 *   subtype: string;
 * }} MimeInfo
 */

/**
 * @typedef {{
 *   bitrate: number | null;
 *   codec: string | null;
 *   sample_rate: number | null;
 *   channels: string | null;
 * }} AudioInfo
 */

/**
 * @typedef {{
 *   latitude: number;
 *   longitude: number;
 * }} GeoLocation
 */

/**
 * @typedef {{
 *   height: number;
 *   width: number;
 *   geo_location: GeoLocation | null;
 *   datetime_original: string;
 *   format: string;
 *   color_mode: string;
 *   dpi: {
 *     '0': number;
 *     '1': number;
 *   } | null;
 *   orientation: number | null;
 *   sequence: boolean | null;
 * }} ImageInfo
 */

/**
 * @typedef {{
 *   duration: number;
 *   format: string;
 *   bitrate: number;
 *   audio: AudioInfo | null;
 *   video: {
 *     height: number;
 *     width: number;
 *     frame_rate: number;
 *     bitrate: number;
 *     codec: string;
 *   };
 * }} VideoInfo
 */

/**
 * @typedef {{
 *   mime?: MimeInfo;
 *   image?: ImageInfo;
 *   video?: VideoInfo;
 * }} ContentInfo
 */

/** @typedef {string[]} FileInfoVariations */
/** @typedef {Record<string, string>} Metadata */

/**
 * @typedef {{
 *   data: {
 *     infected: boolean;
 *     infected_with: string;
 *   };
 *   version: string;
 *   datetime_created: string;
 *   datetime_updated: string;
 * }} ClamavVirusScan
 */

/**
 * @typedef {{
 *   Name: string;
 * }} AwsRekognitionDetectLabelParent
 */

/**
 * @typedef {{
 *   Confidence: number;
 *   BoundingBox: {
 *     Height: number;
 *     Left: number;
 *     Top: number;
 *     Width: number;
 *   };
 * }} AwsRekognitionDetectLabelInstance
 */

/**
 * @typedef {{
 *   Confidence: number;
 *   Name: string;
 *   Parents: AwsRekognitionDetectLabelParent[];
 *   Instances: AwsRekognitionDetectLabelInstance[];
 * }} AwsRekognitionDetectLabel
 */

/**
 * @typedef {{
 *   data: {
 *     LabelModelVersion: string;
 *     Labels: AwsRekognitionDetectLabel[];
 *   };
 *   version: string;
 *   datetime_created: string;
 *   datetime_updated: string;
 * }} AwsRekognitionDetectLabels
 */

/**
 * @typedef {{
 *   data: {
 *     foreground_type: string;
 *   };
 *   version: string;
 *   datetime_created: string;
 *   datetime_updated: string;
 * }} RemoveBg
 */

/**
 * @typedef {{
 *   uc_clamav_virus_scan: ClamavVirusScan;
 *   aws_rekognition_detect_labels: AwsRekognitionDetectLabels;
 *   remove_bg: RemoveBg;
 * }} AppData
 */

/**
 * TODO: genereate from spec
 *
 * @typedef {{
 *   datetime_removed: string | null;
 *   datetime_stored: string | null;
 *   datetime_uploaded: string;
 *   is_image: boolean;
 *   is_ready: boolean;
 *   mime_type: string;
 *   original_file_url: string | null;
 *   original_filename: string;
 *   size: number;
 *   url: string;
 *   uuid: string;
 *   variations: FileInfoVariations | null;
 *   content_info: ContentInfo | null;
 *   metadata: Metadata | null;
 *   appdata: AppData | null;
 * }} FileInfo
 */

/**
 * TODO: genereate from spec
 *
 * @typedef {{
 *   next?: string | null;
 *   per_page?: number;
 *   previous?: string | null;
 *   results?: FileInfo[];
 *   total: number;
 *   totals?: {
 *     removed: number;
 *     stored: number;
 *     unstored: number;
 *   };
 * }} Data
 */

/**
 * @typedef {{
 *   limit?: number;
 *   ordering?: { id: string; desc: boolean } | string;
 *   search?: string;
 *   infected?: string;
 *   from?: string | undefined;
 *   to?: string | undefined;
 *   include?: string | undefined;
 * }} Params
 */

/**
 * @typedef {import('@uploadcare/file-uploader').OutputError<
 *   import('@uploadcare/file-uploader').OutputFileErrorType
 * >} LastUploaderError
 */

/** @param {{ id?: string; desc?: string } | { ordering?: string } | {}} [arg={}] */
export const prepareOrderingParam = (arg = {}) => {
  if ('ordering' in arg && arg.ordering) {
    const { ordering } = arg;
    const isDesc = ordering[0] === '-';
    return {
      id: isDesc ? ordering.slice(1) : ordering,
      desc: isDesc,
    };
  }
  if ('id' in arg && 'desc' in arg && arg.id && arg.desc) {
    const { id, desc } = arg;
    return desc ? `-${id}` : id;
  }
};

/** @augments {BaseStore<Data>} */
export class ProjectFilesStore extends BaseStore {
  /** @type {Data} */
  initialData = {
    total: 0,
  };

  /** @type {Params} */
  initialParams = {
    limit: 100,
    ordering: { id: 'datetime_uploaded', desc: true },
    search: '', // Contains UUID for fetching file by.
    infected: '',
  };

  /** @type {Data} */
  data = this.initialData;

  /** @type {Params} */
  params = { ...this.initialParams };

  /** @type {any[]} */
  recentFiles = []; // recently changed files or uploaded

  /** @type {LastUploaderError | null} */
  lastError = null;

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      params: observable,
      recentFiles: observable,
      lastError: observable,
      fetchData: action,
      setParams: action,
      resetParams: action,
      fetchFiles: flow,
      fetchFile: flow,
      removeFiles: flow,
      storeFiles: flow,
      setLastError: action,
      filesTotal: computed,
    });

    const parsedQuery = qs.parse(window.location.search);
    const hasQuery = Object.keys(parsedQuery).length > 0;
    if (hasQuery) {
      this.params = {
        ...this.initialParams,
        ...parsedQuery,
        ordering: prepareOrderingParam({
          ordering: parsedQuery.ordering ?? prepareOrderingParam(this.initialParams.ordering),
        }),
      };
    }
  }

  onInit() {
    reaction(
      () => this.data,
      (data, prevData) => {
        if (isEqual(toJS(prevData), this.initialData)) {
          return;
        }

        if (data.results) {
          this.recentFiles = data.results?.filter((file) => {
            const prevFile = prevData.results?.find((prevItem) => prevItem.uuid === file.uuid);
            if (!isEqual(toJS(prevFile), toJS(file))) {
              return file;
            }

            return false;
          });
        }
      }
    );
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/files/`;
  }

  getQuery(forNavigate = false) {
    const { search } = this.params;
    if (search) {
      return getQueryFromParams({
        search,
      });
    }

    if (forNavigate) {
      return getQueryFromParams({
        from: this.params.from,
        to: this.params.to,
      });
    }

    return getQueryFromParams({
      ...toJS(this.params),
      ordering: prepareOrderingParam(this.params.ordering),
    });
  }

  /** @param {{ config?: import('axios').AxiosRequestConfig }} param */
  fetchData = ({ config } = {}) => {
    const uuid = this.params.search;

    if (uuid) {
      // search by uuid
      return this.fetchFile({ config });
    }

    return this.fetchFiles({ config });
  };

  /** @param {{ config?: import('axios').AxiosRequestConfig }} param */
  *fetchFiles({ config } = {}) {
    this.isLoading = true;

    try {
      /** @type {import('axios').AxiosResponse<Data>} */
      const res = yield this.api.get(this.getQuery(), config);
      this.data = res.data;
      this.isLoading = this.isInitialLoading = false;
      return res;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(/** @type {Error} */ (e));
    }
  }

  /** @param {{ config?: import('axios').AxiosRequestConfig }} param */
  *fetchFile({ config } = {}) {
    this.isLoading = true;

    try {
      /** @type {import('axios').AxiosResponse<Exclude<Data['results'], undefined>[0]>} */
      const res = yield this.api.get(`/${this.params.search}/`, config);
      const isRemovedFile = !!res.data.datetime_removed;

      if (isRemovedFile) {
        this.data = { results: [], ...this.initialData };
      } else {
        this.data = { results: [res.data], total: 1 };
      }

      this.isLoading = this.isInitialLoading = false;
      return res;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;

      if (/** @type {import('axios').AxiosError} */ (e)?.response?.status === 404) {
        // file not exist
        this.data = { results: [], ...this.initialData };
      } else {
        this.processErrors(/** @type {Error} */ (e));
      }
    }
  }

  /** @param {string[]} uuids */
  *removeFiles(uuids) {
    this.isLoading = true;

    try {
      /** @type {import('axios').AxiosResponse<{ result: Data['results'] }>} */
      const res = yield this.api.delete('/', {
        data: uuids,
      });
      // remove file form list
      this.data = {
        ...this.data,
        results: this.data.results?.filter(({ uuid }) => !uuids.includes(uuid)),
        total: this.data.total - uuids.length,
      };
      this.isLoading = false;
      message.info(`${pluralize(uuids.length, 'File was deleted.', 'Files were deleted.', true)}`);
      return res;
    } catch (e) {
      this.isLoading = false;
      this.processErrors(/** @type {Error} */ (e));
    }
  }

  /** @param {string[]} uuids */
  *storeFiles(uuids) {
    this.isLoading = true;

    try {
      /** @type {import('axios').AxiosResponse<{ result: Data['results'] }>} */
      const res = yield this.api.put('/', uuids);
      // update files in list
      this.data = {
        ...this.data,
        results: this.data.results?.map((file) => {
          const newFile = res.data.result?.find(({ uuid }) => uuid === file.uuid);
          return newFile || file;
        }),
      };
      this.isLoading = false;
      message.info(`${pluralize(uuids.length, 'File was stored.', 'Files were stored.', true)}`);
      return res;
    } catch (e) {
      this.isLoading = false;
      this.processErrors(/** @type {Error} */ (e));
    }
  }

  resetParams() {
    this.params = { ...this.initialParams };
  }

  /** @param {typeof this.params} params */
  setParams(params) {
    // XXX: fix platform backend pagination bug,
    // transform 'include=appdata' x N to 'include=appdata'.
    if (params.include && Array.isArray(params.include)) {
      [params.include] = params.include;
    }

    this.params = params;
  }

  get hasData() {
    return this.data.results ? this.data.results.length > 0 : false;
  }

  get filesTotal() {
    return this.data.total <= 0 ? 0 : this.data.total;
  }

  reset() {
    super.reset();
    this.resetParams();
    this.recentFiles = [];
    this.lastError = null;
  }

  /** @param {LastUploaderError} error */
  setLastError(error) {
    this.lastError = error;
  }

  getLastError() {
    return this.lastError;
  }
}
