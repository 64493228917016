// @ts-check
import { useCallback, useEffect, useId } from 'react';
// @ts-ignore
import { equals } from 'remeda';
import { useNavigate } from 'react-router-dom';
import { RoutesEnum } from '~/App';
import { AnalyticsEventCategoryEnum, sendCustomGtmEvent } from '~/analytics/utils/tracking';
import { useModalsContext } from '~/components/Modal/context/ModalsContext';

/** @typedef {import('~/components/Modal/Modal').ModalConfig} ModalConfig */

/** @type {ModalConfig} */
const DEFAULT_CONFIG = {
  hideOnOutsideClick: true,
};

/**
 * @param {string} name
 * @param {object} [props={}] Props to pass to modal. Default is `{}`
 * @param {ModalConfig} config
 * @returns {[open: (el?: any) => void, close: () => void]}
 */
export const useModal = (name, props = {}, config = {}) => {
  const id = useId();
  const { activeModals, setActiveModals } = useModalsContext();
  const shouldRerender = activeModals.has(id) && !equals(activeModals.get(id).props, props);
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    sendCustomGtmEvent(`Modal closed : ${name}`, {
      category: AnalyticsEventCategoryEnum.LAYOUT,
    });

    setActiveModals((currentModals) => {
      const newActiveModals = new Map(currentModals);
      newActiveModals.delete(id);
      return newActiveModals;
    });

    if (document.location.hash === '#plans') {
      navigate(RoutesEnum.BILLING);
    }
  }, [setActiveModals, id, name, navigate]);

  const handleOpen = useCallback(
    /** @param {unknown[]} args */
    (...args) => {
      const modalConfig = { ...DEFAULT_CONFIG, ...config };

      sendCustomGtmEvent(`Modal open : ${name}`, {
        category: AnalyticsEventCategoryEnum.LAYOUT,
      });

      setActiveModals((currentModals) => {
        const newActiveModals = new Map(currentModals);
        newActiveModals.set(id, {
          onClose: handleClose,
          config: modalConfig,
          onOpenArgs: args,
          props,
          name,
        });
        return newActiveModals;
      });
    },
    [setActiveModals, handleClose, config, props, name, id]
  );

  useEffect(() => {
    if (!shouldRerender) return;

    setActiveModals((currentModals) => {
      const currentModal = currentModals.get(id);
      const newActiveModals = new Map(currentModals);
      newActiveModals.set(id, { ...currentModal, props });
      return newActiveModals;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRerender, setActiveModals]);

  return [handleOpen, handleClose];
};
