// @ts-check
import { flow, observable, makeObservable } from 'mobx';
import { BaseStore } from '../base-store';

/**
 * @typedef {{
 *   bucket_name: string;
 *   bucket_location: string;
 * }} Data
 */

/** @augments {BaseStore<Data>} */
export class ProjectS3ForeignBucketStore extends BaseStore {
  /** @type {Data | null} */
  data = null;

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      addBucket: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/s3_foreign_bucket/`;
  }

  /** @param {{ config?: import('axios').AxiosRequestConfig }} param */
  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      /** @type {import('axios').AxiosResponse<Exclude<Data, undefined>>} */
      const res = yield this.api.get('/', config);
      this.data = res.data;
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }

  /** @param {{ bucket_name: string }} data */
  *addBucket(data) {
    this.isLoading = true;

    try {
      /** @type {import('axios').AxiosResponse<Exclude<Data, undefined>>} */
      const res = yield this.api.post('/', data);
      this.data = res.data;

      return res;
    } finally {
      this.isLoading = false;
    }
  }
}
