import { RoutesEnum, SettingTabEnum } from '~/App';

export const redirects = [
  {
    from: '/accounts/settings/password/',
    to: RoutesEnum.PROFILE,
  },
  {
    from: '/accounts/settings/email/',
    to: RoutesEnum.PROFILE,
  },
  {
    from: '/accounts/settings/auth/',
    to: RoutesEnum.PROFILE,
  },
  {
    from: '/accounts/settings/plan/',
    to: RoutesEnum.BILLING,
  },
  {
    from: '/accounts/settings/billing/',
    to: RoutesEnum.BILLING,
  },
  {
    from: '/accounts/invoices/',
    to: RoutesEnum.BILLING,
  },
  {
    from: '/accounts/settings/subscriptions/',
    to: RoutesEnum.PROFILE,
  },
  {
    from: '/projects/:pubKey/team/',
    to: `${RoutesEnum.PROJECT_SETTINGS}#${SettingTabEnum.TEAM}`,
  },
  {
    from: '/projects/:pubKey/settings/project/',
    to: `${RoutesEnum.PROJECT_SETTINGS}#${SettingTabEnum.PROJECT}`,
  },
  {
    from: '/projects/:pubKey/integrate/file-uploader/',
    to: RoutesEnum.PROJECT_UPLOADING_WIDGET,
  },
  {
    from: '/projects/:pubKey/settings/uploading/',
    to: RoutesEnum.PROJECT_UPLOADING_CONFIGURE,
  },
  {
    from: '/projects/:pubKey/settings/storage/',
    to: `${RoutesEnum.PROJECT_SETTINGS}#${SettingTabEnum.STORAGE}`,
  },
  {
    from: '/projects/:pubKey/uploading/configure/',
    to: RoutesEnum.PROJECT_UPLOADING_CONFIGURE,
  },
  {
    from: '/projects/:pubKey/settings/cdn/',
    to: RoutesEnum.PROJECT_DELIVERY_CONFIGURE,
  },
  {
    from: '/projects/:pubKey/delivery/configure/',
    to: RoutesEnum.PROJECT_DELIVERY_CONFIGURE,
  },
  {
    from: '/projects/:pubKey/settings/webhooks/',
    to: RoutesEnum.PROJECT_API_WEBHOOKS,
  },
  {
    from: RoutesEnum.PROJECT_USAGE,
    to: RoutesEnum.PROJECT_ANALYTICS,
  },
  {
    from: '/projects/:pubKey/uploader-configurator/',
    to: RoutesEnum.PROJECT_GET_STARTED,
  },
];
