import { useStores } from '~/stores';
import { salesLink } from '~/utils/sales-link';
import { BaseLink } from '~/components/Link';
import { useModal, MODALS_ENUM } from '~/components/Modal';

/**
 * Returns a handleClick function that calls onClick callback and opens an upgrade plan modal if
 * possible, also returns an element type, href and target which should be used for the button or
 * the link.
 *
 * @param {object} options
 * @param {number} [options.planId]
 * @param {Function} [options.onClick]
 * @param {Function} [options.onSuccess]
 * @returns {{
 *   handleClick: Function;
 *   element: string | import('react').JSXElementConstructor;
 *   type: 'button' | undefined;
 *   href: string;
 *   target: string;
 * }}
 */
export const useShowUpgrade = ({ planId, onClick, onSuccess } = {}) => {
  const { accountPlanStore, projectStore } = useStores();

  planId ??= accountPlanStore.nextPlan?.id ?? accountPlanStore.firstPaidPlan?.id;

  const [showUpgradeModal] = useModal(
    MODALS_ENUM.UPGRADE_PLAN,
    {
      planToUpgradeId: planId,
      onSuccess,
    },
    {
      isFullScreenModal: true,
    }
  );
  const isShowPlanModal = !projectStore.isShared && accountPlanStore.isNewPlan(planId);

  const handleClick = (e) => {
    onClick?.(e);

    if (isShowPlanModal) {
      showUpgradeModal();
    }
  };

  return {
    handleClick,
    element: isShowPlanModal ? 'button' : BaseLink,
    type: isShowPlanModal ? 'button' : undefined,
    ...(!isShowPlanModal && { ...salesLink }),
  };
};
