import { ONE_DAY } from '~/utils/date';

/**
 * Calculates the number of days left in a trial period.
 *
 * @param {string} trialEndDate - The end date of the trial in 2024-07-06T14:15:49Z format.
 * @returns {number} The number of days left in the trial period.
 */
export const daysUntilTrialEnds = (trialEndDate) => {
  const endDate = new Date(trialEndDate);
  const currentDate = new Date();

  const timeDifference = endDate - currentDate;

  const daysLeft = Math.ceil(timeDifference / ONE_DAY);

  return daysLeft > 0 ? daysLeft : 1;
};
