import { lazy } from 'react';

export const ProjectFiles = lazy(() => import('~/pages/project-files'));
export const ProjectApiKeys = lazy(() => import('~/pages/project-api-keys'));
export const ProjectGetStarted = lazy(() => import('~/pages/project-get-started'));
export const ProjectAnalytics = lazy(() => import('~/pages/project-analytics'));
export const ProjectUsage = lazy(() => import('~/pages/project-usage'));
export const ProjectUploadingWidget = lazy(() => import('~/pages/project-uploading-widget'));
export const ProjectDeliveryImageProcessing = lazy(() =>
  import('~/pages/project-image-processing')
);
export const ProjectDeliveryQuickStart = lazy(() => import('~/pages/project-delivery-quickstart'));
export const ProjectApiWebhooks = lazy(() => import('~/pages/project-api-webhooks'));
export const ProjectApiLogs = lazy(() => import('~/pages/project-api-logs'));
export const ProjectApiLogsItem = lazy(() => import('~/pages/project-api-logs/ProjectApiLogsItem'));
export const ProjectSettings = lazy(() => import('~/pages/project-settings'));
export const ProjectUploadingBlocks = lazy(() => import('~/pages/project-uploading-blocks'));
export const PasswordChangeConfirmation = lazy(() =>
  import('~/pages/password-change-confirmation')
);
export const Profile = lazy(() => import('~/pages/profile'));
export const Billing = lazy(() => import('~/pages/billing'));
export const Workspace = lazy(() => import('~/pages/workspace'));
export const OptimizeImagesPage = lazy(() =>
  import('~/pages/project-get-started/pages/OptimizeImagesPage')
);
export const TransformImagesPage = lazy(() =>
  import('~/pages/project-get-started/pages/TransformImagesPage')
);
export const UploadAssetsPage = lazy(() =>
  import('~/pages/project-get-started/pages/UploadAssetsPage')
);
export const UploadApiPage = lazy(() => import('~/pages/project-get-started/pages/UploadApiPage'));
export const Qualification = lazy(() => import('~/pages/qualification'));
export const CreateNewProject = lazy(() => import('~/pages/create-new-project'));
