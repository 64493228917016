// @ts-check
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { Link } from '../Link';
import { DropDownContext } from './DropDown';

const activeStyles = css`
  background: rgba(55, 113, 255, 0.1);
  color: #3771ff;

  svg {
    opacity: 1;
  }
`;

export const StyledDropDownMenuItem = styled(Link)(
  ({ $disabled, $isActive }) => css`
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #596570;
    padding: 8px 20px 8px 11px;
    display: flex;
    align-items: center;
    text-align: left;
    transition: background-color 0.2s ease, opacity 0.2s ease, color 0.2s ease;
    border-radius: 5px;

    svg {
      color: inherit;
      opacity: 0.6;
    }

    &:hover {
      color: #000000;
      background: rgba(1, 7, 23, 0.04);
    }

    &:active {
      background: rgba(1, 7, 23, 0.06);
    }

    ${$isActive && activeStyles}

    ${$disabled &&
    css`
      pointer-events: none;
      opacity: 0.75;
    `}

    &.active {
      ${activeStyles}
    }
  `
);

/**
 * @type {React.FC<{
 *   children: React.ReactNode;
 *   disabled?: boolean;
 *   hideOnClick: boolean;
 *   onClick?: (e: any) => void;
 *   isActive?: boolean;
 *   as?: any;
 *   forwardedAs?: any;
 *   [key: string]: any;
 * }>}
 */
export const DropDownMenuItem = ({
  children,
  disabled,
  hideOnClick,
  onClick,
  isActive,
  forwardedAs,
  as,
  ...props
}) => {
  const { setIsOpen } = useContext(DropDownContext);

  const handleClick = (/** @type {React.MouseEvent<HTMLAnchorElement>} */ event) => {
    onClick?.(event);

    if (hideOnClick) {
      setIsOpen(false);
    }
  };

  return (
    <StyledDropDownMenuItem
      $isActive={isActive}
      $disabled={disabled}
      onClick={handleClick}
      forwardedAs={forwardedAs}
      as={as}
      {...props}
    >
      {children}
    </StyledDropDownMenuItem>
  );
};

export const DropDownMenuItemSeparator = styled.div`
  border-top: 1px solid #f1f2f3;
`;

export const DropDownMenuItemDanger = styled(DropDownMenuItem)`
  color: #ed364c;

  &:hover {
    background: #fff5f6;
    color: #d8112a;
  }
`;
