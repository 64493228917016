// @ts-check
import { makeObservable, reaction, observable, action, flow, computed, toJS } from 'mobx';
import {
  ConversionStatus,
  conversionJobPoller,
  ConversionType,
  conversionInfo,
} from '@uploadcare/rest-client';
import { RESPONSE_CODES } from '~/utils/http';
import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';
import {
  ERROR_MESSAGE,
  DEFAULT_SELECT_VALUE,
  CONVERSION_STATUS,
} from '../constants/convert-documents';
import {
  getAvailableFormats,
  normalizeUsedFormats,
  normalizeUsedConversions,
  buildLink,
} from '../../pages/project-files/FilePreview/ConversionBlock/utils/data-helpers';
import { getRestApiAuthSchema } from '../../utils/auth';

/**
 * @typedef {Generator<
 *   | Promise<
 *       Promise<
 *         {
 *           path: string;
 *         } & import('@uploadcare/rest-client').ConversionStatusResponse<
 *           import('@uploadcare/rest-client').ConversionStatusResultBase
 *         >
 *       >[]
 *     >
 *   | Promise<PromiseSettledResult<any>[]>
 * >} ConversionJobPollerResponse
 */

/**
 * @typedef {{
 *   authSchema: import('@uploadcare/rest-client').UploadcareAuthSchema;
 *   apiBaseURL: string;
 * }} RestApiOpts
 */

/** @augments {BaseStore<never>} */
export class ProjectConvertDocumentsStore extends BaseStore {
  /** @type {RestApiOpts} */
  restApiOpts = {
    // @ts-ignore
    authSchema: null,
    apiBaseURL: '',
  };

  // stored uuid of selected file + widget active
  /** @type {string} */
  сurrentConvertedFile = '';

  // for tracking of which project and file the conversion is processing and inform user about its completion
  /** @type {{ [uuid: string]: { [key: string]: string } }} */
  docsInConverting = {};

  /** @type {{ value: string; label: string }[]} */
  usedFormats = [];

  /** @type {{ [uuid: string]: { value: string }[] }} */
  formats = {};

  /** @type {string} */
  selectedFormat = DEFAULT_SELECT_VALUE;

  /** @type {{ [uuid: string]: Conversion[] }} */
  conversions = {};

  /** @type {boolean} */
  isConverting = false;

  /** @type {boolean} */
  isLoadingFormats = false;

  /** @type {{ message: string; code: number | undefined } | null} */
  convertError = null;

  /** @type {{ message: string; code: number | undefined } | null} */
  formatsError = null;

  /** @param {import('../app-store').AppStore} store */
  constructor(store) {
    super(store);

    makeObservable(this, {
      сurrentConvertedFile: observable,
      setCurrentConvertedFile: action,
      docsInConverting: observable,
      usedFormats: observable,
      formats: observable,
      selectedFormat: observable,
      setSelectedFormat: action,
      conversions: observable,
      isConverting: observable,
      isLoadingFormats: observable,
      convertError: observable,
      formatsError: observable,
      convertDocument: flow,
      loadAllFormats: flow,
      sortedConversions: computed,
      isAnyAvailableFormat: computed,
    });
  }

  onInit() {
    reaction(
      () => this.appStore.stores.projectFileStore.selectedFile?.variations,
      (variations) => {
        if (this.appStore.stores.projectFileStore.selectedUuid) {
          const uuid = this.appStore.stores.projectFileStore.selectedUuid;
          this.conversions[uuid] =
            this.conversions[uuid] || normalizeUsedConversions(toJS(variations));
        }

        this.usedFormats = normalizeUsedFormats(toJS(variations));
      }
    );
    reaction(
      () => this.appStore.stores.projectStore.pubKey,
      (pubKey) => {
        if (pubKey) {
          const authSchema = getRestApiAuthSchema(pubKey);
          this.restApiOpts = {
            authSchema,
            apiBaseURL: import.meta.env.VITE_REST_API_BASE,
          };
        }
      }
    );
  }

  /**
   * @param {string} uuid
   * @param {string} projectName
   * @param {string} fileName
   * @returns {ConversionJobPollerResponse}
   */
  *convertDocument(uuid, projectName, fileName) {
    if (this.selectedFormat === DEFAULT_SELECT_VALUE) {
      return;
    }

    this.docsInConverting[uuid] = {
      projectName,
      fileName,
    };

    const conversion = {
      format: this.selectedFormat,
      linkTo: undefined,
      status: CONVERSION_STATUS.LOADING,
    };

    this.conversions[uuid] = this.conversions[uuid]
      ? [...this.conversions[uuid], conversion]
      : [conversion];

    try {
      // https://uploadcare.github.io/uploadcare-js-api-clients/rest-client/#md:job-status-polling
      const jobs = yield conversionJobPoller(
        {
          type: ConversionType.DOCUMENT,
          paths: [`${uuid}/document/-/format/${this.selectedFormat}/`],
          store: true,
        },
        this.restApiOpts
      );

      const [result] = yield Promise.allSettled(jobs);

      // there can be several conversions at the same time -> uuid, this.selectedFormat will contain irrelevant data
      const /** @type {string} */ resultFileUuid = result.value.path.split('/')[0];
      const resultSelectedFormat = result.value.path.split('/')[4];
      const resultProjectName = toJS(this.docsInConverting[resultFileUuid].projectName);
      const resultFileName = toJS(this.docsInConverting[resultFileUuid].fileName);

      if (result.status === 'fulfilled') {
        const { value } = result;

        if (value.status === ConversionStatus.FINISHED) {
          const { result } = value;
          const resultUuid = result?.uuid;

          this.conversions[resultFileUuid] = this.conversions[resultFileUuid].map((item) =>
            item.format === resultSelectedFormat
              ? {
                  ...item,
                  linkTo: buildLink(resultUuid),
                  status: CONVERSION_STATUS.SUCCESS,
                }
              : item
          );

          this.formats[resultFileUuid] = this.formats[resultFileUuid].filter(
            ({ value }) => value !== resultSelectedFormat
          );
        }

        if (value.status === ConversionStatus.FAILED) {
          this.conversions[resultFileUuid] = this.conversions[resultFileUuid].map((item) =>
            item.format === resultSelectedFormat
              ? {
                  ...item,
                  linkTo: undefined,
                  status: CONVERSION_STATUS.FAILED,
                }
              : item
          );
        }
      }

      if (result.status === 'rejected') {
        if (!result.reason?.isCancel) {
          this.conversions[resultFileUuid] = this.conversions[resultFileUuid].map((item) =>
            item.format === this.selectedFormat
              ? {
                  ...item,
                  linkTo: undefined,
                  status: CONVERSION_STATUS.FAILED,
                }
              : item
          );

          this.convertError = {
            message: result.reason?.message || 'Unknown Error',
            code: result.reason?.status || 'Unknown Code',
          };
        }
      }

      if (this.сurrentConvertedFile !== uuid) {
        message.info(
          `The conversion of the "${resultFileName}" file in the "${resultProjectName}" project is finished.`
        );
      }

      delete this.docsInConverting[resultFileUuid];
    } catch (/** @type {any} */ error) {
      this.convertError = {
        message: error.message,
        code: error.code,
      };
    } finally {
      this.selectedFormat = DEFAULT_SELECT_VALUE;
    }
  }

  /** @param {string} uuid */
  *loadAllFormats(uuid) {
    this.formatsError = null;

    if (this.formats[uuid] !== undefined) return;

    this.isLoadingFormats = true;

    try {
      const { format } = yield conversionInfo({ uuid }, this.restApiOpts);

      if (format.conversionFormats.length) {
        const allFormats = format.conversionFormats.map(
          (/** @type {{ name: string }} */ { name }) => ({
            value: name,
            label: name.toUpperCase(),
          })
        );

        this.formats[uuid] = getAvailableFormats(allFormats, toJS(this.usedFormats));
      } else {
        this.formatsError = {
          message: ERROR_MESSAGE.NO_FORMATS,
          code: undefined,
        };
      }
    } catch (/** @type {any} */ error) {
      if (error.status !== RESPONSE_CODES.FORBIDDEN) {
        this.formatsError = {
          message: ERROR_MESSAGE.GENERAL_ERROR,
          code: error.status ? error.status : undefined,
        };
      }
    } finally {
      this.isLoadingFormats = false;
    }
  }

  /** @returns {Conversion[]} */
  get sortedConversions() {
    const uuid = this.сurrentConvertedFile;
    if (!this.conversions[uuid]) return [];

    const { SUCCESS, FAILED, LOADING } = CONVERSION_STATUS;

    const conversions = this.conversions[uuid].reduce(
      (/** @type {{ [key: string]: Conversion[] }} */ conversions, conversion) => {
        conversions[conversion.status].push(conversion) && conversions;
        return conversions;
      },
      { [SUCCESS]: [], [FAILED]: [], [LOADING]: [] }
    );

    const sortedConversions = [
      ...conversions[SUCCESS].sort((a, b) => a.format.localeCompare(b.format)),
      ...conversions[FAILED],
      ...conversions[LOADING],
    ];

    return toJS(sortedConversions);
  }

  get isAnyAvailableFormat() {
    return !!this.formats[this.сurrentConvertedFile]?.length;
  }

  /** @param {string} value */
  setCurrentConvertedFile(value) {
    this.сurrentConvertedFile = value;
  }

  /** @param {string} value */
  setSelectedFormat(value) {
    this.selectedFormat = value;
  }

  reset() {
    super.reset();
    this.сurrentConvertedFile = '';
    this.docsInConverting = {};
    this.usedFormats = [];
    this.formats = {};
    this.selectedFormat = DEFAULT_SELECT_VALUE;
    this.conversions = {};
    this.isConverting = false;
    this.isLoadingFormats = false;
    this.convertError = null;
    this.formatsError = null;
  }
}
