// @ts-check
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { DropDownContext } from './DropDown';
import { useFadeIn } from '../../hooks/useFadeIn';

/** @enum {string} */
export const DropDownPositionEnum = {
  RIGHT: 'right',
  LEFT: 'left',
  TOP: 'top',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
};

/**
 * @type {React.FC<{
 *   className?: string;
 *   dataCy?: string;
 *   children: React.ReactNode;
 *   position?: string;
 * }>}
 */
export const DropDownMenu = ({
  className,
  dataCy,
  children,
  position = DropDownPositionEnum.LEFT,
}) => {
  const { isOpen } = useContext(DropDownContext);
  const { isRender, isVisible, onAnimationEnd } = useFadeIn(isOpen);

  if (!isRender) return null;

  return (
    <DropDownMenuContainer
      className={className}
      $position={position}
      $isVisible={isVisible}
      onTransitionEnd={onAnimationEnd}
    >
      <Content data-cy={dataCy}>{children}</Content>
    </DropDownMenuContainer>
  );
};

/**
 * @typedef {import('styled').StyledComponent<
 *   'button',
 *   { $isVisible: boolean; $position: string }
 * >} DropDownMenuContainerProps
 */
const DropDownMenuContainer = /** @type {DropDownMenuContainerProps} */ (styled.div)(
  ({ $isVisible = false, $position }) => css`
    width: max-content;
    min-width: 130px;

    position: absolute;
    top: calc(100% + 8px);
    left: -5px;
    transform: translateY(${$isVisible ? 0 : '-5px'});

    opacity: ${$isVisible ? 1 : 0};
    transition: opacity 0.2s ease, transform 0.2s ease;

    z-index: 100;

    ${$position === DropDownPositionEnum.RIGHT &&
    css`
      left: auto;
      right: -5px;
    `}

    ${$position === DropDownPositionEnum.TOP &&
    css`
      top: -5px;
      left: 0;
      transform: translateY(-100%);
    `}

    ${$position === DropDownPositionEnum.BOTTOM_CENTER &&
    css`
      top: auto;
      left: 50%;
      bottom: -5px;
      transform: translate(-50%, 100%);
    `}

        ${$position === DropDownPositionEnum.BOTTOM_RIGHT &&
    css`
      top: auto;
      bottom: -5px;
      left: auto;
      right: -25px;
      transform: translate(25px, 100%);
    `}
  `
);

const Content = styled.div`
  padding: 5px;
  background: #ffffff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: grid;
  grid-gap: 2px;
`;
