import { action, observable, makeObservable } from 'mobx';

const defaultTitleTemplate = '%s | Uploadcare';

export class PageStore {
  titleTemplate = defaultTitleTemplate;

  constructor() {
    makeObservable(this, {
      titleTemplate: observable,
      setTitleTemplate: action,
    });
  }

  setTitleTemplate(template) {
    this.titleTemplate = template;
  }

  reset() {
    this.titleTemplate = defaultTitleTemplate;
  }
}
