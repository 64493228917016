// @ts-check
import React from 'react';
import { annoyingMessageType } from './AnnoyingMessageTemplate';
import { UpgradeButton } from '../UpgradeButton';
import { BaseLink } from '../Link/BaseLink';

export const accountUnpaidMessageOptions = {
  message: 'We weren’t able to process the payment for your Uploadcare subscription',
  type: annoyingMessageType.ERROR,
};

export const hitUploadLimitsMessageOptions = {
  button: {
    component: UpgradeButton,
    label: 'Upgrade plan',
    dataAnalytics: 'btn_reached-limits_billing',
    ghost: false,
    withoutIcon: true,
  },
  message: 'You’ve reached your usage limits',
  type: annoyingMessageType.ERROR,
};

export const marketingBannerMessage = {
  id: 'video-cdn-link',
  isClosable: true,
  type: annoyingMessageType.INFO,
  message: (
    <>
      Meet&nbsp;<b>Uploadcare Video CDN! &nbsp;</b>
      Easily enable&nbsp;<b>native video streaming</b>&nbsp;for your website or app.&nbsp;
      <BaseLink
        href="/cdn/video-cdn/?utm_source=dashboard&utm_medium=thunderbolt&utm_campaign=video_cdn"
        target="_blank"
        data-analytics="link_annoying-message_video-cdn-page"
        className="text-white underline hover:no-underline focus:!bg-transparent"
      >
        <b>Learn more</b>
      </BaseLink>
      &nbsp; 🎉
    </>
  ),
};
