// @ts-check
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { MODALS_ENUM, useModal } from '~/components/Modal';
import { Button, ButtonSizeEnum } from '@uc-common/button';
import { useStores } from '~/stores';
import { useErrorHandler } from '~/hooks/useErrorHandler';

/**
 * @type {React.FC<
 *   import('@uc-common/button/Button.types').ButtonProps &
 *     React.ButtonHTMLAttributes<HTMLButtonElement>
 * >}
 */
export const UpgradeFromFreePlanButton = observer(
  ({
    themeType,
    customTheme,
    ghost,
    transparent,
    size = ButtonSizeEnum.SMALL,
    block,
    long,
    isLoading,
    icon,
    children,
    alignmentText = 'start',
    as,
    to,
    className,
    onClick,
    ...props
  }) => {
    const { accountPlanStore, accountStore } = useStores();
    const isFreeAccount = accountStore.isFree;
    const planToUpgrade = accountPlanStore.nextPlan;
    const handleError = useErrorHandler();

    const [showSelectPlanModal] = useModal(
      MODALS_ENUM.SELECT_PLAN,
      {},
      {
        isFullScreenModal: true,
      }
    );

    const handleClick = (
      /** @type {import('react').MouseEvent<HTMLButtonElement, MouseEvent>} */ event
    ) => {
      onClick?.(event);
      showSelectPlanModal();
    };

    useEffect(() => {
      // @ts-ignore
      isFreeAccount && accountPlanStore.fetchAvailablePlans().catch(handleError);
    }, [isFreeAccount, accountPlanStore, handleError]);

    if (!isFreeAccount || !planToUpgrade) return null;

    return (
      <Button
        themeType={themeType}
        customTheme={customTheme}
        ghost={ghost}
        transparent={transparent}
        size={size}
        block={block}
        long={long}
        alignmentText={alignmentText}
        isLoading={isLoading}
        as={as}
        to={to}
        icon={icon}
        onClick={handleClick}
        className={className}
        {...props}
      >
        {children ?? 'Upgrade plan'}
      </Button>
    );
  }
);
