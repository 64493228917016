// @ts-check
import React, { useEffect, useRef } from 'react';
import { useScrolling } from 'react-use';
import ReactTooltip from 'react-tooltip';

import { StyledDialog } from './styles/ModalContentContainer.styles';

/**
 * @type {React.FC<{
 *   className?: string;
 *   isDisabled?: boolean;
 *   children: React.ReactNode;
 *   [key: string]: any;
 * }>}
 */
export const ModalContentContainer = ({ className, isDisabled = false, children, ...props }) => {
  const contentRef = useRef(null);
  const scrolling = useScrolling(contentRef);

  useEffect(() => {
    ReactTooltip.hide();
  }, [scrolling]);

  return (
    <StyledDialog ref={contentRef} className={className} $isDisabled={isDisabled} {...props}>
      {children}
    </StyledDialog>
  );
};
