import styled, { css } from 'styled-components';

export const ModalCloseButtonContainer = styled.button(
  ({ theme: { tablet } }) => css`
    position: absolute;
    top: 13px;
    right: 13px;
    padding: 9px;
    border-radius: 4px;
    display: inline-flex;
    color: #495560;
    transition: all 0.2s ease-in-out;
    z-index: 10;

    svg {
      width: 14px;
      height: 14px;
    }

    ${tablet()} {
      &:hover {
        color: #748a98;
        background-color: #dadee7;
        transition: all 0.2s ease-in-out;
      }

      &:active {
        color: #46555e;
        background-color: #cdd3df;
        transition: all 0.2s ease-in-out;
      }

      &:focus-visible {
        outline-offset: 0;
        outline: 2px solid rgba(21, 124, 252, 0.5);
        transition: all 0.2s ease-in-out;
      }
    }
  `
);
