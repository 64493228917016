import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Header } from '~/components/Header';
import { AppContentLoader } from '~/components/AppContentLoader';

export const BasicLayout = () => (
  <Suspense fallback={<AppContentLoader />}>
    <Header />
    <Outlet />
  </Suspense>
);
