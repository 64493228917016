import styled from 'styled-components';
import { annoyingMessageType } from './AnnoyingMessageTemplate';
import { ModalCloseButton } from '../../Modal/components/ModalCloseButton/ModalCloseButton';

/** @type {styled.div & { $type: keyof typeof annoyingMessageTypeEnum }} */
export const AnnoyingMessageTemplateContainer = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px 75px;
  position: relative;

  background: ${({ $type }) => {
    switch ($type) {
      case annoyingMessageType.WARNING:
        return '#FFFACA';
      case annoyingMessageType.ERROR:
        return '#FFE3D4';
      case annoyingMessageType.INFO:
        return '#3771ff';
      default:
        return '#FFFACA';
    }
  }};

  &:not(:last-child) {
    border-bottom: 1px solid #ffffff;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    font-size: 13px;
    font-weight: 500;
    line-height: 154%;
    color: ${({ $type }) => {
      switch ($type) {
        case annoyingMessageType.INFO:
          return '#FFFFFF';
        default:
          return '#000000';
      }
    }};

    text-align: center;
    text-wrap: nowrap;

    & svg {
      margin-right: 8px;
      flex-shrink: 0;
    }
  }

  button span,
  a span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    svg {
      width: 9px;
      height: 9px;
      margin-top: 3px;
    }
  }

  & button,
  & a {
    &:visited:focus {
      background-color: #000000;
    }

    &:focus {
      background-color: #000000;
    }
  }
`;

export const CloseAnnoyingMessage = styled(ModalCloseButton)`
  position: absolute;
  top: 0;

  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 12px;
    color: #ffffff;
    transition: color 0.2s ease-in-out;
  }

  &:hover,
  &&&:focus {
    outline: none;
    background-color: transparent;

    svg {
      color: #c7c7c6;
      transition: color 0.2s ease-in-out;
    }
  }
`;
