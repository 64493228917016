/* eslint-disable camelcase */
import { flow, observable, makeObservable } from 'mobx';
import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';
import { dataLikeArrayMixin } from '../mixins';

export const WEBHOOK_ACTUAL_VERSIONS = ['0.7'];
export const WEBHOOK_ALL_VERSIONS = ['0.5', '0.6', '0.7'];

export const WEBHOOK_LATEST_VERSION = WEBHOOK_ACTUAL_VERSIONS[WEBHOOK_ACTUAL_VERSIONS.length - 1];

export const WebhookEventEnum = {
  UPLOADED: 'file.uploaded',
  INFECTED: 'file.infected',
  STORED: 'file.stored',
  DELETED: 'file.deleted',
  INFO_UPDATED: 'file.info_updated',
};

/** @typedef {import('dashboard-api-types').webhook} Webhook */
export class ProjectWebHooksStore extends dataLikeArrayMixin(BaseStore) {
  /** @type {Webhook[]} */
  data = [];

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      addWebHook: flow,
      removeWebHook: flow,
      updateWebhookStatus: flow,
      updateWebHook: flow,
      fetchData: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/webhooks/`;
  }

  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/', config);
      this.data = res.data.results;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }

  *addWebHook({ target_url, signing_secret, version, event }) {
    this.isLoading = true;

    try {
      const res = yield this.api.post('/', {
        target_url,
        signing_secret,
        version,
        event,
      });
      this.data.push(res.data);
      return res;
    } finally {
      this.isLoading = false;
    }
  }

  *removeWebHook(id) {
    this.isLoadingMap[id] = true;

    try {
      const res = yield this.api.delete(`/${id}/`);
      this.removeItemFromDataByField(id);
      message.info('Webhook was deleted.');
      return res;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap[id] = false;
    }
  }

  *updateWebhookStatus(id, isActive, targetUrl) {
    this.isLoadingMap[id] = true;

    try {
      const res = yield this.api.put(`/${id}/`, { is_active: isActive });
      this.updateItemInDataByField(res.data);
      message.info(`Webhook '${targetUrl}' was ${isActive ? 'enabled' : 'disabled'}.`);
      return res;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap[id] = false;
    }
  }

  *updateWebHook(id, { target_url, signing_secret }) {
    this.isLoadingMap[id] = true;

    try {
      const res = yield this.api.put(`/${id}/`, {
        target_url,
        signing_secret,
      });
      this.updateItemInDataByField(res.data);
      return res;
    } finally {
      this.isLoadingMap[id] = false;
    }
  }
}
