// @ts-check
export const BEACON_ID = 'js-beacon-script';

/** @param {string} key */
export const initBeacon = (key) => {
  if (!window.Beacon) {
    return;
  }

  window.Beacon('init', key);
};

/** @param {import('./Beacon').User} user */
export const identifyUser = (user = null) => {
  if (!window.Beacon) {
    return;
  }

  if (user) {
    window.Beacon('identify', {
      email: user.email,
      ...(user.signature && { signature: user.signature }),
      ...(user.name && { name: user.name }),
    });

    window.Beacon('session-data', {
      ...(typeof user.isNew === 'string' && { 'Is new account': user.isNew }),
    });
  } else {
    window.Beacon('session-data', {
      'Is not logged in': 'yes',
    });
  }
};
