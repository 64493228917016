import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { RoutesEnum } from '../../routes';
import { useStores } from '../../../../stores/index';

export const RequireNonAuth = observer(() => {
  const { accountStore } = useStores();
  const { isAuthenticated } = accountStore;

  return isAuthenticated ? <Navigate to={RoutesEnum.HOME} /> : <Outlet />;
});
