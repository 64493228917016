import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { getGtmCode } from './script';

/** @param {{ containerId: string }} gtmProps */
export const GTM = ({ containerId }) => {
  const { scriptCode, noScriptCode } = getGtmCode(containerId);

  useEffect(() => {
    if (!containerId) return;

    const noScript = document.createElement('noscript');
    noScript.innerHTML = noScriptCode;
    document.body.prepend(noScript);
  }, [containerId, noScriptCode]);

  return containerId ? (
    <Helmet>
      <script>{scriptCode}</script>
    </Helmet>
  ) : null;
};
