// @ts-check
import { useCallback, useEffect } from 'react';
import { useServerValidation } from '@uc-common/use-server-validation';

import { message } from '~/components/Toaster';
import { RoutesEnum } from '~/App';
import { useStores } from '~/stores';
import { useRedirect } from '../useRedirect';

export const ErrorKeyEnum = {
  NON_FIELD_ERRORS: 'non_field_errors',
  ALL_FIELD_ERRORS: '__all__',
  MFA_REQUIRED: 'mfa_required',
};

export const SERVER_ERROR_TYPE = 'SERVER_ERROR_TYPE';

// those errors should not be injected into react-hook-form errors
// because they aren't related to any specific field
const IGNORE_FIELDS = [ErrorKeyEnum.ALL_FIELD_ERRORS, ErrorKeyEnum.NON_FIELD_ERRORS];

/**
 * @param {any} _
 * @param {Error} error
 */
const defaultErrorHandler = (_, error) => message.error(error?.message);

/**
 * @param {Function} [setError] Any error callback, e.g. setError from react-hook-form. If there is
 *   no form, you can not specify any callback, and default one will be used (message.error).
 * @param {Error | null} [error] Error object.
 * @returns {(error: unknown) => void} Error handler
 */
export const useErrorHandler = (setError = defaultErrorHandler, error = null) => {
  const { accountStore } = useStores();
  const redirect = useRedirect();
  const { isAuthenticated } = accountStore ?? {};

  const { handleError: handleServerValidation } = useServerValidation(
    setError,
    // @ts-ignore - {Error} -> {Error | null}
    error,
    IGNORE_FIELDS
  );

  const handleAccessDenied = useCallback(
    (/** @type {{ data: { errors: { [key: string]: string[] }; code: string } }} */ response) => {
      accountStore.setIsUnknownUser(false);
      const errorMessage = response.data?.errors?.[ErrorKeyEnum.NON_FIELD_ERRORS][0] ?? '';

      switch (errorMessage) {
        case 'Authentication credentials were not provided.':
          if (isAuthenticated) {
            accountStore.setIsLogout(true);
            redirect(RoutesEnum.SESSION_EXPIRED, [], true);
            break;
          }

          accountStore.setIsUnknownUser(true);
          redirect(
            RoutesEnum.SIGN_IN,
            [
              RoutesEnum.SIGN_UP,
              RoutesEnum.SIGN_IN_SSO,
              RoutesEnum.TOKEN_AUTH,
              RoutesEnum.TEAM_INVITATION,
              RoutesEnum.SESSION_EXPIRED,
            ],
            true
          );
          break;
        case 'Account is blocked.':
          accountStore.setIsBlocked(true);
          redirect(RoutesEnum.BLOCKED_ACCOUNT, [], true);
          break;
        case 'Please, confirm your user email first':
          redirect(RoutesEnum.SIGN_UP_SUCCESS, [RoutesEnum.APPROVE_EMAIL], true);
          break;
        default:
          if (response.data.code === ErrorKeyEnum.MFA_REQUIRED) {
            redirect(RoutesEnum.MFA_VERIFICATION, [], true);
          }
      }
    },
    [redirect, accountStore, isAuthenticated]
  );

  const handleError = useCallback(
    // @ts-ignore
    (error) => {
      const response = error?.response ?? {};
      const { status } = response;

      if (status === 403) {
        handleAccessDenied(response);
        return;
      }

      try {
        if (
          error.response?.data?.errors?.[ErrorKeyEnum.NON_FIELD_ERRORS] &&
          setError === defaultErrorHandler
        ) {
          // If setError is not react-hook-form's method, display common API errors by default.
          setError(null, {
            message: error.response?.data?.errors?.[ErrorKeyEnum.NON_FIELD_ERRORS][0],
          });
        } else {
          handleServerValidation(error);
        }
      } catch (e) {
        if (e instanceof Error) {
          if (e.message === 'Network Error') {
            // Axios Network Error: https://github.com/axios/axios/blob/main/lib/adapters/xhr.js#L120
            return setError(null, new Error('No internet connection.'));
          }

          // Do not use setError from react-hook-form, use default toast based handler.
          defaultErrorHandler(null, e);
        } else {
          throw e;
        }
      }
    },
    [handleServerValidation, handleAccessDenied, setError]
  );

  useEffect(() => {
    error && handleError(error);
  }, [error, handleError]);

  return handleError;
};
