import { ReactComponent as StarIcon } from './assets/star.svg';
import { ReactComponent as FileIcon } from './assets/file.svg';
import { ReactComponent as ImageProcessingIcon } from './assets/image-proc.svg';
import { ReactComponent as DashboardIcon } from './assets/dashboard.svg';
import { ReactComponent as KeyIcon } from './assets/key.svg';
import { ReactComponent as WebhooksIcon } from './assets/webhooks.svg';
import { ReactComponent as ApiIcon } from './assets/api.svg';
import { ReactComponent as SettingsIcon } from './assets/settings.svg';
import { ReactComponent as QuickstartIcon } from './assets/quick-start.svg';
import { ReactComponent as BillingIcon } from './assets/billing.svg';
import { ReactComponent as WorkspaceIcon } from './assets/workspace.svg';
import { ReactComponent as ProfileIcon } from './assets/profile.svg';
import { ReactComponent as FileUploaderIcon } from './assets/file-uploader.svg';

export const icons = {
  getStarted: StarIcon,
  usage: DashboardIcon,
  files: FileIcon,
  imageProcessing: ImageProcessingIcon,
  keys: KeyIcon,
  webhooks: WebhooksIcon,
  accessLogs: ApiIcon,
  settings: SettingsIcon,
  quickstart: QuickstartIcon,
  billing: BillingIcon,
  workspace: WorkspaceIcon,
  profile: ProfileIcon,
  fileUploader: FileUploaderIcon,
};
