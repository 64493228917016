import styled, { css } from 'styled-components';

import { navigationItemStyles } from '~/components/Navigation/styles/Navigation.styles';
import { BaseLink } from '~/components/Link';
import { customScrollbar } from '@uc-common/utils-styles/custom-scrollbar';
import { ProjectAvatar } from '../../ProjectAvatar';

export const ProjectDropdownContainer = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const ProjectDropdownIcon = styled.svg`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const ProjectDropdownToggleText = styled.span`
  max-width: 145px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProjectDropdownMenu = styled.div(
  ({ $isShow = false }) => css`
    --menu-offset: 10px;

    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    padding: 5px;
    background: #ffffff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    opacity: ${$isShow ? 1 : 0};
    z-index: 100;
    pointer-events: ${$isShow ? 'auto' : 'none'};
    transition: opacity 0.3s ease;
  `
);

export const ProjectDropdownLink = styled(BaseLink)(
  ({ $isSelected = false }) => css`
    border-radius: 10px;

    ${navigationItemStyles};
    ${$isSelected &&
    css`
      background: rgba(55, 113, 255, 0.06);

      ${ProjectDropdownToggleText} {
        color: #3771ff;
      }
    `}

    &:hover,
    &:focus {
      ${ProjectDropdownToggleText} {
        color: #000000;
      }
    }
  `
);

export const ProjectDropdownToggle = styled(ProjectDropdownLink)(
  ({ $isActive = false }) => css`
    border-radius: 8px;
    pointer-events: ${$isActive ? 'none' : 'auto'};
    padding: 5px 15px 5px 6px;
    width: calc(100% - 10px);

    ${ProjectDropdownToggleText} {
      font-size: 14px;
      color: #000000;
    }

    ${ProjectDropdownIcon} {
      opacity: 0.4;
      transition: opacity 0.3s ease;
    }

    &:hover,
    &:focus {
      ${ProjectDropdownIcon} {
        opacity: 1;
      }
    }
  `
);

export const ProjectDropdownToggleAvatar = styled(ProjectAvatar)`
  --gradient-size: 26px;
  margin-right: 8px;
`;

export const ProjectDropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid #f1f1f1;

  ${customScrollbar('#e9eaec', 'transparent', 'y')}
`;

export const ProjectDropdownHeading = styled.h4`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.67;
  color: #7f878d;
  margin-left: 20px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }
`;

export const ProjectDropdownProjects = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: max-content;
  grid-gap: 2px;
  margin-top: 3px;

  &:first-child {
    margin-top: 0;
  }
`;
