import styled from 'styled-components';

export const AppContentLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
  height: max-content;
`;

export const AppContentLoaderDot = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 7px;
  background-color: #3771ff;
  border-radius: 50px;

  animation-name: pulse;
  animation-duration: 0.8s;
  animation-direction: alternate-reverse;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  &:nth-child(2) {
    animation-delay: 0.25s;
  }

  &:nth-child(3) {
    animation-delay: 0.5s;
  }

  &:last-child {
    margin-right: 0;
  }

  @keyframes pulse {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
`;

export const AppContentLoaderDots = styled.div`
  display: flex;
  align-items: center;
`;
