// @ts-check
import React from 'react';

import { ReactComponent as CloseIcon } from './assets/cross.svg';
import { ModalCloseButtonContainer } from './styles/ModalCloseButton.styles';

/**
 * @type {React.FC<{
 *   onClick?: (event: import('event').EventFor<'button', 'onClick'>) => void;
 *   [key: string]: any;
 * }>}
 */
export const ModalCloseButton = ({ onClick, ...props }) => (
  <ModalCloseButtonContainer
    data-cy="btn-modal-close"
    aria-label="Close modal"
    onClick={onClick}
    {...props}
  >
    <CloseIcon />
  </ModalCloseButtonContainer>
);
