// @ts-check
import React, { forwardRef } from 'react';
import FocusLock from 'react-focus-lock';

import { ModalOverlay, ModalContainer, ModalAnimatedDiv } from './styles/Modal.styles';

/**
 * Position for ModalOverlay component
 *
 * @typedef {{
 *   top?: string;
 *   right?: string;
 *   bottom?: string;
 *   left?: string;
 * }} ModalPosition
 */

/**
 * @typedef {object} ModalConfig
 * @property {ModalPosition} [position] - Position for ModalOverlay component
 * @property {string} [className] - Class name for modal overlay
 * @property {() => void} [onHidden] - Callback on modal unmounted
 * @property {boolean} [removeScroll] - Remove scroll from body
 * @property {boolean} [isFullScreenModal] - Is full screen modal
 * @property {boolean} [isShowOverlay] - Is show overlay
 * @property {boolean} [hideOnOutsideClick] - Hide modal on outside click
 */

/**
 * @typedef {ModalConfig & {
 *   style: React.ComponentProps<import('@react-spring/web').AnimatedComponent<'div'>>['style'];
 *   children: React.ReactElement;
 * }} ModalProps
 */

/** @type {React.FC<ModalProps>} */
export const Modal = forwardRef(
  (
    {
      className,
      style,
      position,
      removeScroll = true,
      isFullScreenModal = false,
      isShowOverlay = true,
      children,
    },
    ref
  ) => (
    <FocusLock returnFocus>
      <ModalOverlay
        className={className}
        style={{
          opacity: style?.opacity,
          scale: isFullScreenModal ? style?.scale : 1,
        }}
        $position={position}
        $isFullScreenModal={isFullScreenModal}
        $isShowOverlay={isShowOverlay}
      >
        <ModalAnimatedDiv
          style={{
            transform: isFullScreenModal ? 'none' : style?.transform,
          }}
        >
          <ModalContainer ref={ref} enabled={removeScroll}>
            {children}
          </ModalContainer>
        </ModalAnimatedDiv>
      </ModalOverlay>
    </FocusLock>
  )
);
