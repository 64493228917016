import React from 'react';
import { Helmet } from 'react-helmet';

export const MobileViewport = () => {
  const CONTENT_WIDTH = 1260;
  const { width } = document.body.getBoundingClientRect();
  const viewportScale = width < CONTENT_WIDTH ? Number((width / CONTENT_WIDTH).toFixed(2)) : 1;

  return (
    <Helmet>
      <meta name="viewport" content={`width=device-width, initial-scale=${viewportScale}`} />
    </Helmet>
  );
};
