import { createGlobalStyle, css } from 'styled-components';

export const Vars = createGlobalStyle(
  ({ scrollBarWidth = 0 }) => css`
    :root {
      --header-height: 56px;
      --navigation-width: 249px;
      --content-width: 1280px;

      --border-grey: 1px solid #f1f2f3;
      --content-inset-padding: 22px;
      --content-inset-padding-top: 40px;
      --content-h-offset: 16px;

      --annoying-messages-height: 0px; /* calculating in AnnoyingMessages component */
      --scrollbar-width: ${scrollBarWidth}px;
    }
  `
);
