import styled from 'styled-components';

export const TrialUpgradeWidgetStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: rgba(120, 202, 113, 0.12);
  margin: 0 15px 15px 15px;
  padding: 10px;

  p {
    color: #000;
    font-size: 13px;
    font-weight: 600;
    line-height: 140%;
  }

  & > span {
    color: #495560;
    font-size: 13px;
    font-weight: 400;
    align-self: stretch;
    line-height: 140%;
  }

  button {
    margin-top: 10px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
    }
  }
`;
