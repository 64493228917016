import React from 'react';
import ReactTooltip from 'react-tooltip';
import { createGlobalStyle } from 'styled-components';
import { ReactComponent as QuestionIcon } from './assets/question.svg';
import { ReactComponent as InfoIcon } from './assets/info.svg';
import { ReactComponent as ProtectedIcon } from './assets/protected.svg';
import { Icon } from './Tooltip.styles';

export const TooltipGlobalStyles = createGlobalStyle`
  .tooltip {
    &&& { /* hack to override base styles */
      border-radius: 6px;
      color: rgb(38, 43, 47);
      padding: 14px 20px;
      font-size: 14px;
      line-height: 20px;
      opacity: 1;
      filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.09))
      drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.17));
      text-align: left;
      max-width: 360px;
      font-weight: normal;
      white-space: normal;
      &:after {
        display: none;
      }
    }

  }
`;

/** @param {{ className?: string } & import('react-tooltip').TooltipProps} props */
export const Tooltip = ({ className, ...props }) => (
  <div className={className}>
    <ReactTooltip
      className="tooltip"
      effect="solid"
      delayShow={100}
      backgroundColor="white"
      multiline
      {...props}
    />
  </div>
);

export const TooltipIconEnum = {
  INFO: 'info',
  QUESTION: 'question',
  PROTECTED: 'protected',
};

export const TooltipWithIcon = ({
  id,
  children,
  textClassName,
  className,
  type,
  noMargin,
  tooltipProps,
  iconComponent,
}) => {
  let IconComponent = null;

  if (!iconComponent && type) {
    // eslint-disable-next-line default-case
    switch (type) {
      case TooltipIconEnum.INFO:
        IconComponent = InfoIcon;
        break;
      case TooltipIconEnum.QUESTION:
        IconComponent = QuestionIcon;
        break;
      case TooltipIconEnum.PROTECTED:
        IconComponent = ProtectedIcon;
        break;
    }
  }

  return (
    <>
      <Icon
        as={iconComponent ?? IconComponent}
        $noMargin={noMargin}
        data-tip={children}
        data-for={id}
        className={className}
      />
      <Tooltip id={id} className={textClassName} {...tooltipProps}>
        {children}
      </Tooltip>
    </>
  );
};

export const QuestionTooltip = ({ ...props }) => (
  <TooltipWithIcon {...props} type={TooltipIconEnum.QUESTION} />
);

export const InfoTooltip = ({ ...props }) => (
  <TooltipWithIcon {...props} type={TooltipIconEnum.INFO} />
);
