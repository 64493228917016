// @ts-check
import React, { createContext, useContext, useMemo, useState } from 'react';

const ModalsContext = createContext(
  /**
   * @type {{
   *   activeModals: Map<
   *     string,
   *     import('~/components/Modal').MODALS_ENUM[keyof import('~/components/Modal').MODALS_ENUM]
   *   >;
   *   setActiveModals: React.Dispatch<React.SetStateAction<Map<any, any>>>;
   *   isModalActive: (modalName: string) => boolean;
   * } | null}
   */ (null)
);

/** @type {React.FC<{ children: React.ReactElement }>} */
export const ModalsProvider = ({ children }) => {
  const [activeModals, setActiveModals] = useState(() => new Map());

  const modalValues = useMemo(
    () => ({
      activeModals,
      setActiveModals,
      isModalActive: (/** @type {string} */ modalName) =>
        Array.from(activeModals.values()).some((modal) => modal.name === modalName),
    }),
    [activeModals, setActiveModals]
  );

  return <ModalsContext.Provider value={modalValues}>{children}</ModalsContext.Provider>;
};

export const useModalsContext = () => {
  const context = useContext(ModalsContext);

  if (!context) {
    throw new Error('useModalsContext must be used within a ModalsProvider');
  }

  return context;
};
