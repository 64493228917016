import { action, observable, reaction, makeObservable, flow } from 'mobx';
import { BaseStore } from '../base-store';

export class ProjectUploadsAuthStore extends BaseStore {
  /** @type {string | null} */
  signature = null;

  /** @type {string | null} */
  expire = null;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      signature: observable,
      expire: observable,
      resetSecureUploadsParams: action,
      fetchData: flow,
    });
  }

  onInit() {
    // subscribe to project property for sync signed uploads params
    reaction(
      () => this.appStore.stores.projectStore.project?.is_secure_upload_enabled,
      (isSecureUploadEnabled) => {
        if (!isSecureUploadEnabled) {
          this.resetSecureUploadsParams();
        }
      }
    );

    // update pubKey if we set new project
    reaction(
      () => this.project,
      (project) => {
        this.pubKey = project ? project.pub_key : null;
      }
    );
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/uploads/auth/`;
  }

  get isExpired() {
    return this.expire < Math.floor(Date.now() / 1000);
  }

  *fetchData() {
    if (!this.isExpired) return;

    this.isLoading = true;

    try {
      const res = yield this.api.post('/');
      this.signature = res.data.signature;
      this.expire = res.data.expire;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }

  resetSecureUploadsParams() {
    this.signature = null;
    this.expire = null;
  }

  reset() {
    super.reset();
    this.resetSecureUploadsParams();
  }
}
