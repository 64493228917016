import React from 'react';
import { AppStore } from './app-store';

const StoresContext = React.createContext({});

export const StoresProvider = ({ children }) => {
  const appStore = new AppStore();
  const appChildrenStores = {};
  // hoisting children stores
  Object.keys(appStore.stores).forEach((key) => {
    appChildrenStores[key] = appStore.stores[key];
  });

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const stores = {
    appStore,
    ...appChildrenStores,
  };

  return <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>;
};

/**
 * Hook for getting stores.
 *
 * @returns {{
 *   accountBillingInfoStore: import('./account/account-billing-info-store').AccountBillingInfoStore;
 *   accountCardStore: import('./account/account-card-store').AccountCardStore;
 *   accountPaymentsUpcomingStore: import('./account/account-payments-upcoming-store').AccountPaymentsUpcomingStore;
 *   accountPlanStore: import('./account/account-plan-store').AccountPlanStore;
 *   accountStatsStore: import('./account/account-stats-store').AccountStatsStore;
 *   accountStore: import('./account/account-store').AccountStore;
 *   authStore: import('./auth/auth-store').AuthStore;
 *   billingStore: import('./account/billing-store').BillingStore;
 *   mfaStore: import('./auth/mfa-store').MfaStore;
 *   moderationCategories: import('./project/moderation-categories').ModerationCategories;
 *   pageStore: import('./page-store').PageStore;
 *   projectAnalyticsStore: import('./project/project-analytics-store').ProjectAnalyticsStore;
 *   projectApiLogsStore: import('./project/project-api-logs-store').ProjectApiLogsStore;
 *   projectApiLogsSummaryStore: import('./project/project-api-logs-summary-store').ProjectApiLogsSummaryStore;
 *   projectConvertDocumentsStore: import('./project/project-convert-documents-store').ProjectConvertDocumentsStore;
 *   projectDemoStore: import('./project/project-demo-store').ProjectDemoStore;
 *   projectFileStore: import('./project/project-file-store').ProjectFileStore;
 *   projectFilesModeration: import('./project/project-files-moderation').ProjectFilesModeration;
 *   projectFilesStore: import('./project/project-files-store').ProjectFilesStore;
 *   projectS3BackupStore: import('./project/project-s3-backup-store').ProjectS3BackupStore;
 *   projectS3ForeignBucketStore: import('./project/project-s3-foreign-bucket-store').ProjectS3ForeignBucketStore;
 *   projectS3TargetsStore: import('./project/project-s3-targets-store').ProjectS3TargetsStore;
 *   projectStore: import('./project/project-store').ProjectStore;
 *   projectsListStore: import('./project/projects-list-store').ProjectsListStore;
 *   projectTeamStore: import('./project/project-team-store').ProjectTeamStore;
 *   projectUploadsAuthStore: import('./project/project-uploads-auth-store').ProjectUploadsAuthStore;
 *   projectWebHooksStore: import('./project/project-web-hooks-store').ProjectWebHooksStore;
 *   projectWhitelistStore: import('./project/project-whitelist-store').ProjectWhitelistStore;
 *   projectMimeTypesStore: import('./project/project-mime-types-store').ProjectMimeTypesStore;
 *   projectForeignBucketStore: import('./project/project-foreign-bucket-store').ProjectForeignBucketStore;
 * }}
 */
export const useStores = () => {
  const stores = React.useContext(StoresContext);
  if (!stores) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return stores;
};
