import { useEffect, useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoutesEnum, SettingTabEnum } from '~/App/index';
import { useStores } from '~/stores';
import { noCache } from '~/stores/base-store';
import { message } from '~/components/Toaster';

export const JoinSharedProject = () => {
  const { projectsListStore, projectTeamStore, projectStore } = useStores();
  const navigate = useNavigate();

  const joinProject = useCallback(
    async (token) => {
      await projectTeamStore.acceptInvitation(token);
      await projectsListStore.fetchData({ config: noCache });
    },
    [projectTeamStore, projectsListStore]
  );

  const getJoinedProjectName = (pubKey) => {
    const joinedProject = projectsListStore.data.find((project) => project.pub_key === pubKey);
    const projectName = joinedProject?.name;
    return projectName ? `"${projectName}"` : '';
  };

  useEffect(() => {
    projectTeamStore.checkPendingInvitation();

    if (projectTeamStore.pendingInvitation) {
      const { pubKey, token } = projectTeamStore.pendingInvitation;
      projectStore.setPubKey(pubKey);

      joinProject(token)
        .then(() => {
          const projectTeamPath = generatePath(`${RoutesEnum.PROJECT_SETTINGS}*`, {
            pubKey,
            '*': `#${SettingTabEnum.TEAM}`,
          });
          navigate(projectTeamPath);
          message.success(`You successfully joined the ${getJoinedProjectName(pubKey)} project`);
        })
        .finally(projectTeamStore.removePendingInvitation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
