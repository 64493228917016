// @ts-check
import React from 'react';

import { Button, ButtonSizeEnum, ButtonTypeEnum } from '@uc-common/button';
import { ReactComponent as WarningIcon } from '~/icons/warning.svg';
import { BaseLink } from '~/components/Link';
import useLocalStorageState from 'use-local-storage-state';
import {
  AnnoyingMessageTemplateContainer,
  CloseAnnoyingMessage,
} from './AnnoyingMessageTemplate.styles';

export const annoyingMessageType = Object.freeze({
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
});

const CLOSED_ANNOYING_MESSAGES = 'closed-annoying-messages';

/**
 * @type {React.FC<{
 *   className?: string;
 *   id?: string;
 *   message: string;
 *   type: string;
 *   isClosable?: boolean;
 *   button?: {
 *     component?: Button;
 *     label: string;
 *     icon?: React.FC;
 *     to?: string;
 *     dataAnalytics: string;
 *     onClick?: () => void;
 *   };
 * }>}
 */
export const AnnoyingMessageTemplate = ({
  id,
  className,
  message,
  type,
  button,
  isClosable = false,
}) => {
  const { component, label, dataAnalytics, icon, ...buttonProps } = button ?? {};

  const [closedAnnoyingMessages, setClosedAnnoyingMessages] = useLocalStorageState(
    CLOSED_ANNOYING_MESSAGES,
    { defaultValue: [] }
  );

  // @ts-ignore
  const isMessageClosed = id ? closedAnnoyingMessages.includes(id) : false;

  const closeBanner = () => {
    if (id) {
      // @ts-ignore
      setClosedAnnoyingMessages((prevMessages) => [...prevMessages, id]);
    }
  };

  if (isMessageClosed) {
    return null; // Do not render the banner if it has been closed
  }

  if (button && !button.component) {
    button.component = Button;
  }

  return (
    <AnnoyingMessageTemplateContainer className={className} $type={type}>
      <p data-cy="text_annoying-banner_message">
        {type !== annoyingMessageType.INFO && <WarningIcon />}
        {message}
      </p>

      {button?.component && (
        <button.component
          themeType={ButtonTypeEnum.DARK}
          size={ButtonSizeEnum.EXTRA_SMALL}
          as={button?.to && BaseLink}
          data-analytics={button.dataAnalytics}
          {...buttonProps}
        >
          {label}
          {button.icon && <button.icon />}
        </button.component>
      )}

      {isClosable && (
        <CloseAnnoyingMessage
          aria-label="Close banner"
          data-analytics={`btn_close-annoying-banner_${id}`}
          onClick={closeBanner}
        />
      )}
    </AnnoyingMessageTemplateContainer>
  );
};
