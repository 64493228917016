// @ts-check
import { observable, makeObservable, flow, action, computed } from 'mobx';
import { BaseStore } from '../base-store';

/**
 * TODO: genereate from spec
 *
 * @typedef {unknown} FileApiLogs
 */

/**
 * TODO: genereate from spec
 *
 * @typedef {unknown} FileApiWebhookLogs
 */

/** @augments {BaseStore<never>} */
export class ProjectFileStore extends BaseStore {
  /** @type {FileApiLogs | null} */
  fileApiLogs = null;

  /** @type {FileApiWebhookLogs | null} */
  fileWebhooksLogs = null;

  /** @type {Record<string, boolean>} */
  isLoadingFileApiLogsMap = {};

  /** @type {Record<string, boolean>} */
  isLoadingFileWebhooksLogsMap = {};

  /** @type {string | null} */
  selectedUuid = null;

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      fetchFileApiLogs: flow,
      fetchFileWebhooksLogs: flow,
      fileApiLogs: observable,
      fileWebhooksLogs: observable,
      isLoadingFileApiLogsMap: observable,
      isLoadingFileWebhooksLogsMap: observable,
      selectedUuid: observable,
      setSelectedUuid: action,
      selectedFile: computed,
      isInfectedFile: computed,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/`;
  }

  /** @param {string} fileUuid */
  *fetchFileApiLogs(fileUuid) {
    this.isLoadingFileApiLogsMap[fileUuid] = true;

    try {
      /** @type {import('axios').AxiosResponse<FileApiLogs>} */
      const res = yield this.api.get(`/access_logs/?limit=5&file_id=${fileUuid}`);
      this.fileApiLogs = res.data;
      this.isLoadingFileApiLogsMap[fileUuid] = false;
    } catch (e) {
      this.isLoadingFileApiLogsMap[fileUuid] = false;
      this.processErrors(/** @type {Error} */ (e));
    }
  }

  /** @param {string} fileUuid */
  *fetchFileWebhooksLogs(fileUuid) {
    this.isLoadingFileWebhooksLogsMap[fileUuid] = true;

    try {
      /** @type {import('axios').AxiosResponse<FileApiWebhookLogs>} */
      const res = yield this.api.get(`/webhooks/logs/?limit=5&file_id=${fileUuid}`);
      this.fileWebhooksLogs = res.data;
      this.isLoadingFileWebhooksLogsMap[fileUuid] = false;
    } catch (e) {
      this.isLoadingFileWebhooksLogsMap[fileUuid] = false;
      this.processErrors(/** @type {Error} */ (e));
    }
  }

  /** @param {string | null} uuid */
  setSelectedUuid(uuid) {
    this.selectedUuid = uuid;
  }

  get selectedFile() {
    return this.appStore.stores.projectFilesStore.data.results?.find(
      ({ uuid }) => uuid === this.selectedUuid
    );
  }

  get isInfectedFile() {
    return this.selectedFile?.appdata?.uc_clamav_virus_scan?.data?.infected;
  }

  reset() {
    super.reset();
    this.fileApiLogs = null;
    this.fileWebhooksLogs = null;
    this.selectedUuid = null;
    this.isLoadingFileApiLogsMap = {};
    this.isLoadingFileWebhooksLogsMap = {};
  }
}
