import { ButtonTypeEnum } from './ButtonTypeEnum';

const defaultDisabledColors = {
  disabledBackgroundColor: 'rgba(2, 25, 30, 0.09);',
  disabledColor: '#C9CBD0',
};

const transparentDisabledColors = {
  disabledBackgroundColor: 'transparent',
  disabledColor: '#C9CBD0',
};

const lightTheme = {
  colors: {
    default: {
      defaultBackgroundColor: '#ffffff',
      defaultColor: '#000000',
      defaultBorderColor: '#E2E2E5',

      hoverBackgroundColor: '#F6F6F6',
      hoverColor: '#000000',
      hoverBorderColor: '#E2E2E5',

      focusBackgroundColor: '#F1F1F1',
      focusColor: '#000000',
      focusBorderColor: '#D7D7DB',

      disabledBackgroundColor: '#ffffff',
      disabledBorderColor: '#e7e7e7',
      disabledColor: '#c0c0c0',
    },
    ghost: {
      defaultBackgroundColor: '#ffffff',
      defaultColor: '#808080',
      defaultBorderColor: '#d0d0d0',

      hoverBackgroundColor: '#ffffff',
      hoverColor: '#000000',
      hoverBorderColor: '#808080',

      focusBackgroundColor: '#ffffff',
      focusColor: '#000000',
      focusBorderColor: '#000000',

      disabledBackgroundColor: '#ffffff',
      disabledBorderColor: '#e7e7e7',
      disabledColor: '#c0c0c0',
    },
  },
};

const primaryTheme = {
  colors: {
    default: {
      defaultBackgroundColor: '#3771FF',
      defaultColor: '#ffffff',

      hoverBackgroundColor: '#0e54ff',
      hoverColor: '#ffffff',

      focusOutlineColor: '#b7ccff',
      focusBackgroundColor: '#0E54FF',
      focusColor: '#ffffff',

      activeBackgroundColor: '#0242DF',
      activeColor: '#ffffff',

      ...defaultDisabledColors,
    },
    ghost: {
      defaultBackgroundColor: '#E6EDFF',
      defaultColor: '#3771FF',

      hoverBackgroundColor: '#D6E1FF',
      hoverColor: '#3771FF',

      activeBackgroundColor: '#C4D4FF',
      activeColor: '#3771FF',

      focusOutlineColor: '#b7ccff',
      focusBackgroundColor: '#D6E1FF',
      focusColor: '#3771FF',

      ...defaultDisabledColors,
    },
    transparent: {
      defaultBackgroundColor: 'transparent',
      defaultColor: '#3771FF',

      hoverBackgroundColor: '#E6EDFF',
      hoverColor: '#3771FF',

      focusOutlineColor: '#b7ccff',
      focusBackgroundColor: 'transparent',
      focusColor: '#3771FF',

      activeBackgroundColor: '#D6E1FF',
      activeColor: '#3771FF',

      ...transparentDisabledColors,
    },
  },
};

const dangerTheme = {
  colors: {
    default: {
      defaultBackgroundColor: '#EE5567',
      defaultColor: '#ffffff',

      hoverBackgroundColor: '#F43950',
      hoverColor: '#ffffff',

      focusOutlineColor: 'rgba(244, 57, 80, 0.30)',
      focusBackgroundColor: '#F43950',
      focusColor: '#ffffff',

      activeBackgroundColor: '#E1263C',
      activeColor: '#FFFFFF',

      ...defaultDisabledColors,
    },
    ghost: {
      defaultBackgroundColor: '#FFE4E7',
      defaultColor: '#ED364C',

      hoverBackgroundColor: '#FFD2D7',
      hoverColor: '#ED364C',

      focusOutlineColor: '#fcc4cb',
      focusBackgroundColor: '#FFD2D7',
      focusColor: '#ED364C',

      activeBackgroundColor: '#FEBBC3',
      activeColor: '#ED364C',

      ...defaultDisabledColors,
    },
    transparent: {
      defaultBackgroundColor: 'transparent',
      defaultColor: '#ED364C',

      hoverBackgroundColor: '#FFE4E7',
      hoverColor: '#ED364C',

      focusOutlineColor: '#fcc4cb',
      focusBackgroundColor: 'transparent',
      focusColor: '#ED364C',

      activeBackgroundColor: '#FFD2D7',
      activeColor: '#ED364C',

      ...transparentDisabledColors,
    },
  },
};

const darkTheme = {
  colors: {
    default: {
      defaultBackgroundColor: '#000000',
      defaultColor: '#ffffff',

      hoverBackgroundColor: '#172B3E',
      hoverColor: '#ffffff',

      focusOutlineColor: '#b3bfcb',
      focusBackgroundColor: '#172B3E',
      focusColor: '#ffffff',

      activeBackgroundColor: '#091724',
      activeColor: '#ffffff',

      ...defaultDisabledColors,
    },
    ghost: {
      defaultBackgroundColor: '#f0f1f2',
      defaultColor: '#000000',

      hoverBackgroundColor: '#e8eaeb',
      hoverColor: '#000000',

      focusOutlineColor: '#b3bfcb',
      focusBackgroundColor: '#e8eaeb',
      focusColor: '#000000',

      activeBackgroundColor: '#dee0e2',
      activeColor: '#000000',

      ...defaultDisabledColors,
    },
    transparent: {
      defaultBackgroundColor: 'transparent',
      defaultColor: '#000000',

      hoverBackgroundColor: 'rgba(2, 25, 30, 0.06)',
      hoverColor: '#000000',

      focusOutlineColor: '#b3bfcb',
      focusBackgroundColor: 'transparent',
      focusColor: '#000000',

      activeBackgroundColor: 'rgba(2, 25, 30, 0.09)',
      activeColor: '#000000',

      ...transparentDisabledColors,
    },
  },
};

const transparentTheme = {
  colors: {
    default: {
      defaultBackgroundColor: 'rgba(243, 248, 255, 0.95)',
      defaultColor: '#000000',

      hoverBackgroundColor: '#FFFFFF',
      hoverColor: '#1A3753',

      focusOutlineColor: 'rgba(227, 238, 255, 0.6)',
      focusBackgroundColor: 'rgba(243, 248, 255, 0.95)',
      focusColor: '#000000',

      activeBackgroundColor: 'rgba(243, 248, 255, 0.92)',
      activeColor: '#1A3753',

      disabledBackgroundColor: 'rgba(235, 243, 255, 0.5)',
      disabledColor: '#42484E',
    },
    ghost: {
      defaultBackgroundColor: 'rgba(212, 229, 250, 0.15)',
      defaultColor: '#FFFFFF',

      hoverBackgroundColor: 'rgba(212, 229, 250, 0.2)',
      hoverColor: '#FFFFFF',

      focusOutlineColor: 'rgba(227, 238, 255, 0.3)',
      focusBackgroundColor: 'rgba(212, 229, 250, 0.15)',
      focusColor: '#FFFFFF',

      activeBackgroundColor: 'rgba(212, 229, 250, 0.25)',
      activeColor: '#FFFFFF',

      disabledBackgroundColor: 'rgba(212, 229, 250, 0.2);',
      disabledColor: '#565C62',
    },
    transparent: {
      defaultBackgroundColor: 'transparent',
      defaultColor: '#FFFFFF',

      hoverBackgroundColor: 'rgba(212, 229, 250, 0.15)',
      hoverColor: '#FFFFF',

      focusOutlineColor: 'rgba(227, 238, 255, 0.3)',
      focusBackgroundColor: 'transparent',
      focusColor: '#FFFFFF',

      activeBackgroundColor: 'rgba(212, 229, 250, 0.2)',
      activeColor: '#FFFFFF',

      disabledBackgroundColor: 'transparent',
      disabledColor: '#42484E',
    },
  },
};

const successTheme = {
  colors: {
    default: {
      defaultBackgroundColor: '#24B83C',
      defaultColor: '#fff',

      hoverBackgroundColor: '#1AA430',
      hoverColor: '#fff',

      focusOutlineColor: 'rgba(36, 184, 60, 0.30)',
      focusBackgroundColor: '#24B83C',
      focusColor: '#fff',

      activeBackgroundColor: '#129628',
      activeColor: '#fff',

      ...defaultDisabledColors,
    },
    ghost: {
      defaultBackgroundColor: 'rgba(36, 184, 60, 0.12)',
      defaultColor: '#24B83C',

      hoverBackgroundColor: 'rgba(36, 184, 60, 0.20)',
      hoverColor: '#24B83C',

      focusOutlineColor: 'rgba(36, 184, 60, 0.30)',
      focusBackgroundColor: 'rgba(36, 184, 60, 0.12)',
      focusColor: '#24B83C',

      activeBackgroundColor: 'rgba(36, 184, 60, 0.25)',
      activeColor: '#24B83C',

      ...defaultDisabledColors,
    },
    transparent: {
      defaultBackgroundColor: 'transparent',
      defaultColor: '#24B83C',

      hoverBackgroundColor: 'rgba(36, 184, 60, 0.20)',
      hoverColor: '#24B83C',

      focusOutlineColor: 'rgba(36, 184, 60, 0.30)',
      focusBackgroundColor: 'transparent',
      focusColor: '#24B83C',

      activeBackgroundColor: 'rgba(36, 184, 60, 0.25)',
      activeColor: '#24B83C',

      ...transparentDisabledColors,
    },
  },
};

export const themes = {
  [ButtonTypeEnum.DEFAULT]: lightTheme,
  [ButtonTypeEnum.PRIMARY]: primaryTheme,
  [ButtonTypeEnum.DANGER]: dangerTheme,
  [ButtonTypeEnum.DARK]: darkTheme,
  [ButtonTypeEnum.TRANSPARENT]: transparentTheme,
  [ButtonTypeEnum.SUCCESS]: successTheme,
};
