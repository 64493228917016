import React, { Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentWithNavigation, MainWithNavigation, Navigation } from '~/components/Navigation';
import { AnnoyingMessages } from '~/components/AnnoyingMessages';
import { JoinSharedProject } from '~/components/JoinSharedProject';
import { ScrollToTop } from '~/components/ScrollToTop';
import { Beacon } from '~/components/Beacon';
import { StickyHeaderContainer } from '~/components/StickyHeader';
import { UserMenu } from '~/components/UserMenu';
import { TooltipGlobalStyles } from '~/components/Tooltip';
import { AppContentLoader } from '~/components/AppContentLoader';
import { MobileViewport } from '~/components/Meta';

export const AuthorizedLayout = observer(({ children }) => {
  return (
    <>
      <MobileViewport />
      <AnnoyingMessages />
      <JoinSharedProject />
      <ScrollToTop />
      <Beacon />

      <MainWithNavigation>
        <Navigation />

        <ContentWithNavigation>
          <StickyHeaderContainer>
            <UserMenu />
          </StickyHeaderContainer>
          <Suspense fallback={<AppContentLoader />}>{children}</Suspense>
        </ContentWithNavigation>
      </MainWithNavigation>
      <TooltipGlobalStyles />
    </>
  );
});
