// @ts-check
import { useEffect, useRef, useState } from 'react';

/**
 * @typedef {object} ResizeObserverEntry
 * @property {DOMRectReadOnly} contentRect - The content rectangle of the observed element.
 * @property {Element} target - The observed element.
 */

/**
 * Hook to observe the resize of an element.
 *
 * @param {Element | null} element - The DOM element to observe.
 * @returns {ResizeObserverEntry} The latest entry from the ResizeObserver.
 */
export const useResizeObserver = (element) => {
  const observer = useRef(/** @type {ResizeObserver | null} */ (null));
  const [observerEntry, setObserverEntry] = useState(/** @type {ResizeObserverEntry} */ ({}));

  useEffect(() => {
    if (typeof window.ResizeObserver !== 'undefined') {
      observer.current = new window.ResizeObserver(([entry]) => setObserverEntry(entry));

      return () => {
        if (element && observer.current) {
          observer.current.unobserve(element);
        }
      };
    }
  }, [observer, element]);

  useEffect(() => {
    element && observer.current?.observe(element);
  }, [element]);

  return observerEntry;
};
