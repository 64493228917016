import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initServiceWorker } from './initServiceWorker';

const root = ReactDOM.createRoot(document.getElementById('root'));
// TODO: wrap App <React.StrictMode>
root.render(<App />);

initServiceWorker();
