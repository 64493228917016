/**
 * @param {string} containerId
 * @returns {{ scriptCode: string; noScriptCode: string }}
 */
export const getGtmCode = (containerId) => {
  if (!containerId) {
    return { scriptCode: '', noScriptCode: '' };
  }

  return {
    scriptCode: `
{(function (w, d, s, l, i) {
  w[l] = w[l] || [];
  w[l].push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js",
  });
  var f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != "dataLayer" ? "&l=" + l : "";
  j.async = true;
  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
  f.parentNode.insertBefore(j, f);
})(window, document, "script", "dataLayer", '${containerId}')};`,

    noScriptCode: `<iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}"
  height="0"
  width="0"
  style="display:none;visibility:hidden">
</iframe>`,
  };
};
