// @ts-check
import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';
import { RemoveScroll } from 'react-remove-scroll';

import { StyledDialog } from '../components/ModalContentContainer/styles/ModalContentContainer.styles';

export const ModalAnimatedDiv = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const ModalContainer = styled(RemoveScroll)`
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
  max-width: 100%;
`;

/**
 * @typedef {{
 *   $isShowOverlay: boolean;
 *   $isFullScreenModal: boolean;
 *   $position?: import('../Modal.jsx').ModalPosition;
 *   [key: string]: any;
 * }} ModalOverlayProps
 */

/** @typedef {import('styled').StyledComponent<'animated.div', ModalOverlayProps>} StyledModalOverlayProps */
export const ModalOverlay = /** @type {StyledModalOverlayProps} */ (styled(animated.div))(
  ({ $isShowOverlay = true, $isFullScreenModal = false, $position }) => css`
    --modal-horizontal-padding: 22px;
    --modal-border-radius: 12px;
    --modal-overlay-padding: 40px;

    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    ${$isShowOverlay &&
    css`
      background-color: rgba(230, 231, 234, 0.8);
      padding: var(--modal-overlay-padding);
      width: 100vw;
      height: 100vh;
    `}

    ${$isFullScreenModal &&
    css`
      --modal-border-radius: 0;
      padding: 0;

      ${ModalContainer} {
        box-shadow: none;
        width: 100vw;
        height: 100vh;
      }

      ${StyledDialog} {
        width: 100%;
        min-height: 100%;
      }
    `}

    ${$position}
  `
);
