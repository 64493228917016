import React from 'react';

import { isDefined } from '~/utils/check-value';

import { ProjectAvatarContainer } from './styles/ProjectAvatar.styles';

const getGradientsIndexesArray = () => Array.from({ length: 20 }, (value, index) => index);
const gradients = getGradientsIndexesArray();

const getGradientIndexFromPubKey = (pubKey) => {
  const pubKeyDigits = pubKey.match(/\d+/g) ?? [0];
  return parseInt(pubKeyDigits.join(''), 10) % gradients.length;
};

export const ProjectAvatar = ({ className, pubKey = '0' }) => {
  const gradientIndex = getGradientIndexFromPubKey(pubKey);

  if (!isDefined(gradientIndex)) {
    return null;
  }

  return <ProjectAvatarContainer className={className} gradientIndex={gradientIndex} />;
};
