/* eslint-disable camelcase */
import { flow, computed, observable, makeObservable } from 'mobx';
import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';
import { dataLikeArrayMixin } from '../mixins';

export const OAuthAppEnum = {
  FACEBOOK: 'facebook',
  GDRIVE: 'gdrive',
  GPHOTOS: 'gphotos',
  DROPBOX: 'dropbox',
  INSTAGRAM: 'instagram',
  EVERNOTE: 'evernote',
  FLICKR: 'flickr',
  SKYDRIVE: 'skydrive',
  BOX: 'box',
  VK: 'vk',
  HUDDLE: 'huddle',
  ONEDRIVE: 'onedrive',
};

export const getTitleByOAuthAppId = (id) => {
  switch (id) {
    case OAuthAppEnum.FACEBOOK:
      return 'Facebook';
    case OAuthAppEnum.GDRIVE:
      return 'Google Drive';
    case OAuthAppEnum.GPHOTOS:
      return 'Google Photos';
    case OAuthAppEnum.DROPBOX:
      return 'Dropbox';
    case OAuthAppEnum.INSTAGRAM:
      return 'Instagram';
    case OAuthAppEnum.EVERNOTE:
      return 'Evernote';
    case OAuthAppEnum.FLICKR:
      return 'Flickr';
    case OAuthAppEnum.SKYDRIVE:
      return 'SkyDrive';
    case OAuthAppEnum.ONEDRIVE:
      return 'OneDrive';
    case OAuthAppEnum.BOX:
      return 'Box';
    case OAuthAppEnum.VK:
      return 'VK';
    case OAuthAppEnum.HUDDLE:
      return 'Huddle';
    default:
      return id;
  }
};

/** @typedef {import('dashboard-api-types').oauthApp} OAuthApp */
export class ProjectOAuthAppsStore extends dataLikeArrayMixin(BaseStore) {
  /** @type {OAuthApp[]} */
  data = [];

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      createProject: flow,
      updateProject: flow,
      removeProject: flow,
      sortedData: computed,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/oauth_apps/`;
  }

  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/', config);
      this.data = res.data.results;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }

  *createProject(id, { app_key, app_secret, auth_url }) {
    this.isLoading = true;

    try {
      const res = yield this.api.post(`/${id}/`, {
        app_key,
        app_secret,
        auth_url,
      });
      this.data.push(res.data);
      this.isLoading = false;
      const title = getTitleByOAuthAppId(id);
      message.success(`OAuth app keys for ${title} were created.`);
      return res;
    } catch (e) {
      this.isLoading = false;
      this.processErrors(e);
    }
  }

  *updateProject(id, { app_key, app_secret, auth_url }) {
    this.isLoadingMap[id] = true;

    try {
      const res = yield this.api.put(`/${id}/`, {
        app_key,
        app_secret,
        auth_url,
      });
      this.updateItemInDataByField(res.data, 'name');
      this.isLoadingMap[id] = false;
      const title = getTitleByOAuthAppId(id);
      message.info(`OAuth app keys for ${title} were updated.`);
      return res;
    } catch (e) {
      this.isLoadingMap[id] = false;
      this.processErrors(e);
    }
  }

  *removeProject(id) {
    this.isLoadingMap[id] = true;

    try {
      const res = yield this.api.delete(`/${id}/`);
      this.removeItemFromDataByField(id, 'name');
      this.isLoadingMap[id] = false;
      const title = getTitleByOAuthAppId(id);
      message.info(`${title} OAuth app keys were deleted.`);
      return res;
    } catch (e) {
      this.isLoadingMap[id] = false;
      this.processErrors(e);
    }
  }

  get sortedData() {
    return this.data.sort((itemA, itemB) => itemA.name.localeCompare(itemB.name));
  }
}
