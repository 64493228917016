// @ts-check
import { observable, makeObservable, flow } from 'mobx';
import { RESPONSE_CODES } from '~/utils/http';
import { BaseStore } from '../base-store';

/** @augments {BaseStore<import('dashboard-api-types').stats>} */
export class AccountStatsStore extends BaseStore {
  /** @type {import('dashboard-api-types').stats | null} */
  data = null;

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/account/stats/';
  }

  /** @param {{ config?: import('axios').AxiosRequestConfig }} param */
  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      /**
       * @type {import('axios').AxiosResponse<
       *   Exclude<import('dashboard-api-types').stats, undefined>
       * >}
       */
      const res = yield this.api.get('/', config);
      this.data = res.data;
    } catch (err) {
      const error = /** @type {import('~/types/http-api-error').HttpApiError} */ (err);

      // 404 if stats is empty
      if (error instanceof Error && error?.response?.status !== RESPONSE_CODES.NOT_FOUND) {
        this.processErrors(error);
      }
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }
}
