export const COLORS = {
  AccentGray: {
    base: '',
    transparent: {
      min: '',
      mid: '',
      max: '',
    },
  },
  LightGray: {
    base: '',
  },
  MediumGray: {
    base: '',
  },
  DarkGray: {
    base: '',
  },
  AccentBlue: {
    base: '#3771FF',
  },
};
