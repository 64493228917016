import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ThemeProvider, StyleSheetManager } from 'styled-components';
import { shouldForwardProp } from '@uc-common/utils/should-forward-prop';
import { OutlineManager } from '@uc-common/outline-manager';
import { RollbarProvider } from '~/components/Rollbar';
import { StoresProvider, useStores } from '~/stores';
import { useAuthentication } from '~/hooks/useAuthentication';
import { AppContentLoader } from '~/components/AppContentLoader';
import { ModalsProvider } from '~/components/Modal/context/ModalsContext';
import { SVGDefs } from '~/components/SVGDefs';
import { AppTools } from '~/components/AppTools';
import { Analytics } from '~/analytics';
import { Routing } from '~/App/Routing';
import { ModalsRenderer } from '~/components/Modal';
import { HiddenContainer } from '~/components/HiddenContainer';
import { Toaster } from '~/components/Toaster';
import './styles/app.css';
import { themeStyledComponents } from './styles/theme-styled-components';

export const App = () => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <RollbarProvider>
      <ThemeProvider theme={themeStyledComponents}>
        <OutlineManager />
        <StoresProvider>
          <Router>
            <ModalsProvider>
              <Content />
            </ModalsProvider>
          </Router>
        </StoresProvider>
      </ThemeProvider>
    </RollbarProvider>
  </StyleSheetManager>
);

const Content = observer(() => {
  const { accountStore } = useStores();
  const [authenticate, isAuthenticationLoading] = useAuthentication();

  useEffect(() => {
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (accountStore?.isInitialLoading || isAuthenticationLoading) {
    return <AppContentLoader />;
  }

  return (
    <Suspense fallback={<AppContentLoader />}>
      <SVGDefs />
      <AppTools />
      <Analytics />
      <Routing />
      <Toaster />
      <ModalsRenderer />
      <HiddenContainer />
    </Suspense>
  );
});
