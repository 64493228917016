// ts-check
import React from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import { useTrial } from '~/hooks/useTrial';
import { TrialButtonUserMenu } from '~/components/UserMenu/TrialButtonUserMenu';
import { PlanBadge } from './PlanBadge';
import { UserNav } from './UserNav';
import { BaseLink } from '../Link';
import { ChangelogLink } from './ChangelogLink';
import { ReactComponent as DocsIcon } from './assets/docs.svg';

export const UserMenu = observer(() => {
  const { isTrial } = useTrial();

  return (
    <Container>
      {!isTrial && <PlanBadge />}
      {isTrial && <TrialButtonUserMenu />}

      <UserMenuLink as={ChangelogLink} />

      <UserMenuLink
        href="/docs/"
        target="_blank"
        data-analytics="btn_top-menu_docs"
        aria-label="Go to documentation"
        title="Documentation"
      >
        <DocsIcon />
        <span>Docs</span>
      </UserMenuLink>

      <UserNav />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: 20px;

  height: 100%;
`;

const UserMenuLink = styled(BaseLink)`
  height: 100%;

  display: flex;
  gap: 7px;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  color: #596570;

  position: relative;
  transition: color 0.2s ease-in-out;

  // expand clickable area
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 40px;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    color: #3771ff;
    transition: color 0.2s ease-in-out;
  }

  &:active {
    color: #2d5dd0;
    transition: color 0.2s ease-in-out;
  }

  &:focus-visible {
    outline: none;
    color: #3771ff;
    transition: color 0.2s ease-in-out;
  }
`;
