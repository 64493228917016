import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ButtonSizeEnum, ButtonTypeEnum } from '@uc-common/button';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { UpgradeButton } from '~/components/UpgradePlan';
import { RoutesEnum } from '~/App';
import { useStores } from '~/stores';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import {
  DropDown,
  DropDownMenu,
  DropDownMenuItem,
  DropDownMenuItemSeparator,
  DropDownToggle,
  DropDownPositionEnum,
} from '~/components/DropDownMenu';

export const UserNav = observer(({ className }) => {
  const { authStore, accountStore, accountPlanStore } = useStores();
  const accountData = accountStore.data;
  const planToUpgrade = accountPlanStore.nextPlan;
  const accountMenuItemLetter = accountData.name ? accountData.name[0] : accountData.email[0];
  const handleError = useErrorHandler();

  const handleLogout = () => {
    authStore.logout();
  };

  useEffect(() => {
    accountPlanStore.fetchAvailablePlans().catch(handleError);
  }, [handleError, accountPlanStore]);

  return (
    <DropDown className={className}>
      <StyledDropDownToggle dataAnalytics="btn_top-menu_toggle" aria-label="user menu">
        <ToggleText>{accountMenuItemLetter}</ToggleText>
      </StyledDropDownToggle>

      <DropDownMenu position={DropDownPositionEnum.RIGHT}>
        <UserNavItem
          forwardedAs={NavLink}
          to={RoutesEnum.HOME}
          hideOnClick
          data-analytics="btn_top-menu_workspace"
        >
          Workspace
        </UserNavItem>
        <UserNavItem
          forwardedAs={NavLink}
          to={RoutesEnum.BILLING}
          hideOnClick
          data-analytics="btn_top-menu_billing"
        >
          Billing
        </UserNavItem>
        <UserNavItem
          data-analytics="btn_top-menu_account"
          forwardedAs={NavLink}
          to={RoutesEnum.PROFILE}
          hideOnClick
        >
          Profile
        </UserNavItem>
        <DropDownMenuItemSeparator />
        <UserNavItem
          forwardedAs="button"
          disabled={authStore.isLoadingMap.logout}
          onClick={handleLogout}
          data-analytics="btn_top-menu_logout"
        >
          Log out
        </UserNavItem>
        {accountStore.isFree && Boolean(planToUpgrade) && (
          <>
            <DropDownMenuItemSeparator />
            <UpgradeButton
              planId={planToUpgrade.id}
              themeType={ButtonTypeEnum.PRIMARY}
              size={ButtonSizeEnum.EXTRA_SMALL}
              ghost={false}
              withoutIcon
              data-analytics="btn_account-menu-free-plan_upgrade"
            >
              Upgrade to {planToUpgrade.name}
            </UpgradeButton>
          </>
        )}
      </DropDownMenu>
    </DropDown>
  );
});

const ToggleText = styled.span(
  ({ $isOpen }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    background-color: ${$isOpen ? '#e8eaee' : '#f0f1f4'};
    border-radius: 50%;

    font-family: var(--font-sans);
    color: #000000;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;

    overflow: visible;
    transition: background-color 0.2s ease-in-out;
  `
);

const StyledDropDownToggle = styled(DropDownToggle)`
  position: relative;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;

  // expand clickable area
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 150%;
    transform: translate(-50%, -50%);
  }

  &:hover ${ToggleText} {
    background-color: #e8eaee;
    transition: background-color 0.2s ease;
  }
`;

const UserNavItem = styled(DropDownMenuItem)`
  width: 170px;
  font-size: 13px;
  color: #495560;
`;
