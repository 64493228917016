// @ts-check
export const LINK_TARGET = {
  BLANK: '_blank',
};

export const TRUSTED_HOSTS = [
  'uploadcare.com', // XXX: in case of https://uploadcare.com/docs, https://uploadcare.com/blog
  'app.uploadcare.com',
  'ucarecdn.com',
  'community.uploadcare.com',
  'status.uploadcare.com',
  'staging0.uploadcare.com',
  'app.staging0.uploadcare.com',
  'staging0.ucarecdn.com',
];

const FILE_EXTENSION_REGEXP = /\.[^/]{2,4}$/;

/**
 * @param {string} url
 * @param {string} [target]
 * @param {string} [ariaLabel]
 * @param {string} [rel]
 * @returns {{
 *   newUrl: string;
 *   newTarget: string | undefined;
 *   newAriaLabel: string | undefined;
 *   newRel: string | undefined;
 * }}
 */
export const normalizeLinkAttrs = (url, target, ariaLabel, rel = undefined) => {
  const isExternal = !(url.startsWith('/') || url.startsWith('#'));
  const hasQueryOrAnchor = url.includes('#') || url.includes('?');
  const hasEndedSlash = url.endsWith('/');

  let newUrl = url;
  let newTarget = target;
  let newAriaLabel = ariaLabel;
  let newRel = rel;

  if (!!newUrl && isExternal) {
    const parsedUrl = (() => {
      try {
        return new URL(url);
      } catch {
        throw new Error(`>>> Invalid URL: ${url}`);
      }
    })();

    const { host, pathname } = parsedUrl;

    // Adds a trailing slash for external trusted link except ucarecdn.com and .xml
    if (
      TRUSTED_HOSTS.includes(host) &&
      !host.includes('ucarecdn.com') &&
      !hasEndedSlash &&
      !pathname.match(FILE_EXTENSION_REGEXP)
    ) {
      parsedUrl.pathname = pathname.endsWith('/') ? pathname : `${pathname}/`;
      newUrl = parsedUrl.toString();
    }

    if (!TRUSTED_HOSTS.includes(host)) {
      if (!newRel) {
        newRel = 'noopener noreferrer nofollow';
      } else if (!newRel.includes('noopener')) {
        newRel += ' noopener';
      }

      newTarget = LINK_TARGET.BLANK;
    }
  } else if (!hasEndedSlash && !hasQueryOrAnchor) {
    // Adds a trailing slash for internal links without queries/anchors
    newUrl = newUrl.replace(/\/$|$/, '/');
  }

  if (newTarget === LINK_TARGET.BLANK) {
    newAriaLabel = ariaLabel ? `${ariaLabel} (opens in a new tab)` : 'opens in a new tab';
  }

  return { newUrl, newTarget, newAriaLabel, newRel };
};
