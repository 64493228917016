import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMatch } from 'react-router-dom';

import {
  HeaderRight,
  HeaderContainer,
  HeaderText,
  HeaderLink,
} from '~/components/Header/styles/Header.styles';

import { BaseLink } from '~/components/Link/index';
import { useStores } from '~/stores/index';
import { RoutesEnum } from '~/App/index';
import { mailToLink } from '~/utils/support-link';

export const Header = observer(() => {
  const { authStore, accountStore } = useStores();

  const { isUnknownUser } = accountStore;
  const isLogin = useMatch(RoutesEnum.SIGN_IN);
  const isShowHelp = useMatch(RoutesEnum.SIGN_UP_SUCCESS);

  let link = isUnknownUser
    ? {
        children: 'Log in',
        to: RoutesEnum.SIGN_IN,
      }
    : {
        children: 'Logout',
        onClick: () => authStore.logout(),
        forwardedAs: 'button',
      };

  if (isLogin) {
    link = {
      children: 'Sign up',
      to: RoutesEnum.SIGN_UP,
    };
  }

  return (
    <HeaderContainer>
      <BaseLink href="/">
        <img
          src="https://ucarecdn.com/69eceb38-7f93-4336-8edb-f617ddf21556/logo.svg"
          alt="Uploadcare"
        />
      </BaseLink>
      <HeaderRight>
        {isShowHelp && (
          <HeaderText>
            Any problems?&nbsp;
            <HeaderLink href={mailToLink.help.href}>Contact us</HeaderLink>
          </HeaderText>
        )}
        <HeaderLink {...link} />
      </HeaderRight>
    </HeaderContainer>
  );
});
