import styled, { css } from 'styled-components';

import { Button } from '../../button';

export const SandboxCodeExampleButton = styled(Button)`
  height: 32px;
  background-color: #e9ecee;
  color: #495560;
  border-radius: 8px;

  opacity: 0;
`;

export const StyledCopyButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0 8px;
  background-color: #e9ecee;
  color: #8f969e;

  opacity: 0;
`;

export const CodeButtonsContainer = styled.div`
  display: flex;
  gap: 5px;

  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;

  ${/* sc-selector */ StyledCopyButton} {
    border-color: #f1f2f3;

    &:hover,
    &:focus-visible {
      background-color: #e2e5e7;
      color: #596570;
      opacity: 1;
    }

    &:active {
      background-color: #d8dbde;
      color: #596570;
    }
  }

  ${/* sc-selector */ SandboxCodeExampleButton} {
    border: 1px solid #f1f2f3;

    &:hover,
    &:visited:hover {
      color: #2f3840;
      background-color: #e2e5e7;
      border: 1px solid #f1f2f3;
    }

    &:focus-visible {
      outline: none;
      background-color: #e2e5e7;
      border: 1px solid #f1f2f3;
      color: #2f3840;
      opacity: 1;
    }

    &:active {
      color: #2f3840;
      background-color: #d8dbde;
      border: 1px solid #f1f2f3;
    }

    &:visited {
      color: #2f3840;
      border: 1px solid #f1f2f3;
    }
  }
`;

export const StyledLabel = styled.span`
  position: absolute;
  top: 10px;
  right: var(--code-padding, 20px);
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  color: #869099;
`;

export const StyledContainer = styled.div`
  margin: 0 0 0.7em;
  position: relative;
  --default-color: #575f7b;
  --background-color: #f8f9fb;
  --border-color: #f1f2f3;
  --color-1: #36a134;
  --color-2: #5c6cfc;
  --color-3: #be4678;
  --color-4: #576ddb;
  --color-5: #655f6d;

  pre {
    margin: 0;
    white-space: pre-wrap;
  }

  &:hover {
    ${/* sc-selector */ StyledCopyButton} {
      opacity: 1;
    }

    ${/* sc-selector */ SandboxCodeExampleButton} {
      opacity: 1;
    }

    ${StyledCopyButton} + ${StyledLabel} {
      display: none;
    }
  }
`;

export const StyledCode = styled.code(
  ({ $autoLinker = false }) => css`
    color: var(--default-color);
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    font-size: 13px;
    line-height: 1.7;
    padding: var(--code-padding, 20px);
    display: block;
    overflow: auto;
    width: auto;
    font-family: var(--font-mono);

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &[class*='language-'] {
      & .string,
      & .attr-value,
      & .symbol,
      & .bullet {
        color: var(--color-1);
      }

      & .number,
      & .meta,
      & .class-name,
      & .type,
      & .boolean,
      & .parameter,
      & .keyword,
      & .selector-tag {
        color: var(--color-2);
      }

      & .variable,
      & .template-variable,
      & .attribute,
      & .tag,
      & .attr-name,
      & .name,
      & .regexp,
      & .link,
      & .selector {
        color: var(--color-3);
      }

      & .function,
      & .section {
        color: var(--color-4);
      }

      & .comment,
      & .quote {
        color: var(--color-5);
      }
    }

    a {
      color: inherit;

      ${$autoLinker
        ? css`
            text-decoration: underline;
          `
        : css`
            pointer-events: none;
            text-decoration: none;
            outline: none;
          `}
    }
  `
);
