// @ts-check
import { flow, makeObservable, observable } from 'mobx';

import { BaseStore } from '../base-store';

/** @typedef {import('dashboard-api-types').moderationThreshold} Threshold */

/** @augments {BaseStore<Threshold[], { updateThresholds: boolean }>} */
export class ProjectFilesModeration extends BaseStore {
  /** @type {Threshold[] | null} */
  data = null;

  /** @param {import('../app-store.js').AppStore} appStore */
  constructor(appStore) {
    super(appStore);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      updateThresholds: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/moderation/`;
  }

  /** @param {{ config?: import('../base-store').AxiosRequestConfig }} [options={}] */
  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      /** @type {{ data: { thresholds: Threshold[] } }} */
      const res = yield /** @type {Promise<{ thresholds: Threshold[] }>} */ (
        this.api.get(`/thresholds/`, config)
      );
      this.data = res.data.thresholds;
    } finally {
      this.isLoading = this.isInitialLoading = false;
    }
  }

  /** @param {Threshold[]} thresholds */
  *updateThresholds(thresholds) {
    this.isLoadingMap.updateThresholds = true;

    try {
      /** @type {{ data: { thresholds: Threshold[] } }} */
      const res = yield this.api.post(
        '/thresholds/',
        thresholds.map((t) => ({
          category_id: t.category_id,
          file_type: t.file_type,
          threshold_percentage: t.threshold_percentage,
        }))
      );
      this.data = res.data.thresholds;
    } finally {
      this.isLoadingMap.updateThresholds = false;
    }
  }
}
