export const NOT_ONLY_WHITESPACE_PATTERN = /\S/;

export const PROJECT_NAME_VALIDATION = {
  required: 'Please, fill in the field.',
  pattern: {
    value: NOT_ONLY_WHITESPACE_PATTERN,
    message: 'This field may not be blank.',
  },
  maxLength: {
    value: 60,
    message: 'Project name may not exceed 60 characters.',
  },
};

export const STORAGE_TYPE = {
  UC_STORAGE: 'ucStorage',
  FOREIGN_STORAGE: 'foreignStorage',
};

export const USE_CASES = {
  UC_STORAGE: {
    value: STORAGE_TYPE.UC_STORAGE,
    label: 'Built-in storage',
    description: 'Simple and straightforward',
    dataAnalytics: 'checkbox_create-project_builtin',
  },
  FOREIGN_STORAGE: {
    value: STORAGE_TYPE.FOREIGN_STORAGE,
    label: 'Your own AWS S3 bucket',
    description: 'Full control and cloud portability',
    dataAnalytics: 'checkbox_create-project_bucket',
  },
};

export const STEP_LABELS = [
  'Name and storage',
  'S3 access settings',
  'S3 bucket policy',
  'S3 CORS policy',
  'Check connection',
];

export const FORM_FIELD = {
  PROJECT_NAME: 'name',
  USE_CASE: 'useCase',
  FOREIGN_BUCKET_NAME: 'bucket_name',
};
