// @ts-check
import { useCallback, useState } from 'react';
import copy from 'copy-to-clipboard';

export const StatusEnum = {
  IDLE: 'IDLE',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const CaptionEnum = {
  [StatusEnum.IDLE]: 'Copy',
  [StatusEnum.SUCCESS]: 'Copied!',
  [StatusEnum.FAILED]: 'Failed',
};

/**
 * @param {string} text
 * @param {number} delay
 * @param {() => void} onCopy
 * @returns {[string, () => void, string]}
 */

export const useCopyToClipboard = (text, delay = 1000, onCopy = () => {}) => {
  const [status, setStatus] = useState(StatusEnum.IDLE);

  const onClick = useCallback(() => {
    const isCopied = copy(text);
    setStatus(isCopied ? StatusEnum.SUCCESS : StatusEnum.FAILED);

    setTimeout(() => {
      setStatus(StatusEnum.IDLE);
      onCopy();
    }, delay);
  }, [text, onCopy, delay]);

  return [CaptionEnum[status], onClick, status];
};
