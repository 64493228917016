// @ts-check
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '~/stores';
import { CUSTOM_PLAN_BASE } from '~/stores/constants/plans';
import { useErrorHandler } from '~/hooks/useErrorHandler';
import { ButtonSizeEnum, ButtonTypeEnum } from '@uc-common/button';
import { useShowUpgrade } from './hooks/useShowUpgrade';
import { UpgradeButtonContainer } from './styles/UpgradeButton.styles';
import { ReactComponent as UpgradeIcon } from './assets/upgrade-lightning.svg';

/**
 * @type {React.FC<
 *   {
 *     withoutIcon?: boolean;
 *     planId?: number;
 *   } & import('@uc-common/button/Button.types').ButtonProps &
 *     React.ButtonHTMLAttributes<HTMLButtonElement>
 * >}
 */
export const UpgradeButton = observer(
  ({
    themeType = ButtonTypeEnum.SUCCESS,
    customTheme,
    ghost = true,
    transparent,
    size = ButtonSizeEnum.SMALL,
    block,
    long,
    alignmentText,
    isLoading,
    as,
    to,
    icon = UpgradeIcon,
    withoutIcon = false,
    children,
    onClick,
    className,
    planId,
    ...props
  }) => {
    const { accountPlanStore } = useStores();
    const handleError = useErrorHandler();

    useEffect(() => {
      if (!planId) {
        accountPlanStore.fetchAvailablePlans();
      }
    }, [planId, handleError, accountPlanStore]);

    const { handleClick, element, href, target } = useShowUpgrade({ planId, onClick });

    return (
      <UpgradeButtonContainer
        type="button"
        themeType={themeType}
        customTheme={customTheme}
        ghost={ghost}
        transparent={transparent}
        size={size}
        block={block}
        long={long}
        alignmentText={alignmentText}
        isLoading={isLoading}
        as={as}
        href={href}
        target={target}
        forwardedAs={element}
        icon={!withoutIcon && icon}
        onClick={handleClick}
        className={className}
        data-analytics={`btn_upgrade-${planId ?? CUSTOM_PLAN_BASE.id}`}
        {...props}
      >
        {children}
      </UpgradeButtonContainer>
    );
  }
);
