export const RoutesEnum = Object.freeze({
  HOME: '/',
  SIGN_IN: '/accounts/login/',
  SIGN_IN_SSO: '/accounts/login/okta-oauth2/',
  SIGN_UP: '/accounts/signup/',
  SIGN_UP_SUCCESS: '/accounts/signup/success/',
  SESSION_EXPIRED: '/accounts/session-expired/',
  PROJECT: '/projects/:pubKey/',
  PROJECT_SETTINGS: '/projects/:pubKey/settings/',
  PROJECT_GET_STARTED: '/projects/:pubKey/get-started/',
  PROJECT_GET_STARTED_OPTIMIZE: '/projects/:pubKey/get-started/optimize-image/',
  PROJECT_GET_STARTED_TRANSFORMATIONS: '/projects/:pubKey/get-started/transform-image/',
  PROJECT_GET_STARTED_UPLOAD_ASSETS: '/projects/:pubKey/get-started/upload-assets/',
  PROJECT_GET_STARTED_UPLOAD_API: '/projects/:pubKey/get-started/upload-assets/upload-api/',
  PROJECT_FILES_INDEX: '/projects/:pubKey/files/',
  PROJECT_FILES_FILE: '/projects/:pubKey/files/:uuid/',
  PROJECT_API_KEYS: '/projects/:pubKey/api-keys/',
  PROJECT_API_LOGS: '/projects/:pubKey/api-logs/',
  PROJECT_API_LOGS_ITEM: '/projects/:pubKey/api-logs/:requestId/',
  PROJECT_ANALYTICS: '/projects/:pubKey/analytics/',
  PROJECT_USAGE: '/projects/:pubKey/usage/',
  TEAM_INVITATION: '/projects/:pubKey/team/invitations/:token/',
  PROFILE: '/accounts/settings/',
  PASSWORD_CHANGE_CONFIRMATION: '/accounts/settings/password/:emailBase64/:token/',
  BILLING: '/billing/',
  UPGRADE_CONFIRMATION: '/billing/confirmation/',
  BLOCKED_ACCOUNT: '/accounts/blocked/',
  APPROVE_EMAIL: '/accounts/settings/approve-email/:token/',
  PROJECT_UPLOADING_CONFIGURE: '/projects/:pubKey/settings/#uploading',
  PROJECT_STORAGE_CONFIGURE: '/projects/:pubKey/settings/#storage',
  PROJECT_UPLOADING_WIDGET: '/projects/:pubKey/uploading/widget/',
  PROJECT_DELIVERY_CONFIGURE: '/projects/:pubKey/settings/#delivery',
  PROJECT_DELIVERY_IMAGE_PROCESSING: '/projects/:pubKey/delivery/image-processing/',
  PROJECT_DELIVERY_QUICKSTART: '/projects/:pubKey/delivery/quick-start/',
  PROJECT_API_WEBHOOKS: '/projects/:pubKey/api-webhooks/',
  PROJECT_UPLOADING_BLOCKS: '/projects/:pubKey/uploading/blocks/',
  MFA_VERIFICATION: '/accounts/2fa/',
  TOKEN_AUTH: '/auth/login/token/:jwtToken/',
  // TODO: update url
  SOCIALS_AUTHORIZATION: '/social/lalala',
  QUALIFICATION: '/qualification/',
  CREATE_NEW_PROJECT: '/create-new-project/',
});

// use for compile route with hash, like '/settings/#delivery'
export const SettingTabEnum = {
  UPLOADING: 'uploading',
  STORAGE: 'storage',
  DELIVERY: 'delivery',
  TEAM: 'team',
  PROJECT: 'project',
};

export const GetStartedIntegrationEnum = {
  HTML: 'html',
  REACT: 'react',
  REACT_UPLOADER: 'react-uploader',
  NEXT_JS: 'nextJs',
  VUE: 'vue',
  ANGULAR: 'angular',
  SVELTE: 'svelte',
  WEBFLOW: 'webflow',
  JOTFORM: 'jotform',
};
