import React from 'react';

import { ReactComponent as Icon } from './assets/button-icon.svg';
import { FacadeButton, ButtonIcon, Spinner } from './styles/styled-components';

export const BeaconFacadeButton = ({ className, onClick, isLoading }) => {
  return (
    <FacadeButton className={className} onClick={onClick}>
      {isLoading ? (
        <Spinner />
      ) : (
        <ButtonIcon>
          <Icon />
        </ButtonIcon>
      )}
    </FacadeButton>
  );
};
