import styled, { css } from 'styled-components';

import { Link } from '~/components/Link';
import ArrowIcon from '../../../assets/arrow-blank.svg?url';

export const NavLinkIcon = styled.i`
  width: 16px;
  height: 16px;
  margin-right: 13px;
  color: inherit;
  transition: opacity 0.2s ease;
  opacity: 0.6;
`;

export const NavLinkContainer = styled(Link)(
  ({ $isActive = false, $isShowArrow = false }) => css`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.28;
    padding: 10px 13px;
    color: #495560;
    border-radius: 8px;
    margin-top: 6px;
    transition: color 0.3s ease, background-color 0.3s ease;
    white-space: nowrap;

    &:first-child {
      margin-top: 0;
    }

    &:hover,
    &:active {
      ${NavLinkIcon} {
        opacity: 1;
      }
    }

    &:hover {
      color: #000000;
      background-color: rgba(1, 7, 23, 0.04);

      ${$isShowArrow &&
      css`
        &::after {
          opacity: 1;
        }
      `}
    }

    &:active {
      background: rgba(1, 7, 23, 0.06);
    }

    ${$isShowArrow &&
    css`
      &::after {
        content: '';
        width: 8px;
        height: 8px;
        margin-left: auto;
        background: url(${ArrowIcon}) no-repeat center;
        background-size: 8px;
        transition: opacity 0.3s ease;
        opacity: 0;
      }
    `}

    ${$isActive &&
    css`
      && {
        color: #4670f6;
        background-color: #f4f7fe;

        ${NavLinkIcon} {
          opacity: 1;
        }
      }
    `}
  `
);

export const NavLinkBadgeColor = Object.freeze({
  GREEN: 'green',
  BLUE: 'blue',
});

export const NavLinkBadge = styled.span`
  font-weight: 700;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 0.05em;
  margin-left: auto;
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 4px;

  ${({ $color = NavLinkBadgeColor.BLUE } = {}) => {
    switch ($color) {
      case NavLinkBadgeColor.GREEN:
        return css`
          color: #78ca71;
          background-color: rgba(118, 219, 109, 0.2);
        `;
      case NavLinkBadgeColor.BLUE:
        return css`
          color: #3771ff;
          background-color: #ebf1ff;
        `;
      default:
        return '';
    }
  }}
`;
