// @ts-check
import { subDays } from 'date-fns';
import { action, computed, flow, makeObservable, observable } from 'mobx';
import { BaseStore } from '~/stores/base-store';
import { getQueryFromParams } from '~/utils/browser';

export const DEFAULT_ANALYTICS_PERIOD = 29;
export const STORAGE_COLLECTION_BEGINNING_DATE = '2021-08-12';
export const BILLING_PERIOD = 'billing_period';

/** @enum {string} */
export const AnalyticsCategory = Object.freeze({
  OPERATIONS_USAGE: 'operations',
  CDN_TRAFFIC: 'cdn-traffic',
  CDN_FILES: 'cdn-files',
  API_REQUESTS: 'api-requests',
  API_ERRORS: 'api-errors',
  STORAGE_USAGE: 'storage-usage',
  UPLOADS: 'uploads',
  UPLOAD_UNITS: 'upload-units',
  PROCESSING_VIDEO: 'processing-video',
  PROCESSING_DOCUMENTS: 'processing-documents',
  PROCESSING_AWS_REKOGNITION: 'processing-aws-rekognition',
  PROCESSING_BG_REMOVAL: 'processing-bg-removal',
  SAVINGS: 'savings',
});

/** @enum {string} */
export const AnalyticsCategoryMetrics = Object.freeze({
  /** @type {['usage']} */
  [AnalyticsCategory.OPERATIONS_USAGE]: ['usage'],
  /** @type {['usage']} */
  [AnalyticsCategory.SAVINGS]: ['usage'],
  /** @type {['bandwidth']} */
  [AnalyticsCategory.CDN_TRAFFIC]: ['bandwidth'],
  /** @type {['bandwidth', 'requests']} */
  [AnalyticsCategory.CDN_FILES]: ['bandwidth', 'requests'],
  /** @type {['rest', 'upload', 'cdn']} */
  [AnalyticsCategory.API_REQUESTS]: ['rest', 'upload', 'cdn'],
  /** @type {['rest', 'upload']} */
  [AnalyticsCategory.API_ERRORS]: ['rest', 'upload'],
  /** @type {['usage']} */
  [AnalyticsCategory.STORAGE_USAGE]: ['usage'],
  /** @type {['files']} */
  [AnalyticsCategory.UPLOADS]: ['files'],
  /** @type {['units']} */
  [AnalyticsCategory.UPLOAD_UNITS]: ['units'],
  /** @type {['video_conversion']} */
  [AnalyticsCategory.PROCESSING_VIDEO]: ['video_conversion'],
  /** @type {['document_conversion']} */
  [AnalyticsCategory.PROCESSING_DOCUMENTS]: ['document_conversion'],
  /** @type {['aws_rekognition_detect_labels']} */
  [AnalyticsCategory.PROCESSING_AWS_REKOGNITION]: ['aws_rekognition_detect_labels'],
  /** @type {['remove_bg']} */
  [AnalyticsCategory.PROCESSING_BG_REMOVAL]: ['remove_bg'],
});

/**
 * Make generic from generated analyticsMetricObject type
 *
 * @template {| import('dashboard-api-types').analyticsSeriesObject
 *   | import('dashboard-api-types').analyticsListObject} T
 * @typedef {{
 *   category: import('dashboard-api-types').analyticsMetricObject['category'];
 *   metric: import('dashboard-api-types').analyticsMetricObject['metric'];
 *   datasets: T[];
 * } | null} AnalyticsMetricObject
 */

/**
 * @typedef {{
 *   [AnalyticsCategory.OPERATIONS_USAGE]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.CDN_TRAFFIC]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.CDN_FILES]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsListObject
 *   >;
 *   [AnalyticsCategory.API_REQUESTS]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.API_ERRORS]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.STORAGE_USAGE]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.UPLOADS]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.UPLOAD_UNITS]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.PROCESSING_VIDEO]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.PROCESSING_DOCUMENTS]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.PROCESSING_AWS_REKOGNITION]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.PROCESSING_BG_REMOVAL]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 *   [AnalyticsCategory.SAVINGS]: AnalyticsMetricObject<
 *     import('dashboard-api-types').analyticsSeriesObject
 *   >;
 * }} AnalyticsMetricObjectMapping
 */

/**
 * @template {typeof AnalyticsCategory[keyof typeof AnalyticsCategory]} T
 * @typedef {{
 *   activeScope: typeof AnalyticsCategoryMetrics[T][number];
 *   scopes: typeof AnalyticsCategoryMetrics[T];
 *   scopeState: Record<
 *     typeof AnalyticsCategoryMetrics[T][number],
 *     { isLoading: boolean; data: AnalyticsMetricObjectMapping[T] }
 *   >;
 * }} CategoryState
 */

/**
 * @template {typeof AnalyticsCategory[keyof typeof AnalyticsCategory]} T
 * @param {T} category
 * @returns {CategoryState<T>}
 */
const createInitialState = (category) => {
  const scopes = AnalyticsCategoryMetrics[category];

  /** @type {CategoryState<T>} */
  const initialState = {
    scopes,
    activeScope: scopes[0],
    scopeState: /** @type {CategoryState<T>['scopeState']} */ (
      Object.fromEntries(
        scopes.map((scope) => {
          /**
           * @type {readonly [
           *   typeof AnalyticsCategoryMetrics[T][number],
           *   { isLoading: boolean; data: AnalyticsMetricObjectMapping[T] }
           * ]}
           */
          const item = [scope, { isLoading: true, data: null }];
          return item;
        })
      )
    ),
  };
  return initialState;
};

/**
 * @template {typeof AnalyticsCategory[keyof typeof AnalyticsCategory][]} T
 * @param {T} categories
 * @returns {{ [key in T[number]]: ReturnType<typeof createInitialState<key>> }}
 */
const createInitialData = (categories) =>
  categories.reduce(
    (acc, category) => ({ ...acc, [category]: createInitialState(category) }),
    /** @type {{ [key in T[number]]: ReturnType<typeof createInitialState<key>> }} */
    ({})
  );

/**
 * @template {import('~/pages/project-analytics/charts/types').ValueOf<typeof AnalyticsCategory>} T
 * @param {T} category
 * @param {typeof AnalyticsCategoryMetrics[T][number]} scope
 * @returns {string}
 */
const getScopeApiPath = (category, scope) => {
  switch (category) {
    case AnalyticsCategory.OPERATIONS_USAGE:
      return '/operations/usage/';
    case AnalyticsCategory.CDN_TRAFFIC:
      return `/cdn/traffic/${scope}/`;
    case AnalyticsCategory.CDN_FILES:
      return `/cdn/urls/${scope}/`;
    case AnalyticsCategory.SAVINGS:
      return `/cdn/traffic/savings/`;
    case AnalyticsCategory.API_REQUESTS:
      if (scope === 'cdn') {
        return `/cdn/traffic/requests/`;
      }
      return `/api/${scope}/requests/`;
    case AnalyticsCategory.API_ERRORS:
      return `/api/${scope}/errors/`;
    case AnalyticsCategory.UPLOADS:
      return `/uploads/${scope}/`;
    case AnalyticsCategory.UPLOAD_UNITS:
      return `/uploads/${scope}/`;
    case AnalyticsCategory.STORAGE_USAGE:
      return `/storage/${scope}/`;
    case AnalyticsCategory.PROCESSING_AWS_REKOGNITION:
    case AnalyticsCategory.PROCESSING_BG_REMOVAL:
    case AnalyticsCategory.PROCESSING_DOCUMENTS:
    case AnalyticsCategory.PROCESSING_VIDEO:
      return `/processing/${scope}/`;

    default:
      throw new Error(`Unknown category: ${category}`);
  }
};

const legacyCategories = [
  AnalyticsCategory.CDN_FILES,
  AnalyticsCategory.CDN_TRAFFIC,
  AnalyticsCategory.UPLOAD_UNITS,
  AnalyticsCategory.STORAGE_USAGE,
  AnalyticsCategory.PROCESSING_VIDEO,
  AnalyticsCategory.PROCESSING_DOCUMENTS,
  AnalyticsCategory.PROCESSING_AWS_REKOGNITION,
  AnalyticsCategory.PROCESSING_BG_REMOVAL,
  AnalyticsCategory.API_REQUESTS,
  AnalyticsCategory.API_ERRORS,
  AnalyticsCategory.SAVINGS,
];

const operationsBasedCategories = [
  AnalyticsCategory.CDN_FILES,
  AnalyticsCategory.OPERATIONS_USAGE,
  AnalyticsCategory.CDN_TRAFFIC,
  AnalyticsCategory.STORAGE_USAGE,
  AnalyticsCategory.UPLOADS,
  AnalyticsCategory.API_REQUESTS,
  AnalyticsCategory.API_ERRORS,
  AnalyticsCategory.SAVINGS,
];

const storeInitialData = createInitialData([
  AnalyticsCategory.OPERATIONS_USAGE,
  AnalyticsCategory.CDN_TRAFFIC,
  AnalyticsCategory.CDN_FILES,
  AnalyticsCategory.UPLOADS,
  AnalyticsCategory.UPLOAD_UNITS,
  AnalyticsCategory.STORAGE_USAGE,
  AnalyticsCategory.PROCESSING_VIDEO,
  AnalyticsCategory.PROCESSING_DOCUMENTS,
  AnalyticsCategory.PROCESSING_AWS_REKOGNITION,
  AnalyticsCategory.PROCESSING_BG_REMOVAL,
  AnalyticsCategory.API_REQUESTS,
  AnalyticsCategory.API_ERRORS,
  AnalyticsCategory.SAVINGS,
]);

/** @augments {BaseStore<typeof storeInitialData>} */
export class ProjectAnalyticsStore extends BaseStore {
  /** @type {{ periodStart: Date | null; periodEnd: Date | null; periodDays?: number | string }} */
  params = {
    periodStart: null,
    periodEnd: null,
    periodDays: DEFAULT_ANALYTICS_PERIOD,
  };

  initialData = storeInitialData;

  data = this.initialData;

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);
    makeObservable(this, {
      params: observable,
      initParams: action,
      setParams: action,
      setActiveScope: action,

      fetchData: action,
      fetchScopeData: flow,

      categories: computed,
      data: observable,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/digest/`;
  }

  get categories() {
    const hasOperations = this.appStore.stores.projectStore.features.operations?.is_enabled;
    return hasOperations ? operationsBasedCategories : legacyCategories;
  }

  /**
   * @template {import('~/pages/project-analytics/charts/types').ValueOf<typeof AnalyticsCategory>} T
   * @param {T} category
   * @param {typeof AnalyticsCategoryMetrics[T][number]} scope
   */
  setActiveScope = (category, scope) => {
    this.data[category].activeScope = scope;
    this.fetchScopeData(category);
  };

  /**
   * @template {import('~/pages/project-analytics/charts/types').ValueOf<typeof AnalyticsCategory>} T
   * @param {T} category
   * @returns {Generator<Promise<void>>}
   */
  *fetchScopeData(category) {
    const queryString = getQueryFromParams({
      from: /** @type {Date} */ (this.params.periodStart).toISOString(),
      to: /** @type {Date} */ (this.params.periodEnd).toISOString(),
      project: this.appStore.stores.projectStore.pubKey,
    });

    const { activeScope } = this.data[category];
    const scopeState = this.data[category].scopeState[activeScope];
    const url = getScopeApiPath(category, activeScope) + queryString;

    scopeState.isLoading = true;
    scopeState.data = null;

    try {
      const res = yield this.api.get(url);
      scopeState.data = res.data;
    } catch (e) {
      if (e instanceof Error) {
        this.processErrors(e);
      } else {
        throw e;
      }
    } finally {
      scopeState.isLoading = false;
    }
  }

  fetchData() {
    return Promise.all(
      this.categories.map((category) =>
        this.fetchScopeData(
          /**
           * @type {import('~/pages/project-analytics/charts/types').ValueOf<
           *   typeof AnalyticsCategory
           * >}
           */ (category)
        )
      )
    );
  }

  initParams = () => {
    if (this.params.periodDays === BILLING_PERIOD) {
      return;
    }

    const periodEnd = new Date();

    periodEnd.setUTCHours(23);
    periodEnd.setUTCMinutes(59);
    periodEnd.setUTCSeconds(59);
    periodEnd.setUTCMilliseconds(999);

    const periodDays =
      typeof this.params.periodDays === 'number'
        ? this.params.periodDays
        : Number(this.params.periodDays ?? DEFAULT_ANALYTICS_PERIOD);

    const periodStart = subDays(periodEnd, periodDays);

    periodStart.setUTCHours(0);
    periodStart.setUTCMinutes(0);
    periodStart.setUTCSeconds(0);
    periodStart.setUTCMilliseconds(0);

    this.params = {
      ...this.params,
      periodStart,
      periodEnd,
      periodDays,
    };
  };

  /** @param {{ periodStart: Date | null; periodEnd: Date | null; periodDays?: number | string }} options */
  setParams = ({ periodStart, periodEnd, periodDays }) => {
    this.params = {
      ...this.params,
      periodStart,
      periodEnd,
      periodDays,
    };

    return this;
  };

  resetPeriod() {
    this.params.periodDays = DEFAULT_ANALYTICS_PERIOD;
  }
}
