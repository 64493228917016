import { action, observable, makeObservable, flow } from 'mobx';

import { getParamsFromQuery, getQueryFromParams, queryToUrl } from '~/utils/browser';
import { BaseStore } from '../base-store';

export const PERIODS = [
  { value: '4h', label: 'Last 4 hours' },
  { value: '12h', label: 'Last 12 hours' },
  { value: '24h', label: 'Last 24 hours' },
  { value: '1w', label: 'Last week' },
];
const DEFAULT_PERIOD = '4h';

export class ProjectApiLogsSummaryStore extends BaseStore {
  // TODO: Add type
  /** @type {unknown[]} */
  data = [];

  params = {
    period: null,
  };

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      params: observable,
      setParams: action,
      fetchData: flow,
      data: observable,
    });

    const params = getParamsFromQuery();
    const period =
      params?.period && PERIODS.find((period) => period.value === params.period)
        ? params.period
        : DEFAULT_PERIOD;
    this.params = { period };
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/access_logs/summary/`;
  }

  *fetchData() {
    const queryString = getQueryFromParams({ period: this.params.period });

    queryToUrl(queryString, true);

    this.isLoading = true;

    try {
      const res = yield this.api.get(queryString);
      this.data = res.data?.data;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }

  setParams = ({ period }) => {
    if (period === this.params.period) {
      return;
    }

    this.params = {
      ...this.params,
      period,
    };
  };

  reset() {
    super.reset();
    this.data = [];
  }
}
