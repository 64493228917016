// @ts-check
import React, { useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { DropDownContext } from './DropDown';

/**
 * @type {React.FC<{
 *   className?: string;
 *   children: React.ReactElement;
 *   disabled?: boolean;
 *   onOpen?: () => void;
 *   dataAnalytics?: string;
 *   dataCy?: string;
 * }>}
 */
export const DropDownToggle = ({
  children,
  className,
  disabled,
  onOpen,
  dataAnalytics,
  dataCy,
}) => {
  const { isOpen, setIsOpen } = useContext(DropDownContext);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      onOpen?.();
    }
  }, [isOpen, onOpen]);

  /** @returns {Record<string, any>} */
  const getChildProps = () => {
    if (React.isValidElement(children)) {
      /** @type {React.ElementType & { styledComponentId?: string }} */
      const componentType = children?.type;

      if (typeof componentType === 'object' && 'styledComponentId' in componentType) {
        return { $isOpen: isOpen };
      }

      if (typeof componentType === 'function') {
        return { isOpen };
      }
    }

    return {};
  };

  const childProps = getChildProps();

  return (
    <DropDownToggleButton
      className={className}
      type="button"
      onClick={handleClick}
      data-analytics={dataAnalytics}
      data-cy={dataCy}
      $disabled={disabled}
    >
      {React.isValidElement(children) ? React.cloneElement(children, childProps) : children}
    </DropDownToggleButton>
  );
};

/** @typedef {import('styled').StyledComponent<'button', { $disabled?: boolean }>} DropDownToggleButtonProps */
const DropDownToggleButton = /** @type {DropDownToggleButtonProps} */ (styled.button)(
  ({ $disabled }) => css`
    cursor: pointer;
    display: inline-flex;
    pointer-events: ${$disabled ? 'none' : 'auto'};
  `
);
