import { flow, observable, makeObservable } from 'mobx';

import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';
import { dataLikeArrayMixin } from '../mixins';

/** @typedef {import('dashboard-api-types').secret} SecretKey */
export class ProjectApiKeysStore extends dataLikeArrayMixin(BaseStore) {
  limit = 100;

  /** @type {SecretKey[]} */
  data = [];

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      createSecretKey: flow,
      removeSecretKey: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/secrets/`;
  }

  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get(`/?limit=${this.limit}`, config);
      this.data = res.data.results;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }

  *createSecretKey(isSilent = false) {
    this.isLoadingMap.createSecretKey = true;

    try {
      const res = yield this.api.post('/');
      this.data.push(res.data);
      this.isLoadingMap.createSecretKey = false;

      if (!isSilent) {
        message.success('Secret key was created.');
      }

      return res;
    } catch (e) {
      this.isLoadingMap.createSecretKey = false;
      this.processErrors(e);
    }
  }

  *removeSecretKey(id) {
    this.isLoadingMap[id] = true;

    try {
      const res = yield this.api.delete(`/${id}/`);
      this.removeItemFromDataByField(id);
      this.isLoadingMap[id] = false;
      message.info('Secret key was deleted.');
      return res;
    } catch (e) {
      this.isLoadingMap[id] = false;
      this.processErrors(e);
    }
  }
}
