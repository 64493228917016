// https://fkhadra.github.io/react-toastify/introduction/

// @ts-check
import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonSizeEnum, ButtonTypeEnum } from '@uc-common/button';
import { UpgradeButton } from '../UpgradeButton';
import { ReactComponent as SuccessIcon } from './assets/success-icon.svg';
import { ReactComponent as ErrorIcon } from './assets/error-icon.svg';
import { ReactComponent as WarnIcon } from './assets/warn-icon.svg';
import { NotificationTimeout } from './Toaster';

// Example of multiline message :
//
// message.<type>(
//   <>
//     <p>Lorem ipsum dolor sit amet.</p>
//     <p>Lorem ipsum dolor sit amet.</p>
//   </>
// );

/** @typedef {string | number} Id */

/**
 * @function
 * @param {React.ReactNode} message
 * @param {import('react-toastify').ToastOptions<unknown>} [toastProps]
 * @returns {Id}
 */
export const message = (message, toastProps) =>
  toast(<MessageContainer>{message}</MessageContainer>, { ...toastProps });

/**
 * @function
 * @param {React.ReactNode} message
 * @param {import('react-toastify').ToastOptions<unknown>} [toastProps]
 * @returns {Id}
 */
message.info = (message, toastProps) =>
  toast.info(<MessageContainer>{message}</MessageContainer>, {
    icon: false,
    ...toastProps,
  });

/**
 * @function
 * @param {React.ReactNode} message
 * @param {import('react-toastify').ToastOptions<unknown>} [toastProps]
 * @returns {Id}
 */
message.warn = (message, toastProps) =>
  toast.warn(<MessageContainer>{message}</MessageContainer>, {
    icon: <WarnIcon />,
    ...toastProps,
  });

/**
 * @function
 * @param {React.ReactNode} message
 * @param {import('react-toastify').ToastOptions<unknown>} [toastProps]
 * @returns {Id}
 */
message.success = (message, toastProps) =>
  toast.success(<MessageContainer>{message}</MessageContainer>, {
    icon: <SuccessIcon />,
    ...toastProps,
  });

/** @type {Set<React.ReactNode>} */
const errorsStore = new Set();

/**
 * @function
 * @param {React.ReactNode} [message='Something went wrong please try again later.']
 * @param {import('react-toastify').ToastOptions<unknown>} [toastProps]
 * @returns {void}
 */
message.error = (
  message = 'Something went wrong please try again later.',
  toastProps = undefined
) => {
  if (!errorsStore.has(message)) {
    toast.error(<MessageContainer>{message}</MessageContainer>, {
      role: 'alert',
      icon: <ErrorIcon />,
      autoClose: false,
      ...toastProps,
    });

    errorsStore.add(message);
    setTimeout(() => errorsStore.delete(message), NotificationTimeout.BASE);
  }
};

/**
 * @function
 * @param {React.ReactNode} message
 * @param {import('react-toastify').ToastOptions<unknown>} [toastProps]
 * @returns {Id}
 */
message.upgrade = (message, toastProps) =>
  toast.warn(
    <>
      <MessageContainer>{message}</MessageContainer>
      <UpgradeButton
        withoutIcon
        themeType={ButtonTypeEnum.DARK}
        ghost={false}
        size={ButtonSizeEnum.EXTRA_SMALL}
      >
        Upgrade
      </UpgradeButton>
    </>,
    { icon: <WarnIcon />, closeButton: false, autoClose: false, ...toastProps }
  );

const MessageContainer = styled.div`
  padding: 8px 0;

  &,
  & * {
    font-family: var(--font-sans);
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    word-break: break-word;
    color: #495560;
  }

  &:not(:has(*)),
  & p:nth-child(1) {
    color: #000;
    font-weight: 500;
  }
`;
