import { action, flow, makeObservable, observable } from 'mobx';
import { BaseStore } from '../base-store';

const initialSettings = {
  need_password: null, // if field equal true, then required password for change email
  need_to_auth: null, // if field equal true, then logout and login for change email
};

export class UserEmailStore extends BaseStore {
  settings = initialSettings;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      settings: observable,
      resendEmail: flow,
      approveEmail: flow,
      fetchSettings: flow,
      resetSettings: action,
      changeEmail: flow,
      cancelChangeEmail: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/email/';
  }

  *resendEmail(data) {
    this.isLoadingMap.resendEmail = true;

    try {
      yield this.api.post('/resend/', data);
    } finally {
      this.isLoadingMap.resendEmail = false;
    }
  }

  *approveEmail(token) {
    this.isLoadingMap.approveEmail = true;

    try {
      yield this.api.post('/approve/', { token });
    } finally {
      this.isLoadingMap.approveEmail = false;
    }
  }

  *fetchSettings({ config } = {}) {
    this.isLoadingMap.fetchSettings = true;

    try {
      const res = yield this.api.get('/change/', config);
      this.settings = res.data;
      return res;
    } finally {
      this.isLoadingMap.fetchSettings = false;
    }
  }

  resetSettings() {
    this.settings = initialSettings;
  }

  *changeEmail(data) {
    this.isLoadingMap.changeEmail = true;

    try {
      yield this.api.post('/change/', data);
    } finally {
      this.isLoadingMap.changeEmail = false;
    }
  }

  *cancelChangeEmail() {
    this.isLoadingMap.cancelChangeEmail = true;

    try {
      yield this.api.delete('/change/');
    } finally {
      this.isLoadingMap.cancelChangeEmail = false;
    }
  }

  reset() {
    super.reset();
    this.resetSettings();
  }
}
