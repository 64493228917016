import { AuthStore } from './auth/auth-store';
import { UserEmailStore } from './user/user-email-store';
import { AccountStore } from './account/account-store';
import { AccountStatsStore } from './account/account-stats-store';
import { AccountPlanStore } from './account/account-plan-store';
import { AccountCardStore } from './account/account-card-store';
import { BillingStore } from './account/billing-store';
import { MfaStore } from './auth/mfa-store';
import { UserPasswordStore } from './user/user-password-store';
import { ProjectsListStore } from './project/projects-list-store';
import { ProjectStore } from './project/project-store';
import { ProjectFilesStore } from './project/project-files-store';
import { ProjectWhitelistStore } from './project/project-whitelist-store';
import { ProjectWebHooksStore } from './project/project-web-hooks-store';
import { ProjectConvertDocumentsStore } from './project/project-convert-documents-store';
import { ProjectS3ForeignBucketStore } from './project/project-s3-foreign-bucket-store';
import { ProjectS3TargetsStore } from './project/project-s3-targets-store';
import { ProjectS3BackupStore } from './project/project-s3-backup-store';
import { ProjectOAuthAppsStore } from './project/project-o-auth-apps-store';
import { ProjectApiKeysStore } from './project/project-api-keys-store';
import { ProjectApiLogsStore } from './project/project-api-logs-store';
import { ProjectApiLogsSummaryStore } from './project/project-api-logs-summary-store';
import { ProjectFileStore } from './project/project-file-store';
import { ProjectAnalyticsStore } from './project/project-analytics-store';
import { ProjectTeamStore } from './project/project-team-store';
import { ProjectUploadsAuthStore } from './project/project-uploads-auth-store';
import { ProjectDemoStore } from './project/project-demo-store';
import { ProjectMimeTypesStore } from './project/project-mime-types-store';
import { AccountPaymentsStore } from './account/account-payments-store';
import { AccountPaymentsUpcomingStore } from './account/account-payments-upcoming-store';
import { ProjectProxyDomainsStore } from './project/project-proxy-domains-store';
import { PageStore } from './page-store';
import { ProjectForeignBucketStore } from './project/project-foreign-bucket-store';
import { AccountBillingInfoStore } from './account/account-billing-info-store';
import { ProjectFilesModeration } from './project/project-files-moderation';
import { ModerationCategories } from './project/moderation-categories';

export class AppStore {
  isCaptchaEnabled = null;

  captchaPublicKey = null;

  constructor() {
    this.isCaptchaEnabled = import.meta.env.VITE_CAPTCHA_ENABLED === 'true';
    this.captchaPublicKey = import.meta.env.VITE_CAPTCHA_PUBLIC_KEY;

    this.stores = {
      authStore: new AuthStore(this),
      accountStore: new AccountStore(this),
      accountPlanStore: new AccountPlanStore(this),
      accountCardStore: new AccountCardStore(this),
      accountStatsStore: new AccountStatsStore(this),
      accountPaymentsStore: new AccountPaymentsStore(this),
      accountPaymentsUpcomingStore: new AccountPaymentsUpcomingStore(this),
      accountBillingInfoStore: new AccountBillingInfoStore(this),
      billingStore: new BillingStore(this),
      mfaStore: new MfaStore(this),
      projectStore: new ProjectStore(this),
      projectsListStore: new ProjectsListStore(this),
      projectFilesStore: new ProjectFilesStore(this),
      projectFileStore: new ProjectFileStore(this),
      projectApiKeysStore: new ProjectApiKeysStore(this),
      projectApiLogsStore: new ProjectApiLogsStore(this),
      projectApiLogsSummaryStore: new ProjectApiLogsSummaryStore(this),
      projectWebHooksStore: new ProjectWebHooksStore(this),
      projectConvertDocumentsStore: new ProjectConvertDocumentsStore(this),
      projectS3BackupStore: new ProjectS3BackupStore(this),
      projectWhitelistStore: new ProjectWhitelistStore(this),
      projectS3ForeignBucketStore: new ProjectS3ForeignBucketStore(this),
      projectS3TargetsStore: new ProjectS3TargetsStore(this),
      projectOAuthAppsStore: new ProjectOAuthAppsStore(this),
      projectUploadsAuthStore: new ProjectUploadsAuthStore(this),
      projectProxyDomainsStore: new ProjectProxyDomainsStore(this),
      projectAnalyticsStore: new ProjectAnalyticsStore(this),
      projectTeamStore: new ProjectTeamStore(this),
      projectDemoStore: new ProjectDemoStore(this),
      projectMimeTypesStore: new ProjectMimeTypesStore(this),
      userEmailStore: new UserEmailStore(this),
      userPasswordStore: new UserPasswordStore(this),
      pageStore: new PageStore(this),
      projectForeignBucketStore: new ProjectForeignBucketStore(this),
      projectFilesModeration: new ProjectFilesModeration(this),
      moderationCategories: new ModerationCategories(this),
    };

    // call subscribe to reaction after init all stores
    Object.keys(this.stores).forEach((key) => {
      this.stores[key].onInit && this.stores[key].onInit();
    });
  }
}
