// @ts-check
import { observable, makeObservable, flow } from 'mobx';
import { RESPONSE_CODES } from '~/utils/http';
import { FORM_FIELD } from '~/pages/create-new-project/constants';
import { clarifyErrors } from '~/utils/clarify-errors';
import { BaseStore } from '../base-store';

/** @augments {BaseStore<string[]>} */
export class ProjectForeignBucketStore extends BaseStore {
  /**
   * @type {{
   *   isForeignBucketConnected: boolean;
   *   errors: string[] | null;
   * }}
   */
  foreignBucketConnection = {
    isForeignBucketConnected: false,
    errors: null,
  };

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      foreignBucketConnection: observable,
      checkForeignBucketConnection: flow,
    });
  }

  /**
   * @param {string} foreignBucketName
   * @returns {Generator<Promise<boolean>>}
   */
  *checkForeignBucketConnection(foreignBucketName) {
    this.isLoadingMap.checkForeignBucketConnection = true;

    try {
      const { status } = yield this.api.post('/apps/api/v0.1/services/s3/', {
        bucket_name: foreignBucketName,
      });

      this.foreignBucketConnection.isForeignBucketConnected = status === RESPONSE_CODES.NO_CONTENT;
    } catch (err) {
      const error = /** @type {import('~/types/http-api-error').HttpApiError} */ (err);

      this.foreignBucketConnection.errors = error.response
        ? clarifyErrors(error.response?.data.errors[FORM_FIELD.FOREIGN_BUCKET_NAME])
        : null;
    } finally {
      this.isLoadingMap.checkForeignBucketConnection = false;
    }
  }

  resetForeignBucketConnectionState() {
    this.foreignBucketConnection = {
      isForeignBucketConnected: false,
      errors: null,
    };
  }
}
