export const AnalyticsEventCategoryEnum = {
  ACCOUNT: 'Account',
  PROJECT: 'Project',
  LAYOUT: 'Layout',
};

/**
 * Send a custom event to GTM via dataLayer. https://support.google.com/tagmanager/answer/6164391
 *
 * @param {string} name Event name.
 * @param {object} [payload={}] Default is `{}`
 */
export const sendCustomGtmEvent = (name, payload = {}) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    event: name,
    ...payload,
  });
};

/**
 * Set variable in GTM dataLayer.
 *
 * @param {string} name Variable's name.
 * @param {string | number | boolean} value Variable's value.
 */
export const setGtmVariable = (name, value) => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  window.dataLayer.push({
    [name]: value,
  });
};
