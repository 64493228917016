// @ts-check
import { useMemo } from 'react';

import { useStores } from '~/stores';
import { daysUntilTrialEnds } from '~/utils/dayLeftInTrial';

const TRIAL_MAX_DAYS = 14;
const TRIAL_MIN_DAYS = 1;

/** @returns {{ isTrial: boolean; trialDaysLeft?: number }} */
export const useTrial = () => {
  const { accountStore } = useStores();
  const subscription = accountStore.data?.subscription;

  return useMemo(() => {
    if (!subscription?.is_trial || !subscription?.period_end) {
      return {
        isTrial: false,
      };
    }

    const daysLeft = daysUntilTrialEnds(subscription?.period_end ?? '');

    return {
      isTrial: true,
      trialDaysLeft: Math.max(TRIAL_MIN_DAYS, Math.min(daysLeft, TRIAL_MAX_DAYS)),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription]);
};
