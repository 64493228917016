// @ts-check
import React from 'react';

import { useTrial } from '~/hooks/useTrial';
import { MODALS_ENUM, useModal } from '~/components/Modal';
import { Button, ButtonSizeEnum, ButtonTypeEnum } from '@uc-common/button';
import { TrialUpgradeWidgetStyled } from './styles/TrialUpgradeWidget.styles';

import { ReactComponent as UpgradeIcon } from '../../assets/icon-upgrade-white.svg';

export const TrialUpgradeWidget = () => {
  const { trialDaysLeft } = useTrial();
  const [showSelectPlanModal] = useModal(MODALS_ENUM.SELECT_PLAN, {}, { isFullScreenModal: true });

  return (
    <TrialUpgradeWidgetStyled>
      <p>
        {trialDaysLeft} day{trialDaysLeft === 1 ? '' : 's'} left in free trial
      </p>
      <span>Upgrade to avoid interruption</span>

      <Button
        block
        themeType={ButtonTypeEnum.SUCCESS}
        size={ButtonSizeEnum.SMALL}
        data-analytics="btn_navigation_upgrade-from-trial"
        onClick={showSelectPlanModal}
      >
        <UpgradeIcon />
        Upgrade now
      </Button>
    </TrialUpgradeWidgetStyled>
  );
};
