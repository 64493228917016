import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '~/stores';

import { ONE_DAY } from '~/utils/date';
import { Beacon as BitBeacon } from '@uc-common/beacon';

const USERS_TYPES_ENUM = {
  NEW_USER: 'yes',
  OLD_USER: 'no',
};

export const Beacon = observer(() => {
  const { accountStore } = useStores();
  const accountData = accountStore.data;
  const key = import.meta.env.VITE_BEACON_ENABLED === 'true' && import.meta.env.VITE_BEACON_KEY;
  const signature = accountStore.beaconSignature;
  const timeAfterRegistration = Date.now() - new Date(accountData.created);
  // One day in milliseconds
  const diffInDays = Math.round(timeAfterRegistration / ONE_DAY);

  useEffect(() => {
    key && accountStore.getBeaconSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  if (!key || !signature) {
    return null;
  }

  return (
    <BitBeacon
      beaconKey={key}
      user={{
        signature,
        name: accountData.name,
        email: accountData.email,
        isNew: diffInDays < 14 ? USERS_TYPES_ENUM.NEW_USER : USERS_TYPES_ENUM.OLD_USER,
      }}
    />
  );
});
