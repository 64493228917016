import React from 'react';

export const SVGDefs = () => (
  <svg style={{ display: 'none' }}>
    <defs>
      <path
        id="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        d="M6.41666 10.6231L9.69047 13.9333L15.5833 7.97498"
        stroke="currentColor"
        strokeWidth="2"
      />
      <g id="cross">
        <path fill="currentColor" d="M4.091 0h.818v9h-.818z" />
        <path fill="currentColor" d="M0 4.91V4.09h9v.818z" />
      </g>
      <path id="dropdown-open" d="m1 1 3.75 3.5L8.5 1" stroke="currentColor" strokeWidth="1.2" />
      <path
        id="dropdown-close"
        xmlns="http://www.w3.org/2000/svg"
        d="M0 1H10"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <g id="outer-link-arrow">
        <path d="M2.75 1.5H8V6.75" stroke="currentColor" strokeWidth="1.2" />
        <path d="M8 1.5L1 8.5" stroke="currentColor" strokeWidth="1.2" />
      </g>
      <g id="google-logo">
        <path
          d="M15.534 6.546H8V9.64h4.306a3.68 3.68 0 0 1-1.597 2.414v2.008h2.585c1.513-1.393 2.386-3.444 2.386-5.88 0-.568-.05-1.113-.146-1.636Z"
          fill="#4285F4"
        />
        <path
          d="M8 16c2.16 0 3.97-.716 5.294-1.938l-2.585-2.008c-.716.48-1.633.765-2.709.765-2.084 0-3.847-1.408-4.476-3.299H.85v2.073A7.997 7.997 0 0 0 8 16Z"
          fill="#34A853"
        />
        <path
          d="M3.524 9.52A4.809 4.809 0 0 1 3.273 8c0-.527.09-1.04.25-1.52V4.407H.852a8.004 8.004 0 0 0 0 7.186L3.524 9.52Z"
          fill="#FBBC05"
        />
        <path
          d="M8 3.182c1.174 0 2.23.404 3.058 1.196l2.295-2.294C11.967.792 10.156 0 8 0A7.997 7.997 0 0 0 .85 4.407L3.525 6.48C4.153 4.59 5.916 3.182 8 3.182Z"
          fill="#EA4335"
        />
      </g>
      <g id="github-logo">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.582 0 0 3.672 0 8.203c0 3.624 2.292 6.697 5.471 7.783.4.075.546-.178.546-.396 0-.194-.006-.71-.01-1.395-2.226.496-2.695-1.099-2.695-1.099-.364-.948-.889-1.2-.889-1.2-.726-.508.055-.499.055-.499.803.058 1.225.846 1.225.846.714 1.253 1.873.891 2.329.68.073-.53.28-.89.508-1.095-1.776-.208-3.645-.912-3.645-4.054 0-.896.312-1.628.824-2.202-.083-.207-.357-1.041.08-2.17 0 0 .67-.22 2.198.84A7.484 7.484 0 0 1 8 3.968c.677.001 1.35.094 2.003.276C11.53 3.18 12.2 3.402 12.2 3.402c.436 1.129.162 1.963.08 2.17.513.574.822 1.307.822 2.201 0 3.151-1.871 3.846-3.653 4.048.287.254.543.754.543 1.52 0 1.095-.009 1.98-.009 2.25 0 .219.144.474.55.394C13.71 14.898 16 11.825 16 8.203 16 3.672 12.418 0 8 0Z"
          fill="#000"
        />
      </g>
    </defs>
  </svg>
);
