import { useCallback, useState } from 'react';
import { useRollbarPerson } from '@rollbar/react';

import { detectSignUp } from '~/analytics/utils/account';
import { noCache } from '~/stores/base-store';
import { useStores } from '~/stores';
import { useErrorHandler } from './useErrorHandler';
import { useNavigateNextRoute } from './useNextRoute';

export const useAuthentication = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { accountStore, projectsListStore } = useStores();
  const handleError = useErrorHandler();
  useRollbarPerson(accountStore?.rollbarPerson ?? {});

  const navigateNextRoute = useNavigateNextRoute();

  const authenticate = useCallback(async () => {
    setIsLoading(true);

    try {
      await Promise.all([
        !accountStore.data &&
          accountStore.fetchData({ config: noCache, statusesOfSilentErrors: [403] }),
        projectsListStore.fetchData({ config: noCache, statusesOfSilentErrors: [403] }),
      ]);
      accountStore.setIsAuthenticated(true);
      if (accountStore.data?.created) {
        detectSignUp(accountStore.data?.user?.id, accountStore.data.created);
      }
      navigateNextRoute();
    } catch (e) {
      handleError(e);
    } finally {
      setIsLoading(false);
    }
  }, [accountStore, projectsListStore, handleError, navigateNextRoute]);

  return [authenticate, isLoading];
};
