import styled from 'styled-components';

export const ModalTitleWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  padding: 17px var(--modal-horizontal-padding);
  border-bottom: 1px solid #f1f2f3;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  background-color: #ffffff;
  border-radius: var(--modal-border-radius) var(--modal-border-radius) 0 0;
  z-index: 100;
`;

export const ModalTitleHeading = styled.h2`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: -0.025em;
  color: #000000;
`;

export const ModalTitleSupportiveText = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 1.6;
  color: #8d8d8d;
  margin-bottom: 12px;
`;
