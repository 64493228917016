// @ts-check
import axios from 'axios';
import { action, computed, makeObservable, observable } from 'mobx';
import { formatDistance } from 'date-fns';
import isEqual from 'react-fast-compare';
import { setupCache, buildMemoryStorage, defaultKeyGenerator } from 'axios-cache-interceptor';
import { storeNextRoute } from '~/hooks/useNextRoute';
import { getCookie } from '@uc-common/use-cookie';
import { isCsrfMethodRequired } from '~/utils/check-value';
import { ONE_HOUR } from '~/utils/date';
import { ErrorKeyEnum } from '~/hooks/useErrorHandler';
import { RoutesEnum } from '~/App/Routing/routes';
import { message } from '~/components/Toaster';

/** @typedef {import('axios').AxiosRequestConfig} AxiosRequestConfig */
/** @typedef {import('axios').AxiosResponse} AxiosResponse */
/** @typedef {import('axios-cache-interceptor').CacheRequestConfig} CacheRequestConfig */

export const restApi = {
  version: '0.7',
  refsUrl: '/api-refs/rest-api/v0.7.0/',
};

const ResponseCodeEnum = {
  VALIDATION_ERROR: 'validation_error',
  PERMISSION_ERROR: 'permission_error',
};

const NO_AUTH_ERROR = 'Authentication credentials were not provided.';
const CSRF_ERROR_PREFIX = 'CSRF Failed';

/**
 * https://axios-cache-interceptor.js.org
 *
 * Axios-cache-interceptor will wait for the completion of the first request and return its result,
 * rather than execute a new request.
 *
 * @type {import('axios-cache-interceptor').AxiosCacheInstance}
 */
export const cache = setupCache(axios.create(), {
  storage: buildMemoryStorage(),
  ttl: 1000,
  methods: ['get'],
  generateKey: defaultKeyGenerator,
  cachePredicate: {
    statusCheck: (/** @type {number} */ status) => status >= 200 && status < 400,
  },
  // return cache if server not responding
  staleIfError: true,
  // consider server response headers
  interpretHeader: true,
  // https://axios-cache-interceptor.js.org/config#debug
  // debug: console.log,
  // disables cache, useful for debug
  // override: true,
});

/** @returns {Promise<void>} */
export const clearRequestsCache = async () => {
  try {
    if (typeof cache?.storage?.clear === 'function') {
      await cache.storage.clear();
    }
  } catch (error) {
    if (window?.Rollbar && error instanceof Error) {
      window.Rollbar.error(`Error while clearing cache: ${error.message}`, error);
    } else {
      throw error;
    }
  }
};

/** @type {CacheRequestConfig} */
export const noCache = {
  cache: false,
};

/** @type {CacheRequestConfig} */
export const longCache = {
  cache: {
    ttl: ONE_HOUR,
  },
};

/**
 * @template T
 * @template {Record<string, boolean>} [LoadingMap={}]
 */
export class BaseStore {
  /** @type {T | null} */
  initialData = null;

  /** @type {T | null} */
  data = this.initialData;

  isLoading = false;

  isInitialLoading = true;

  /** @type {LoadingMap} */
  isLoadingMap = /** @type {LoadingMap} */ ({});

  /** @param {import('./app-store.js').AppStore} appStore */
  constructor(appStore) {
    makeObservable(this, {
      isLoading: observable,
      isInitialLoading: observable,
      isLoadingMap: observable,
      api: computed,
      baseURL: computed,
      processErrors: action,
      hasData: computed,
      reset: action,
      setIsInitialLoading: action,
      setIsLoadingMapByKey: action,
    });

    this.appStore = appStore;
  }

  // add mobx reaction in this handler
  onInit() {}

  get baseURL() {
    return '';
  }

  get api() {
    const api = cache;
    api.defaults.baseURL = `${import.meta.env.VITE_API_BASE_URL ?? ''}${this.baseURL}`;

    api.interceptors.request.use(
      /** @param {import('axios').InternalAxiosRequestConfig} config */ (config) => {
        if (config.method && isCsrfMethodRequired(config.method)) {
          config.headers['X-CSRFTOKEN'] = getCookie('csrftoken');
        }

        return config;
      }
    );

    api.interceptors.response.use(
      (res) => res,
      (error) => {
        const { code, errors } = error.response?.data ?? {};

        if (
          code === ResponseCodeEnum.PERMISSION_ERROR &&
          errors[ErrorKeyEnum.NON_FIELD_ERRORS]?.[0]?.startsWith?.(CSRF_ERROR_PREFIX)
        ) {
          return this.handleCsrfError(error.config);
        }

        if (error.response?.status === 429) {
          return this.handleApiThrottling(error);
        }

        throw error;
      }
    );

    return api;
  }

  get hasData() {
    if (this.initialData) {
      return !isEqual(this.data, this.initialData);
    }

    if (Array.isArray(this.data)) {
      return this.data.length !== 0;
    }

    return this.data !== null;
  }

  /** @param {boolean} isLoading */
  setIsInitialLoading(isLoading) {
    this.isInitialLoading = isLoading;
  }

  /**
   * @template {keyof LoadingMap} K
   * @param {K} key
   * @param {LoadingMap[K]} value
   */
  setIsLoadingMapByKey(key, value) {
    this.isLoadingMap[key] = value;
  }

  /**
   * BaseStore.processErrors is deprecated. Please use useErrorHandler hook from your components
   * instead of handling errors in stores. Common HTTP errors should be handled by interceptors.
   *
   * @param {Error | import('axios').AxiosError} e
   * @param {{ statusesOfSilentErrors?: number[] }} options
   */
  processErrors(e, { statusesOfSilentErrors } = {}) {
    if (!statusesOfSilentErrors) {
      statusesOfSilentErrors = [];
    }

    const isAxiosError = 'isAxiosError' in e && e.isAxiosError;

    const errors = [];
    const resData = (isAxiosError && e?.response?.data) ?? undefined;
    const isValidationError = resData?.code === ResponseCodeEnum.VALIDATION_ERROR;
    const isPermissionError = resData?.code === ResponseCodeEnum.PERMISSION_ERROR;
    const errorMessage = resData?.errors?.[ErrorKeyEnum.NON_FIELD_ERRORS]?.[0] ?? '';

    // Common 403 error handler for backward compatibility with component which use processErrors
    // instead of useErrorHandler hook.
    if (errorMessage === NO_AUTH_ERROR && this.appStore.stores.accountStore?.isAuthenticated) {
      // Could not make redirect properly with react-dom-router outside a component,
      // so we need to set isAuthenticated to false manually.
      this.appStore.stores.accountStore.setIsLogout(true);
      this.appStore.stores.accountStore.setIsAuthenticated(false);
      const { hash, pathname, search } = document.location;
      storeNextRoute(pathname + search + hash);
      document.location.href = RoutesEnum.SESSION_EXPIRED;
      return;
    }

    // don't show message error if statuses of silent errors includes response status
    // don't show message error if account is blocked, because user already see block view
    const showErrorMessage =
      isAxiosError &&
      e.response?.status &&
      !statusesOfSilentErrors.includes(e.response.status) &&
      !this.appStore.stores.accountStore.isBlocked;
    if (showErrorMessage) {
      if (isValidationError || isPermissionError) {
        Object.keys(resData?.errors).forEach((errorKey) => {
          if (errorKey === ErrorKeyEnum.NON_FIELD_ERRORS) {
            resData.errors[errorKey].forEach(
              /** @param {string} error */ (error) => errors.push(error)
            );
          }
        });

        if (errors.length === 0) {
          errors.push('Validation error.');
        }

        errors.forEach((error) => message.error(error));
      } else {
        message.error(resData?.detail);
      }
    }

    throw e;
  }

  reset() {
    this.data = this.initialData;
    this.isLoading = false;
    this.isInitialLoading = true;
    this.isLoadingMap = /** @type {LoadingMap} */ ({});
  }

  /**
   * @param {AxiosRequestConfig} config
   * @returns {Promise<AxiosResponse>}
   */
  async handleCsrfError(config) {
    if (!config.headers) {
      config.headers = {};
    }

    const { headers } = config;
    const body = {
      csrftoken: headers['X-CSRFTOKEN'] ?? 'old_token',
    };

    try {
      await axios.post('/apps/api/v0.1/csrf/', body);
      headers['X-CSRFTOKEN'] = getCookie('csrftoken');

      // Resending the original request with a new token
      return axios.request(config);
    } catch (error) {
      if (error instanceof Error) {
        window?.Rollbar?.error(`CSRF token error: ${error.message}`, error);
      }

      throw error;
    }
  }

  /**
   * @param {{
   *   config: AxiosRequestConfig & { retries?: number };
   *   response: AxiosResponse;
   * }} error
   * @returns {Promise<AxiosResponse>}
   */
  handleApiThrottling(error) {
    const { config, response } = error;

    const retryTimeout = response.headers['retry-after'] ?? 1;
    if (retryTimeout > 60) {
      if (response.data?.errors?.non_field_errors?.length) {
        const now = Date.now();
        const seconds = response.data.errors.non_field_errors[0].match(/(\d+) seconds/)?.[1];
        if (seconds) {
          response.data.errors.non_field_errors = [
            `Too many requests, please retry in ${formatDistance(
              now + Number(seconds) * 1000,
              now
            )}.`,
          ];
        }
      }
      throw error;
    }

    return new Promise((resolve, reject) => {
      if (!config.retries) {
        config.retries = 5;
      }

      if (config.retries < 1) {
        reject(new Error(`Timed out retrying to make ${config.method} ${config.url}`));
      } else {
        config.retries--;
        setTimeout(
          () => resolve(axios.request(config)), // Re-send failed request.
          Number(retryTimeout) * 1000
        );
      }
    });
  }
}
