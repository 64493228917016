import React from 'react';

export const SignIn = React.lazy(() => import('../../pages/sign-in/index'));
export const SignInSso = React.lazy(() => import('../../pages/sign-in-sso/index'));
export const SignUp = React.lazy(() => import('../../pages/sign-up/index'));
export const PendingEmailVerification = React.lazy(() =>
  import('../../pages/pending-email-verification/index')
);
export const SocialsAuthorization = React.lazy(() =>
  import('../../pages/socials-authorization/index')
);
export const MfaVerification = React.lazy(() => import('../../pages/mfa-verification/index'));
export const TokenAuthentication = React.lazy(() =>
  import('../../pages/token-authentication/index')
);
