export const supportLink = {
  href: 'https://help.uploadcare.com/',
  target: '_blank',
};

export const mailToLink = {
  hello: {
    href: 'mailto:hello@uploadcare.com',
    children: 'hello@uploadcare.com',
  },
  help: {
    href: 'mailto:help@uploadcare.com',
    children: 'help@uploadcare.com',
  },
};
