import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTrial } from '~/hooks/useTrial';

import { RoutesEnum } from '~/App';
import { useStores } from '~/stores';

import { ButtonTypeEnum } from '@uc-common/button';
import { ProjectDropdown, NavLink } from './components';
import { TrialUpgradeWidget } from './components/TrialUpgradeWidget';
import { UpgradeFromFreePlanButton } from '../UpgradeFromFreePlanButton';

import { icons } from './icons';
import { ReactComponent as UpgradeIcon } from './assets/icon-upgrade.svg';

import {
  NavigationGlobalStyles,
  NavigationContainer,
  NavigationContent,
  NavigationHeader,
  NavigationLinksContainer,
  NavigationTitle,
  NavigationBlock,
  NavigationLogo,
  NavigationUpgradeBlock,
} from './styles/Navigation.styles';

const CustomButtonTheme = {
  colors: {
    defaultColor: '#495560',
    hoverBackgroundColor: '#f4fbf4',
    hoverColor: '#29b11d',

    focusOutlineColor: 'rgba(80, 153, 46, 0.3)',
    focusBackgroundColor: '#f4fbf4',
    focusColor: '#495560',

    activeBackgroundColor: '#def5de',
    activeColor: '#29b11d',
  },
};

const getNavigationLinks = (pubKey, isSharedProject) =>
  pubKey
    ? [
        {
          items: [
            {
              text: 'Get started',
              to: generatePath(RoutesEnum.PROJECT_GET_STARTED, { pubKey }),
              Icon: icons.getStarted,
              'data-analytics': 'btn_left-menu_get-started',
              id: 'dashboard-tour-get-started',
            },
            {
              text: 'Files',
              to: generatePath(RoutesEnum.PROJECT_FILES_INDEX, { pubKey }),
              Icon: icons.files,
              'data-analytics': 'btn_left-menu_files',
            },
            {
              text: 'Transformations',
              to: generatePath(RoutesEnum.PROJECT_DELIVERY_IMAGE_PROCESSING, {
                pubKey,
              }),
              Icon: icons.imageProcessing,
              'data-analytics': 'btn_left-menu_image-processing',
            },
            {
              text: 'Analytics',
              to: generatePath(RoutesEnum.PROJECT_ANALYTICS, { pubKey }),
              Icon: icons.usage,
              'data-analytics': 'btn_left-menu_analytics',
            },
            {
              text: 'Settings',
              to: generatePath(RoutesEnum.PROJECT_SETTINGS, { pubKey }),
              Icon: icons.settings,
              'data-analytics': 'btn_left-menu_account-settings',
            },
            {
              text: 'API keys',
              to: generatePath(RoutesEnum.PROJECT_API_KEYS, { pubKey }),
              Icon: icons.keys,
              'data-analytics': 'btn_left-menu_api-keys',
            },
            {
              text: 'Webhooks',
              to: generatePath(RoutesEnum.PROJECT_API_WEBHOOKS, { pubKey }),
              Icon: icons.webhooks,
              'data-analytics': 'btn_left-menu_webhooks',
            },
            {
              text: 'Logs',
              to: generatePath(RoutesEnum.PROJECT_API_LOGS, { pubKey }),
              Icon: icons.accessLogs,
              'data-analytics': 'btn_left-menu_api-logs',
            },
          ],
        },
        ...(isSharedProject
          ? []
          : [
              {
                items: [
                  {
                    text: 'Billing',
                    to: RoutesEnum.BILLING,
                    Icon: icons.billing,
                    'data-analytics': 'btn_left-menu_billing',
                  },
                ],
              },
            ]),
      ]
    : [
        {
          items: [
            {
              text: 'Workspace',
              to: RoutesEnum.HOME,
              Icon: icons.workspace,
              'data-analytics': 'btn_left-menu_workspace',
            },
            {
              text: 'Billing',
              to: RoutesEnum.BILLING,
              Icon: icons.billing,
              'data-analytics': 'btn_left-menu_billing',
            },
            {
              text: 'Profile',
              to: RoutesEnum.PROFILE,
              Icon: icons.profile,
              'data-analytics': 'btn_left-menu_profile',
            },
          ],
        },
      ];

export const Navigation = observer(() => {
  const { isTrial } = useTrial();
  const { projectStore, accountStore, accountPlanStore } = useStores();

  const pubKey = projectStore.project?.pub_key;
  const isFreeAccount = accountStore.isFree;
  const isSharedProject = projectStore.isShared;
  const planToUpgrade = accountPlanStore.nextPlan;
  const isShowUpgradeButton = isFreeAccount && planToUpgrade && !isSharedProject;
  const links = useMemo(
    () => getNavigationLinks(pubKey, isSharedProject),
    [pubKey, isSharedProject]
  );

  return (
    <NavigationContainer>
      <NavigationGlobalStyles />
      <NavigationHeader>
        {pubKey ? (
          <ProjectDropdown />
        ) : (
          <NavigationLogo uuid="a36fb017-625b-4479-b121-421f06338565" />
        )}
      </NavigationHeader>

      <NavigationContent>
        <NavigationLinksContainer>
          {isShowUpgradeButton && (
            <NavigationUpgradeBlock>
              <UpgradeFromFreePlanButton
                themeType={ButtonTypeEnum.SUCCESS}
                transparent
                customTheme={CustomButtonTheme}
                block
                icon={UpgradeIcon}
                data-analytics="btn_upgrade-navigation"
              >
                {pubKey ? null : 'Upgrade workspace'}
              </UpgradeFromFreePlanButton>
            </NavigationUpgradeBlock>
          )}
          {links.map(({ text, items = [] }, index) => (
            <NavigationBlock key={index}>
              {Boolean(text) && <NavigationTitle>{text}</NavigationTitle>}
              {items.map(({ text, badge, Icon, ...link }) => (
                <NavLink key={text + badge} badge={badge} Icon={Icon} {...link}>
                  {text}
                </NavLink>
              ))}
            </NavigationBlock>
          ))}
        </NavigationLinksContainer>
        {isTrial && <TrialUpgradeWidget />}
      </NavigationContent>
    </NavigationContainer>
  );
});
