import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { AuthorizedLayout } from '../Wrappers/AuthorizedLayout';
import { RoutesEnum } from '../../routes';
import { useStores } from '../../../../stores/index';

export const RequireAuth = observer(({ layoutComponent = AuthorizedLayout }) => {
  const { accountStore } = useStores();
  const { isAuthenticated } = accountStore;
  const LayoutComponent = layoutComponent;

  return isAuthenticated ? (
    <LayoutComponent>
      <Outlet />
    </LayoutComponent>
  ) : (
    <Navigate to={RoutesEnum.SIGN_IN} />
  );
});
