const DEFAULT_BEATIFY_OPTIONS = {
  wrap_attributes: 'force-expand-multiline',
  brace_style: 'collapse,preserve-inline',
  max_preserve_newlines: 2,
  disableBeautify: false,
};

const LARGE_JSON_LENGTH = 80;

export const getBeautifiedOptions = (options = {}, code = '', language = '') => {
  const mergedBeatifyOptions = {
    ...DEFAULT_BEATIFY_OPTIONS,
    ...options,
  };

  if (language === 'json') {
    // Forced expand  large/multi-braced one-line json code from API.
    const isBraceStyleOverride =
      mergedBeatifyOptions.brace_style !== DEFAULT_BEATIFY_OPTIONS.brace_style;
    const hasOneMoreBraceLevel = code.lastIndexOf('{') !== code.indexOf('{');
    if (!isBraceStyleOverride && (hasOneMoreBraceLevel || code.length > LARGE_JSON_LENGTH)) {
      mergedBeatifyOptions.brace_style = 'expand';
    }
  }

  return mergedBeatifyOptions;
};
