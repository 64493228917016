import React from 'react';
import { useScrollbarWidth } from 'react-use';
import { SetVW } from '../SetVW';
import { Vars } from './Vars';

export const AppTools = () => {
  const sbw = useScrollbarWidth();

  return (
    <>
      <Vars scrollBarWidth={sbw} />
      <SetVW />
    </>
  );
};
