import React, { useEffect, lazy } from 'react';
import { generatePath, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ProtectedRoute } from '~/components/ProtectedRoute';
import { redirects } from '~/App/Routing/redirects';
import { RoutesEnum } from '~/App';
import { AccountStatusEnum } from '~/stores/account/account-store';

import { BasicLayout, FullscreenLayout, ProjectWrapper } from './components/Wrappers';
import { RequireAuth } from './components/RequireAuth';
import { useStores } from '../../stores/index';
import { RequireNonAuth } from './components/RequireNonAuth';

import * as NonAuthenticatedPages from './nonAuthenticatedPages';
import * as DashboardPages from './dashboardPages';

const ApproveEmail = lazy(() => import('../../pages/approve-email'));
const BlockedAccount = lazy(() => import('../../pages/blocked-account'));
const SessionExpired = lazy(() => import('../../pages/session-expired'));
const TeamInvitation = lazy(() => import('../../pages/team-invitation'));

export const Routing = observer(() => {
  const { projectTeamStore, projectStore, accountStore } = useStores();

  const { pathname } = useLocation();
  const pubKey = pathname.split('projects/')[1]?.split('/')[0] ?? projectStore?.pubKey;
  const isAccountUnpaid = accountStore.data?.status === AccountStatusEnum.UNPAID;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAccountUnpaid) return;

    navigate(RoutesEnum.BILLING);
  }, [isAccountUnpaid, navigate]);

  return (
    <Routes>
      {redirects.map(({ from, to }) => (
        <Route
          key={from}
          path={from}
          element={<Navigate to={generatePath(to, { pubKey: pubKey ?? '' })} replace />}
        />
      ))}

      <Route element={<BasicLayout />}>
        <Route path={RoutesEnum.APPROVE_EMAIL} element={<ApproveEmail />} />
        <Route path={RoutesEnum.TEAM_INVITATION} element={<TeamInvitation />} />
        <Route
          path={RoutesEnum.BLOCKED_ACCOUNT}
          element={
            <ProtectedRoute enabled={accountStore?.isBlocked} redirectTo={RoutesEnum.HOME}>
              <BlockedAccount />
            </ProtectedRoute>
          }
        />

        <Route
          path={RoutesEnum.SESSION_EXPIRED}
          element={
            <ProtectedRoute
              // Check for !isAuthenticated instead of isLogout,
              // because we cannot redirect to this component properly from BaseStore.processErrors.
              enabled={!accountStore?.isAuthenticated}
              redirectTo={RoutesEnum.HOME}
            >
              <SessionExpired />
            </ProtectedRoute>
          }
        />

        <Route element={<RequireNonAuth />}>
          <Route path={RoutesEnum.SIGN_IN} element={<NonAuthenticatedPages.SignIn />} />
          <Route path={RoutesEnum.SIGN_IN_SSO} element={<NonAuthenticatedPages.SignInSso />} />
          <Route path={RoutesEnum.SIGN_UP} element={<NonAuthenticatedPages.SignUp />} />
          <Route
            path={RoutesEnum.SIGN_UP_SUCCESS}
            element={<NonAuthenticatedPages.PendingEmailVerification />}
          />
          <Route
            path={RoutesEnum.SOCIALS_AUTHORIZATION}
            element={<NonAuthenticatedPages.SocialsAuthorization />}
          />

          <Route
            path={RoutesEnum.MFA_VERIFICATION}
            element={<NonAuthenticatedPages.MfaVerification />}
          />

          <Route
            path={RoutesEnum.TOKEN_AUTH}
            element={<NonAuthenticatedPages.TokenAuthentication />}
          />

          <Route path="*" element={<Navigate to={RoutesEnum.SIGN_IN} />} />
        </Route>
      </Route>

      <Route element={<RequireAuth layoutComponent={FullscreenLayout} />}>
        <Route path={RoutesEnum.QUALIFICATION} element={<DashboardPages.Qualification />} />
      </Route>

      <Route element={<RequireAuth layoutComponent={FullscreenLayout} />}>
        <Route path={RoutesEnum.CREATE_NEW_PROJECT} element={<DashboardPages.CreateNewProject />} />
      </Route>

      <Route element={<RequireAuth />}>
        <Route
          path={RoutesEnum.HOME}
          index
          element={
            <ProtectedRoute
              // must wait process redirect in JoinSharedProject component
              // if store has projectTeamStore.pendingInvitation
              enabled={!projectTeamStore.pendingInvitation}
            >
              <DashboardPages.Workspace />
            </ProtectedRoute>
          }
        />

        <Route element={<ProjectWrapper />}>
          <Route
            path={RoutesEnum.PROJECT}
            element={
              <Navigate
                to={generatePath(RoutesEnum.PROJECT_FILES_INDEX, {
                  pubKey: pubKey ?? '',
                })}
              />
            }
          />
          <Route
            path={RoutesEnum.PROJECT_GET_STARTED}
            element={<DashboardPages.ProjectGetStarted />}
          />
          <Route
            path={RoutesEnum.PROJECT_GET_STARTED_OPTIMIZE}
            element={<DashboardPages.OptimizeImagesPage />}
          />
          <Route
            path={RoutesEnum.PROJECT_GET_STARTED_TRANSFORMATIONS}
            element={<DashboardPages.TransformImagesPage />}
          />
          <Route
            path={RoutesEnum.PROJECT_GET_STARTED_UPLOAD_ASSETS}
            element={<DashboardPages.UploadAssetsPage />}
          />
          <Route
            path={RoutesEnum.PROJECT_GET_STARTED_UPLOAD_API}
            element={<DashboardPages.UploadApiPage />}
          />
          <Route path={RoutesEnum.PROJECT_FILES_INDEX} element={<DashboardPages.ProjectFiles />} />
          <Route path={RoutesEnum.PROJECT_FILES_FILE} element={<DashboardPages.ProjectFiles />} />
          <Route path={RoutesEnum.PROJECT_API_KEYS} element={<DashboardPages.ProjectApiKeys />} />
          <Route
            path={RoutesEnum.PROJECT_API_LOGS_ITEM}
            element={<DashboardPages.ProjectApiLogsItem />}
          />
          <Route path={RoutesEnum.PROJECT_API_LOGS} element={<DashboardPages.ProjectApiLogs />} />
          <Route
            path={RoutesEnum.PROJECT_UPLOADING_WIDGET}
            element={<DashboardPages.ProjectUploadingWidget />}
          />
          <Route
            path={RoutesEnum.PROJECT_DELIVERY_IMAGE_PROCESSING}
            element={<DashboardPages.ProjectDeliveryImageProcessing />}
          />
          <Route
            path={RoutesEnum.PROJECT_DELIVERY_QUICKSTART}
            element={<DashboardPages.ProjectDeliveryQuickStart />}
          />
          <Route
            path={RoutesEnum.PROJECT_API_WEBHOOKS}
            element={<DashboardPages.ProjectApiWebhooks />}
          />
          <Route path={RoutesEnum.PROJECT_SETTINGS} element={<DashboardPages.ProjectSettings />} />
          <Route
            path={RoutesEnum.PROJECT_ANALYTICS}
            element={<DashboardPages.ProjectAnalytics />}
          />

          <Route
            path={RoutesEnum.PROJECT_UPLOADING_BLOCKS}
            element={<DashboardPages.ProjectUploadingBlocks />}
          />
        </Route>

        <Route path={RoutesEnum.PROFILE} element={<DashboardPages.Profile />} />

        <Route
          path={RoutesEnum.PASSWORD_CHANGE_CONFIRMATION}
          element={<DashboardPages.PasswordChangeConfirmation />}
        />

        <Route path={RoutesEnum.BILLING} element={<DashboardPages.Billing />} />

        <Route path="*" element={<DashboardPages.Workspace />} />
      </Route>
    </Routes>
  );
});
