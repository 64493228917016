import React from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { InputSpinner } from '@uc-common/spinner';

export const Loader = ({ isLoading = false, className }) => {
  if (!isLoading) return null;

  return (
    <div
      className={twMerge(
        `absolute left-0 top-0 w-full h-full flex items-center justify-center z-[100] bg-white/60`,
        className
      )}
    >
      <InputSpinner />
    </div>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};
