// @ts-check
import { css } from 'styled-components';

/**
 * @param {string} thumbColor
 * @param {string} barColor
 * @param {'x' | 'y'} orientation
 */
export const customScrollbar = (thumbColor, barColor, orientation) => css`
  overflow-y: ${orientation === 'y' ? 'auto' : 'unset'};
  overflow-x: ${orientation === 'x' ? 'auto' : 'unset'};

  /* WebKit (Safari, Chrome) */
  @supports selector(::-webkit-scrollbar) {
    &::-webkit-scrollbar {
      ${orientation === 'x' ? 'height' : 'width'}: 4px;
    }

    &::-webkit-scrollbar-track {
      background: ${barColor};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${thumbColor};
      border-radius: 8px;
    }
  }

  /* Firefox */
  @supports (scrollbar-color: auto) and (scrollbar-width: thin) {
    scrollbar-width: thin;
    scrollbar-color: ${thumbColor} ${barColor};
  }
`;
