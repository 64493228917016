// @ts-check
import styled, { css, keyframes } from 'styled-components';
import { ButtonSizeEnum } from '../ButtonSizeEnum';

/** @typedef {{ colors: { [key: string]: string } }} currentTheme */

/**
 * @param {{ $currentTheme: currentTheme }} props
 * @returns {import('styled-components').Interpolation<object>}
 */
const StateStyles = ({ $currentTheme }) => css`
  &,
  &:visited {
    background-color: ${$currentTheme.colors.defaultBackgroundColor};
    color: ${$currentTheme.colors.defaultColor};

    ${$currentTheme.colors.defaultBorderColor &&
    css`
      border: 1px solid ${$currentTheme.colors.defaultBorderColor};
    `};
  }

  &:hover,
  &:visited:hover {
    box-shadow: none;
    background-color: ${$currentTheme.colors.hoverBackgroundColor};
    color: ${$currentTheme.colors.hoverColor};
    transition: all 0.2s ease-in-out;

    ${$currentTheme.colors.hoverBorderColor &&
    css`
      border: 1px solid ${$currentTheme.colors.hoverBorderColor};
    `};
  }

  &:focus,
  &:visited:focus {
    box-shadow: none;
    outline: 0;
    background-color: ${$currentTheme.colors.focusBackgroundColor};
    color: ${$currentTheme.colors.focusColor};
    transition: all 0.2s ease-in-out;

    ${$currentTheme.colors.focusBorderColor &&
    css`
      border: 1px solid ${$currentTheme.colors.focusBorderColor};
    `};
  }

  &:active,
  &:visited:active {
    background-color: ${$currentTheme.colors.activeBackgroundColor};
    color: ${$currentTheme.colors.activeColor};
    transition: all 0.2s ease-in-out;
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      background-color: ${$currentTheme.colors.disabledBackgroundColor};
      color: ${$currentTheme.colors.disabledColor};
      pointer-events: none;

      ${$currentTheme.colors.disabledBorderColor &&
      css`
        border: 1px solid ${$currentTheme.colors.disabledBorderColor};
      `};
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${$currentTheme.colors.focusOutlineColor || '#b7ccff'};
  }
`;

/**
 * @typedef {import('styled').StyledComponent<
 *   'button',
 *   {
 *     $alignmentText?: 'center' | 'start' | 'end';
 *     $isIcon?: boolean;
 *   }
 * >} ButtonContentProp
 */
export const ButtonContent = /** @type {ButtonContentProp} */ (styled.span)(
  ({ $alignmentText, $isIcon }) => css`
    display: flex;
    flex-grow: ${$isIcon ? '1' : '0'};
    align-items: center;
    justify-content: ${$alignmentText};
  `
);

const showSpinner = keyframes`
  to {
    opacity: 1;
  }
`;

export const ButtonSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
  height: max-content;
  opacity: 0;
  animation: ${showSpinner} 0.2s ease-in forwards 0.65s;
`;

/**
 * @typedef {import('styled').StyledComponent<
 *   'button',
 *   {
 *     $size?: string;
 *     $long?: boolean;
 *     $block?: boolean;
 *     $isLoading?: boolean;
 *     $alignmentText?: 'center' | 'start' | 'end';
 *     $isIcon: boolean;
 *   }
 * >} StyledButtonProp
 */
export const StyledButton = /** @type {StyledButtonProp} */ (styled.button)(
  ({ $size, $long, $block, $isLoading, $alignmentText, $isIcon }) => css`
    box-sizing: border-box;
    display: inline-flex;
    justify-content: ${$isIcon ? 'center' : $alignmentText};
    align-items: center;
    gap: ${$alignmentText ? '13px' : '8px'};

    font-family: inherit;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;

    border: none;
    border-radius: 5px;
    box-shadow: 0 0 0 0 transparent;

    position: relative;
    cursor: pointer;

    transition: all 0.2s ease-in-out;

    ${
      // @ts-ignore
      StateStyles
    };

    ${() => {
      switch ($size) {
        case ButtonSizeEnum.EXTRA_SMALL:
          return css`
            height: 32px;
            padding: 0 12px;
            border-radius: 8px;
          `;
        case ButtonSizeEnum.SMALL:
          return css`
            height: 38px;
            padding: 0 14px;
            border-radius: 8px;
          `;
        case ButtonSizeEnum.NORMAL:
          return css`
            height: 44px;
            padding: 0 20px;
            font-size: 14px;
            border-radius: 10px;
          `;
        case ButtonSizeEnum.LARGE:
          return css`
            height: 52px;
            padding: 0 25px;
            font-size: 15px;
            border-radius: 12px;
          `;
        default:
          return '';
      }
    }}

    width: ${$block && '100%'};
    padding: ${$long && '0 28px'};

    ${() =>
      $isLoading &&
      css`
        pointer-events: none;

        ${ButtonContent} {
          opacity: 0;
          transform: scale(0.9);
          transition-delay: 0.45s;
        }
      `}
  `
);
