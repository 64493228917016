import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { storeNextRoute } from './useNextRoute';

export const useRedirect = () => {
  const { hash, pathname, search } = useLocation();
  const navigate = useNavigate();

  return useCallback(
    /**
     * @param {string} routeToRedirect
     * @param {string[]} [excludedRoutes=[]] Default is `[]`
     * @param {boolean} [doStoreNextRoute=false] Default is `false`
     */
    (routeToRedirect, excludedRoutes = [], doStoreNextRoute = false) => {
      //  we're not redirecting to the same page or from the routes that are passed in the excludedRoutes array
      if (
        !matchPath(routeToRedirect, pathname) &&
        !excludedRoutes.find((route) => matchPath(route, pathname))
      ) {
        if (doStoreNextRoute) {
          storeNextRoute(pathname + search + hash);
        }
        navigate(routeToRedirect);
      }
    },
    [navigate, hash, pathname, search]
  );
};
