import React, { useEffect, forwardRef, useState, useCallback } from 'react';
import Prism from 'prismjs';
import 'prismjs/plugins/remove-initial-line-feed/prism-remove-initial-line-feed.min';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.min';
import 'prismjs/plugins/autolinker/prism-autolinker.min';
import 'prismjs/plugins/autolinker/prism-autolinker.css';
import { html_beautify as htmlBeautify, js_beautify as jsBeautify } from 'js-beautify';
import 'prismjs/plugins/autoloader/prism-autoloader.min';

import {
  CodeButtonsContainer,
  SandboxCodeExampleButton,
  StyledCopyButton,
  StyledCode,
  StyledContainer,
  StyledLabel,
} from './styles/Code.styles';
import { ReactComponent as CopyIcon } from './assets/copy.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';

import { ButtonSizeEnum, ButtonTypeEnum } from '../button';
import { StatusEnum, useCopyToClipboard } from '../use-copy-to-clipboard';
import { isBrowser } from '../utils';
import { useSyncedRef } from '../use-synced-ref';
import { getBeautifiedOptions } from './utils';

if (isBrowser()) {
  Prism.plugins.autoloader.languages_path = '/_static/prism/1.29.0/components/';
}

const DEFAULT_COPY_BUTTON_PROPS = {
  isEnabled: true,
  themeType: ButtonTypeEnum.DEFAULT,
  size: ButtonSizeEnum.EXTRA_SMALL,
  ghost: true,
};

/** @typedef {React.ButtonHTMLAttributes<HTMLButtonElement>} DefaultButtonProps */

/**
 * @type {React.FC<{
 *   code: string;
 *   language?: string;
 *   copyButton?: {
 *     isEnabled?: boolean;
 *     'data-analytics'?: string;
 *     [key: string]: any;
 *   } & DefaultButtonProps;
 *   exampleButton?: { href?: string; dataAnalytics?: string } & DefaultButtonProps;
 *   autoLinker?: boolean;
 *   label?: string;
 *   beautifyOptions?: { disableBeautify?: boolean; [key: string]: any };
 *   className?: string;
 * }>}
 */
export const Code = forwardRef(
  (
    {
      code,
      language = 'markup',
      copyButton = {},
      exampleButton,
      autoLinker = true,
      label,
      beautifyOptions = {},
      className,
      ...props
    },
    outerRef
  ) => {
    const [highlightedCode, setHighlightedCode] = useState(code);

    const copyButtonOptions = {
      ...DEFAULT_COPY_BUTTON_PROPS,
      ...copyButton,
    };
    const mergedBeatifyOptions = getBeautifiedOptions(beautifyOptions, code, language);

    const codeRef = useSyncedRef(outerRef);

    const beatifyCode = useCallback(
      (processedCode) => {
        if (mergedBeatifyOptions.disableBeautify) return processedCode;

        const isJsLang = /javascript|js/.test(language);

        if (isJsLang) return jsBeautify(processedCode, mergedBeatifyOptions);

        const isMarkup = /markup|html|xml|svg|mathml|ssml|atom|rss/.test(language);

        if (isMarkup) return htmlBeautify(processedCode, mergedBeatifyOptions);

        return processedCode;
      },
      [language, mergedBeatifyOptions]
    );

    const processCode = useCallback(
      async (codeToProcess) => {
        return beatifyCode(codeToProcess);
      },
      [beatifyCode]
    );

    const [, copyCode, status] = useCopyToClipboard(
      highlightedCode,
      500,
      copyButtonOptions.onClick
    );

    useEffect(() => {
      const processLinks = () => {
        const linksInCode = codeRef.current?.querySelectorAll('.url-link') ?? [];
        const linksAsMail = codeRef.current?.querySelectorAll('.email-link') ?? [];
        const links = [...linksInCode, ...linksAsMail];

        links.forEach((link) => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer nofollow');
          link.setAttribute('tabindex', '-1');
        });
      };

      // we're using setTimeout here because Prism is changing nodes in element even after the callback is called,
      // thus linksInCode might contain elements which are already deleted from DOM
      Prism.highlightElement(codeRef.current, false, () => setTimeout(processLinks, 120));
    }, [highlightedCode, codeRef]);

    useEffect(() => {
      processCode(code).then((processedCode) => setHighlightedCode(processedCode));
    }, [code, processCode]);

    // Do not pass isEnabled to HTML tag via StyledCopyButton.
    return (
      <StyledContainer className={className}>
        <CodeButtonsContainer>
          {exampleButton?.href && (
            <SandboxCodeExampleButton
              forwardedAs="a"
              href={exampleButton?.href}
              target="_blank"
              rel="noopener noreferrer"
              themeType={ButtonTypeEnum.DEFAULT}
              size={ButtonSizeEnum.EXTRA_SMALL}
              ghost
              data-analytics={exampleButton?.dataAnalytics}
            >
              Open in CodeSandbox
            </SandboxCodeExampleButton>
          )}

          {copyButtonOptions?.isEnabled && (
            <StyledCopyButton
              {...{ ...copyButtonOptions, isEnabled: undefined }}
              type="button"
              onClick={copyCode}
              icon={status === StatusEnum.SUCCESS ? CheckIcon : CopyIcon}
            />
          )}
        </CodeButtonsContainer>

        {label && <StyledLabel>{label}</StyledLabel>}

        <pre tabIndex={-1}>
          <StyledCode
            $autoLinker={autoLinker}
            className={`language-${language}`}
            data-dependencies="bash"
            ref={codeRef}
            tabIndex={-1}
            {...props}
          >
            {highlightedCode?.trim()}
          </StyledCode>
        </pre>
      </StyledContainer>
    );
  }
);
