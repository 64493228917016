import { action, flow, makeObservable, observable, computed } from 'mobx';

import { BaseStore } from '../base-store';
import { AccountMetaFieldEnum } from '../account/account-store';

const DEMO_RELEASE_DATE = new Date('2022-08-31T00:00:00Z');

export const DEMO_STEPS_COUNT = 3;

export class ProjectDemoStore extends BaseStore {
  initialData = {
    currentStep: 1,
    fileInfo: null,
    uploadInfo: null,
    isShowOptimization: false,
  };

  currentStep = this.initialData.currentStep;

  uploadInfo = this.initialData.uploadInfo;

  fileInfo = this.initialData.fileInfo;

  isShowOptimization = this.initialData.isShowOptimization;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      isDemoCompleted: computed,
      currentStep: observable,
      uploadInfo: observable,
      fileInfo: observable,
      isShowOptimization: observable,
      setUploadInfo: action,
      stepForward: action,
      getFileInfo: flow,
      completeDemo: action,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/`;
  }

  get isDemoCompleted() {
    return this.appStore.stores.accountStore.data.meta?.[AccountMetaFieldEnum.IS_DEMO_COMPLETED];
  }

  get isRegisteredAfterRelease() {
    const registrationDate = new Date(this.appStore.stores.accountStore.data.created);
    return registrationDate.getTime() >= DEMO_RELEASE_DATE.getTime();
  }

  *getFileInfo(pubKey, uuid) {
    this.isLoadingMap.getFileInfo = true;

    try {
      const res = yield this.api.get(`${pubKey}/files/${uuid}/?include=appdata`);
      this.fileInfo = res.data;
    } catch (e) {
      this.processErrors(e);
    } finally {
      this.isLoadingMap.getFileInfo = false;
    }
  }

  setUploadInfo(data) {
    if (this.uploadInfo) {
      this.reset();
    }

    this.uploadInfo = data;
  }

  showOptimization() {
    this.isShowOptimization = true;
  }

  stepForward() {
    this.currentStep += 1;
  }

  completeDemo() {
    if (this.isDemoCompleted) return;

    return this.appStore.stores.accountStore.updateAccount(
      {
        meta: {
          [AccountMetaFieldEnum.IS_DEMO_COMPLETED]: true,
        },
      },
      true
    );
  }

  reset() {
    super.reset();
    this.currentStep = this.initialData.currentStep;
    this.fileInfo = this.initialData.fileInfo;
    this.uploadInfo = this.initialData.uploadInfo;
    this.isShowOptimization = this.initialData.isShowOptimization;
  }
}
