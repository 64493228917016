import React from 'react';
import { useLocation } from 'react-router-dom';

import { NavLinkIcon, NavLinkContainer } from './styles/NavLink.styles';
import { isLinkActive } from './utils';

export const NavLink = ({ as, children, to, href, target, onClick, badge, Icon, ...props }) => {
  const { pathname } = useLocation();

  return (
    <NavLinkContainer
      {...props}
      as={as}
      to={to}
      href={href}
      target={target}
      onClick={onClick}
      $isActive={isLinkActive(pathname, to)}
      $isShowArrow={target === '_blank'}
    >
      {Boolean(Icon) && (
        <NavLinkIcon>
          <Icon />
        </NavLinkIcon>
      )}
      {children}
      {badge}
    </NavLinkContainer>
  );
};
