import styled, { css, createGlobalStyle } from 'styled-components';
import Image from '@uc-common/image';
import { customScrollbar } from '@uc-common/utils-styles/custom-scrollbar';

export const NavigationGlobalStyles = createGlobalStyle`
  .site-wrap {
    align-items: flex-start;
  }

  .content-wrap {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .messages {
    width: var(--content-width);
    padding-left: var(--navigation-width);
  }
`;

export const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: column;
  width: var(--navigation-width);

  background-color: #ffffff;
  border-right: var(--border-grey);
  height: calc(100vh - var(--annoying-messages-height));
`;

export const NavigationContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  height: calc(100vh - var(--annoying-messages-height) - var(--header-height));
  ${customScrollbar('#c8cbd0', 'transparent', 'y')}
`;

export const navigationItemStyles = css`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 8px 20px;
  border-radius: 5px;
  width: 100%;
  color: #495560;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.54;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &:hover,
  &:focus-visible {
    color: #000000;
    background: #f4f5f6;
    outline: none;
  }
`;

export const NavigationHeader = styled.div`
  position: sticky;
  z-index: 100;
  height: var(--header-height);
  padding: 10px;
  border-bottom: var(--border-grey);
  border-top: var(--border-grey);
  display: grid;
  align-items: center;
  flex-shrink: 0;
`;

export const NavigationBlock = styled.div`
  border-top: 1px solid #f1f2f3;
  margin: 0 15px;
  padding: 15px 0;
`;

export const NavigationUpgradeBlock = styled(NavigationBlock)`
  padding-bottom: 16px;
`;

export const NavigationTitle = styled.p`
  font-weight: 700;
  font-size: 11px;
  line-height: 1.6;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #495560;
  padding-left: 12px;
  margin-top: 0;
  margin-bottom: 6px;
`;

export const NavigationLinksContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${/* sc-selector */ NavigationBlock}:first-child {
    border-top: none;
  }
`;

export const NavigationLogo = styled(Image)`
  margin-left: 20px;
`;

export const MainWithNavigation = styled.main`
  display: grid;
  grid-template-columns: var(--navigation-width) 1fr;
  grid-template-rows: 1fr;

  width: 100%;
  height: calc(100vh - var(--annoying-messages-height));
`;

export const ContentWithNavigation = styled.div`
  height: 100%;
  background-color: var(--content-background-color, transparent);
  transition: background-color 0.2s ease-in-out;

  ${customScrollbar('#c8cbd0', 'transparent', 'x')}
`;
