import { action, observable, makeObservable, flow } from 'mobx';

import { getQueryFromParams, queryToUrl } from '~/utils/browser';
import { BaseStore, longCache } from '../base-store';

export const API_LOGS_LIVE_IN_DAYS = 7;
export const RESPONSE_ERROR_CODE_ENUM = {
  INFECTED: 'FileInfectedError',
};

const defaultParams = {
  api_type: '',
  api_version: '',
  client_ip: '',
  request_endpoint: '',
  request_method: '',
  response_error_code: '',
  response_status_code: '',
  response_status_code__gte: '',
  file_id: '',
  request_id: '',
};

export class ProjectApiLogsStore extends BaseStore {
  ITEMS_PER_PAGE = 50;

  API_TYPE_OPTIONS = [
    { value: '', label: 'All' },
    { value: 'RestAPI', label: 'RestAPI' },
    { value: 'UploadAPI', label: 'UploadAPI' },
  ];

  METHOD_OPTIONS = [
    { value: '', label: 'All' },
    { value: 'GET', label: 'GET' },
    { value: 'POST', label: 'POST' },
    { value: 'PUT', label: 'PUT' },
    { value: 'DELETE', label: 'DELETE' },
  ];

  ERROR_OPTIONS = [
    { value: '', label: '--' },
    { value: 'errors', label: 'All HTTP errors' },
    { value: 'infected', label: 'Infected uploads' },
  ];

  data = [];

  logItem = null;

  next = false;

  activeLog = null;

  params = { ...defaultParams };

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      resetLogItem: action,
      setActiveLog: action,
      fetchList: flow,
      fetchMore: flow,
      fetchRequest: flow,
      data: observable,
      logItem: observable,
      activeLog: observable,
    });
  }

  parseSearch = () => {
    new URLSearchParams(window.location.search).forEach((value, field) => {
      if (this.params[field] !== undefined && value) {
        this.params[field] = value;
      }
    });
  };

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/access_logs/`;
  }

  *fetchList({ config } = {}) {
    const params = { ...this.params };
    params.limit = this.ITEMS_PER_PAGE;
    const queryString = getQueryFromParams(params);

    queryToUrl(queryString);

    this.isLoading = true;
    this.next = false;

    try {
      const res = yield this.api.get(queryString, config);
      this.data = res.data.results;
      this.next = res.data.next;
      this.isLoading = this.isInitialLoading = false;
      return res;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }

  *fetchMore() {
    if (!this.next) {
      return;
    }

    const params = { ...this.params };
    params.limit = this.ITEMS_PER_PAGE;
    params.from = this.data[this.data.length - 1].request_received_at;
    const queryString = getQueryFromParams(params);

    this.isLoading = true;

    try {
      const res = yield this.api.get(queryString);
      this.data.push(...res.data.results);
      this.next = res.data.next;
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
      this.processErrors(e);
    }
  }

  /**
   * Fetch one request from the API by UUID.
   *
   * @param {string} requestId
   */
  *fetchRequest(requestId) {
    this.isLoadingMap[requestId] = true;

    try {
      const res = yield this.api.get(`/${requestId}/`, longCache);
      this.logItem = res.data;
      this.isLoadingMap[requestId] = false;
    } catch (e) {
      this.isLoadingMap[requestId] = false;
      this.processErrors(e);
    }
  }

  setParams = (params) => {
    this.params = {
      ...this.params,
      ...params,
    };
  };

  resetParams = () => {
    this.params = { ...defaultParams };
  };

  setActiveLog = (id) => {
    if (this.activeLog === id) {
      return;
    }

    this.activeLog = id;
  };

  resetLogItem() {
    this.logItem = null;
  }

  reset() {
    super.reset();
    this.data = [];
    this.logItem = null;
    this.next = false;
    this.activeLog = null;
    this.resetParams();
  }

  get isMalwareFilterEnabled() {
    return this.params.response_error_code === RESPONSE_ERROR_CODE_ENUM.INFECTED;
  }
}
