import { observable, makeObservable, flow } from 'mobx';

import { message } from '~/components/Toaster';
import { RESPONSE_CODES } from '~/utils/http';
import { noCache, BaseStore } from '~/stores/base-store';

export class AccountCardStore extends BaseStore {
  /** @type {import('dashboard-api-types').card} */
  data = null;

  cantRemoveCard = false;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      cantRemoveCard: observable,
      fetchData: flow,
      addCard: flow,
      removeCard: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/card/';
  }

  *fetchData() {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/');
      this.data = res.data;
      this.isLoading = this.isInitialLoading = false;
      return res;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      // 404 means no card attached to the account.
      if (e.response?.status !== 404) {
        this.processErrors(e);
      }
    }
  }

  async *addCard(token) {
    this.isLoadingMap.addCard = true;

    const { accountStore, projectStore } = this.appStore.stores;

    try {
      const res = yield this.api.post('/', {
        stripe_token: token.id,
      });

      this.data = res.data;

      await accountStore.fetchData({ config: noCache });

      if (projectStore.project) {
        await projectStore.fetchData({ config: noCache });
      }

      this.isLoadingMap.addCard = false;
      message.success('Card data was stored.');

      return res;
    } catch (e) {
      this.isLoadingMap.addCard = false;
      this.processErrors(e);
    }
  }

  async *removeCard() {
    this.isLoadingMap.removeCard = true;

    const { accountStore, billingStore, accountPlanStore } = this.appStore.stores;
    const { subscription } = accountStore.data ?? {};
    const shouldPollByPeriod = Number(subscription?.price) !== 0 && !subscription?.is_trial;

    try {
      const res = yield this.api.delete('/');
      this.data = null;

      if (shouldPollByPeriod) {
        // XXX: due to race conditions on the backend we need to poll API until has_card is false
        // or billing period is changed (on downgrade to free plan).

        await accountStore.pollNewSubscriptionStatus({
          byHasCard: true,
          byPeriodStart: shouldPollByPeriod ? subscription?.period_start : undefined,
        });

        await Promise.all([
          accountPlanStore.fetchAvailablePlans(),
          billingStore.fetchUsage({ config: noCache }),
        ]);

        accountPlanStore.setCurrentPlanById(accountStore.data?.subscription?.plan_id);
      }

      return res;
    } catch (err) {
      if (err instanceof Error && err.response?.status === RESPONSE_CODES.BAD_REQUEST) {
        this.cantRemoveCard = true;
        throw new Error('Cannot delete card');
      } else {
        throw err;
      }
    } finally {
      this.isLoadingMap.removeCard = false;
    }
  }
}
