// @ts-check
import styled, { css } from 'styled-components';

import { ModalMainContent } from '~/components/Modal';

/** @typedef {import('styled').StyledComponent<'dialog', { $isDisabled: boolean }>} StyledDialogProps */
export const StyledDialog = /** @type {StyledDialogProps} */ (styled.dialog)(
  ({ $isDisabled = false }) => css`
    position: static;

    display: flex;
    flex-direction: column;

    width: 644px;
    max-height: 100%;
    padding: 0;

    background-color: #ffffff;
    border: none;
    border-radius: var(--modal-border-radius);
    box-shadow: 0 1px 4px rgb(0 0 0 / 15%), 0 5px 15px rgb(0 0 0 / 10%);
    overflow: auto;

    font-size: 15px;

    ${$isDisabled &&
    css`
      ${ModalMainContent} {
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-color: #ffffff;
          opacity: 0.5;
          z-index: 90;
          width: 100%;
          height: 100%;
        }
      }
    `}
  `
);
