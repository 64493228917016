import styled from 'styled-components';

import { AvatarGradient } from '~/components/AvatarGradient';

export const ProjectAvatarContainer = styled(AvatarGradient)`
  --gradient-size: 16px;
  margin-right: 9px;
  border-radius: 4px;
  flex-shrink: 0;
`;
