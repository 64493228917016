import { observable, makeObservable, flow } from 'mobx';
import { BaseStore } from '../base-store';

export class AccountPaymentsUpcomingStore extends BaseStore {
  /** @type {import('dashboard-api-types').upcomingInvoice} */
  data = null;

  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
    });
  }

  get baseURL() {
    return '/apps/api/v0.1/payments/upcoming/';
  }

  *fetchData() {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/');
      this.data = res.data;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }
}
