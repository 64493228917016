/* eslint-disable camelcase */
import { flow, observable, makeObservable } from 'mobx';
import { message } from '~/components/Toaster';
import { BaseStore } from '../base-store';
import { dataLikeArrayMixin } from '../mixins';

/** @typedef {import('dashboard-api-types').s3Target} S3Target */

export class ProjectS3TargetsStore extends dataLikeArrayMixin(BaseStore) {
  /** @type {S3Target} */
  defaultData = Object.freeze({
    // eslint-disable-next-line no-template-curly-in-string
    key_pattern: '${default}',
    bucket_name: '',
    name: '',
    is_bucket_acl_enabled: false,
    is_auto_copy_enabled: true,
    auto_copy_as_public: true,
  });

  /** @type {S3Target[]} */
  data = [];

  /** @param {ConstructorParameters<typeof BaseStore>} args */
  constructor(...args) {
    super(...args);

    makeObservable(this, {
      data: observable,
      fetchData: flow,
      addBucket: flow,
      editBucket: flow,
      removeBucket: flow,
    });
  }

  get baseURL() {
    return `/apps/api/v0.1/projects/${this.appStore.stores.projectStore.pubKey}/s3_targets/`;
  }

  /** @param {{ config?: import('axios').AxiosRequestConfig }} param */
  *fetchData({ config } = {}) {
    this.isLoading = true;

    try {
      const res = yield this.api.get('/', config);
      this.data = res.data.results;
      this.isLoading = this.isInitialLoading = false;
    } catch (e) {
      this.isLoading = this.isInitialLoading = false;
      this.processErrors(e);
    }
  }

  /** @param {Omit<S3Target, 'id' | 'bucket_location'>} data */
  *addBucket(data) {
    data.prefix = data.prefix ?? '';
    this.isLoading = true;

    try {
      const res = yield this.api.post('/', data);
      this.data.push(res.data);
      this.isLoading = false;
      message.success('S3 Bucket was added.');
      return res;
    } catch (e) {
      this.isLoading = false;
      this.processErrors(e);
    }
  }

  /**
   * @param {number} id
   * @param {Omit<S3Target, 'id' | 'bucket_location'>} data
   */
  *editBucket(id, data) {
    data.prefix = data.prefix ?? '';
    this.isLoading = this.isLoadingMap[id] = true;

    try {
      const res = yield this.api.put(`/${id}/`, data);
      this.updateItemInDataByField(res.data);
      this.isLoading = this.isLoadingMap[id] = false;
      message.info('S3 Bucket was updated.');
      return res;
    } catch (e) {
      this.isLoading = this.isLoadingMap[id] = false;
      this.processErrors(e);
    }
  }

  /** @param {number} id */
  *removeBucket(id) {
    this.isLoading = this.isLoadingMap[id] = true;

    try {
      const res = yield this.api.delete(`/${id}/`);
      this.removeItemFromDataByField(id);
      this.isLoading = this.isLoadingMap[id] = false;
      message.info('S3 Bucket was deleted.');
      return res;
    } catch (e) {
      this.isLoading = this.isLoadingMap[id] = false;
      this.processErrors(e);
    }
  }
}
