import styled, { css } from 'styled-components';
import { ReactComponent as QuestionIcon } from './assets/question.svg';
import { ReactComponent as InfoIcon } from './assets/info.svg';

const tooltipIconStyles = ({ $noMargin }) => css`
  cursor: pointer;
  fill: #ebebef;
  margin: ${$noMargin ? 0 : '0 5px'};
  transition: all 0.2s ease;
  width: 18px;
  height: 18px;

  &:hover {
    fill: #dddddd;
  }
`;

export const Icon = styled.i`
  ${tooltipIconStyles};
`;

export const QuestionTooltipIcon = styled(QuestionIcon)`
  ${tooltipIconStyles};
`;

export const InfoTooltipIcon = styled(InfoIcon)`
  ${tooltipIconStyles};
`;
