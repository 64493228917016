import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { H2 } from '../dashboard/typography';

const Container = styled.div`
  height: var(--header-height, 56px);
  padding-left: var(--content-inset-padding);
  padding-right: var(--content-inset-padding);

  background-color: #ffffff;
  border-top: var(--border-grey);
  border-bottom: var(--border-grey);

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  position: sticky;
  left: 0;
  top: 0;
  z-index: 90;

  ${/* sc-selector */ H2} {
    margin: 0;
  }

  #nprogress .spinner {
    /* reset fixed position */
    position: static;
  }
`;

const Content = styled.div`
  position: relative;
  display: inline-grid;
  grid-gap: 15px;
  grid-auto-flow: column;
  align-items: center;
`;

export const STICKY_HEADER_CONTENT_ID = 'sticky-header-content';

export const StickyHeaderContainer = ({ children }) => (
  <Container>
    <Content id={STICKY_HEADER_CONTENT_ID} />
    {children}
  </Container>
);

export const StickyHeader = ({ children }) => {
  const [container, setContainer] = useState(false);

  useEffect(() => {
    const container = document.getElementById(STICKY_HEADER_CONTENT_ID);

    if (container) {
      setContainer(container);
    }
  }, []);

  if (!container) {
    return null;
  }

  return createPortal(children, container);
};
