// @ts-check
import styled, { css } from 'styled-components';

import { UpgradeButton } from '~/components/UpgradeButton';

/** @typedef {import('styled').StyledComponent<'div', { $isWarning?: boolean }>} UpgradePlanContainerProp */
export const UpgradePlanContainer = /** @type {UpgradePlanContainerProp} */ (styled.div)(
  ({ $isWarning = false }) => css`
    position: sticky;
    top: 59px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e2fade;
    padding: 12px 22px;
    width: 100%;
    z-index: 100;
    min-height: 44px;
    color: #5c992e;

    /*hack for overlap border of the parent element */
    margin-top: -1px;

    ${$isWarning &&
    css`
      background-color: #f6f3d9;
      color: #9e7147;
    `};
  `
);

export const UpgradePlanText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 10px 0 0;
`;

export const UpgradePlanButton = styled(UpgradeButton)`
  background-color: transparent;
`;
