import urlJoin from 'url-join';

export const UC_CDN_BASE = 'https://ucarecdn.com/';

export const getSrc = ({ uuid, src, options }) => {
  const operations = options
    ? `-/${Object.keys(options)
        .map((key) => `${key}/${options[key] || ''}`)
        .join('/-/')}/`
    : '/';

  if (uuid) {
    return urlJoin(UC_CDN_BASE, uuid, operations);
  }

  const cantUseCdnOperations = /\.gif|.svg|data:base|\/-\//.test(src);

  if (cantUseCdnOperations) {
    return src;
  }

  const url = new URL(src);
  const fileName = url.pathname.substring(1 + url.pathname.lastIndexOf('/'));

  if (fileName) {
    return urlJoin(src.replace(fileName, ''), operations, fileName);
  }

  return urlJoin(src, operations);
};
