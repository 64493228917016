// @ts-check
import { useEffect, useState } from 'react';

/**
 * @param {React.RefObject<HTMLElement>} ref
 * @param {object} [params]
 * @param {boolean} [params.alwaysHideByClick=false]
 * @returns {[boolean, React.Dispatch<React.SetStateAction<boolean>>]}
 */
export const useDropdown = (ref, params) => {
  const { alwaysHideByClick } = params || {};
  const [show, setShow] = useState(false);

  useEffect(() => {
    /** @param {MouseEvent} evt */
    const onClickOutside = (evt) => {
      if (alwaysHideByClick) {
        // always hide menu
        setShow(false);
      } else if (ref.current && evt.target instanceof Node && !ref.current.contains(evt.target)) {
        // hide menu only if click outside menu container
        setShow(false);
      }
    };

    /** @param {KeyboardEvent} evt */
    const onKeydown = (evt) => {
      if (evt.key === 'Escape') {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener('mouseup', onClickOutside);
      document.addEventListener('keydown', onKeydown);

      return () => {
        document.removeEventListener('mouseup', onClickOutside);
        document.removeEventListener('keydown', onKeydown);
      };
    }
  }, [show, alwaysHideByClick, ref]);

  return [show, setShow];
};
