// @ts-check

/**
 * @template {unknown} T
 * @param {T} value
 * @returns {value is Exclude<T, null | undefined>}
 */
export function isDefined(value) {
  return value !== undefined && value !== null;
}

/** @param {boolean | string} value */
export function isBooleanStr(value) {
  if (typeof value === 'boolean') return true;

  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
    case 'false':
    case 'no':
    case '0':
      return true;
    default:
      return false;
  }
}

/** @param {unknown} value */
export function isEmpty(value) {
  // check object, add other func if needed
  if (typeof value === 'object' && value !== null) {
    const filteredValues = Object.values(value).filter(isDefined);
    return filteredValues.length === 0;
  }

  return value;
}

/** @param {string} method */
export function isCsrfMethodRequired(method) {
  // these HTTP methods require CSRF protection
  return /^(delete|put|post)$/.test(method);
}
