import { useEffect } from 'react';
import { useResizeObserver } from '@uc-common/use-resize-observer';

export const SetVW = () => {
  const body = useResizeObserver(document.body);

  useEffect(() => {
    if (body.contentRect) {
      const vw = body.contentRect.width / 100;
      body.target.style.setProperty('--vw', `${vw}px`);
    }
  }, [body]);

  return null;
};
